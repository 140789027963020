import React from "react";

export default function AssetTextField({icon, text}) {
  return (
    <div className="d-flex flex-row p-1">
      <div className="d-flex h-100">{icon}</div>

      <div className="flex align-items-center px-3 w-100">
        <span className="asset-info-text">{text}</span>
      </div>
    </div>
  );
}
