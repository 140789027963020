import styled from "styled-components";
import {Col} from "react-bootstrap";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: ${({$wrap}) => $wrap};
  justify-content: ${({$flexEnd}) => $flexEnd && "flex-end"};
  margin: ${({$t, $r, $b, $l}) => `
        ${$t ? $t : 0}px 
        ${$r ? $r : 0}px 
        ${$b ? $b : 0}px 
        ${$l ? $l : 0}px 
    `};

  @media screen and (max-width: 600px) {
    justify-content: ${({$spaceBetween}) => $spaceBetween && "space-between"};
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-gap: ${({gutter}) => (gutter ? `${gutter}px` : "0.3rem")};
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));

  /* grid-template-columns: ${({showFilter}) =>
    showFilter ? `repeat(1, 1fr)` : `repeat(2, 1fr)`};

  @media screen and (min-width: 600px) {
    grid-template-columns: ${({showFilter}) =>
    showFilter ? `repeat(3, 1fr)` : `repeat(4, 1fr)`};
  }
  @media screen and (min-width: 750px) {
    grid-template-columns: ${({showFilter}) =>
    showFilter ? `repeat(4, 1fr)` : `repeat(6, 1fr)`};
  }
  @media screen and (min-width: 950px) {
    grid-template-columns: ${({showFilter}) =>
    showFilter ? `repeat(6, 1fr)` : `repeat(8, 1fr)`};
  } */
`;

export const DatePickerContainer = styled.div`
  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--reeler-title);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: outline 0.06s ease-in-out;
  }

  input:focus {
    outline: 3px solid var(--reeler-light-o-25);
  }
`;

export const HoverIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: ${({$active}) =>
      $active ? "var(--reeler-grey-dark)" : null};

    transition: all ease-in-out 0.2s;
    :hover {
      background-color: var(--reeler-grey-dark);
    }
  }
`;

export const Sidebar = styled(Col)`
  position: sticky;
  top: 60px;
  flex-direction: column;

  max-height: calc(100vh - 70px);
  min-width: 280px;
  background-color: white;
  padding: 12px 12px 6px 12px;
  margin-bottom: 1em;
  border-right: 1px solid var(--reeler-grey-dark);
  color: var(--reeler-title);
  transition: all ease-in-out 0.2s;
  display: ${({$show}) => ($show ? "flex" : "none")};

  ${({$show}) => !$show && `transform: translateX(-100%);`}

  @media screen and (max-width: 768px) {
  }
`;

export const SidebarTeaser = styled.div`
  position: sticky;
  top: 60px;
  display: ${({$show}) => ($show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: calc(100vh - 70px);
  width: 15px;
  background-color: var(--reeler-super-light);
  padding: 5px;
  border-right: 1px solid var(--reeler-grey-dark);
  color: var(--reeler-title);
  transition: all ease-in-out 0.2s;
`;

export const RotateIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({open}) => (open ? "180deg" : "0deg")});
  transition: all ease-in-out 0.2s;
`;
// more filters
export const MoreFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  border-radius: 10px;
  ${({selected}) =>
    selected ? "background-color: var(--reeler-mid-green)" : null};

  :hover {
    background-color: var(--reeler-hover);
  }
`;

// SAVED FILTERS
export const SingleCollection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  border-radius: 10px;
  ${({selected}) =>
    selected ? "background-color: var(--reeler-mid-green)" : null};

  :hover {
    background-color: var(--reeler-hover);
  }
`;
export const CollectionNameContainer = styled.div`
  display: flex;
  flex: 10;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const EditCollectionContainer = styled.div`
  display: flex;
  flex: 1;
`;
