import React, {useState} from "react";
import {Table, Tooltip} from "react-bootstrap";
import CampaignsRow from "./CampaignsRow";
import {BsSortDown} from "react-icons/bs";
import CampaignNumberOfAssets from "./CampaignNumberOfAssets";
import {CAMPAIGN_STATUS} from "../../../constants";
import FilterSourceStatus from "./FilterSourceStatus";
import {useSelector} from "react-redux";
import FilterSourceOwner from "./FilterSourceOwner";
import FilterCampaignType from "./FilterCampaignType";
import {FaEye} from "react-icons/fa";
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip";

const CampaignsTable = ({campaigns}) => {
  const [filterCampaigns, setFilterCampaigns] = useState({
    status: "showAll",
    owner: "showAll",
    type: "showAll",
  });
  const [sort, setSort] = useState(null);

  return (
    <Table className="mt-3" hover>
      <thead>
        <tr style={{height: "50px", borderBottom: "2px solid #dee2e6"}}>
          <th className="text-center align-middle" style={{width: "20px"}}>
            <FilterSourceStatus
              state={filterCampaigns}
              dispatch={setFilterCampaigns}
            />
          </th>
          <th className="text-center align-middle" style={{width: "20px"}}>
            <ReelerTooltip text="Show source in menu" placement="top">
              <FaEye className="icon-color" />
            </ReelerTooltip>
          </th>
          <th style={{width: "150px", borderBottom: "none"}}>
            <FilterCampaignType
              state={filterCampaigns}
              dispatch={setFilterCampaigns}
            />
          </th>
          <th
            className="icon-btn"
            onClick={() => {
              setSort("campaignName");
            }}
          >
            Content Sources{" "}
            <BsSortDown className="ms-1" style={{fontSize: "20px"}} />
          </th>

          <th
            style={{width: "130px"}}
            className="icon-btn"
            onClick={() => {
              setSort("createdAt");
            }}
          >
            Created <BsSortDown className="ms-1" style={{fontSize: "20px"}} />
          </th>
          <th style={{width: "200px", borderBottom: "none"}}>
            <div>
              <FilterSourceOwner
                state={filterCampaigns}
                dispatch={setFilterCampaigns}
              />
            </div>
          </th>
          <th style={{width: "250px"}} className="text-center"></th>
        </tr>
      </thead>
      <tbody>
        {campaigns
          .filter((campaign) => {
            if (filterCampaigns.owner !== "showAll") {
              if (campaign.userId === filterCampaigns.owner) {
                return true;
              }
            } else {
              return true;
            }
          })
          .filter((campaign) => {
            if (filterCampaigns.status !== "showAll") {
              if (
                campaign.status === filterCampaigns.status ||
                campaign.status === CAMPAIGN_STATUS.deleting
              ) {
                return true;
              }
            } else {
              return true;
            }
          })
          .filter((campaign) => {
            if (filterCampaigns.type !== "showAll") {
              if (campaign.campaignType === filterCampaigns.type) {
                return true;
              }
            } else {
              return true;
            }
          })
          .sort((a, b) => {
            if (sort === "campaignName") {
              if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase())
                return -1;
              if (a.campaignName.toUpperCase() > b.campaignName.toUpperCase())
                return 1;
              else return 0;
            } else if (sort === "createdAt") {
              if (a.createdAt.seconds > b.createdAt.seconds) return -1;
              if (a.createdAt.seconds < b.createdAt.seconds) return 1;
              else return 0;
            } else {
              return 0;
            }
          })
          .map((campaign, index) => {
            if (campaign.status === "deleting") {
              return (
                <tr style={{back: "20px", background: "var(--reeler-grey)"}}>
                  <td
                    style={{width: "20px"}}
                    className="text-center align-middle"
                  >
                    <span className="link" style={{fontSize: "12px"}}>
                      Deleting...
                    </span>
                  </td>
                  <td className="text-center"></td>
                  <td
                    style={{
                      maxWidth: "100px",
                    }}
                  >
                    <div className="link text-truncate">
                      {campaign.campaignName}
                    </div>
                  </td>

                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center">
                    <CampaignNumberOfAssets campaignId={campaign.id} />
                  </td>
                </tr>
              );
            }

            return <CampaignsRow key={index} campaign={campaign} />;
          })}
      </tbody>
    </Table>
  );
};

export default CampaignsTable;
