import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAccounts} from "../../../redux/account/account.selectors";
import {database, firestore} from "../../../firebase";
import {Dropdown, Form, CloseButton} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {selectBulkEditArray} from "../../../redux/bulk-edit/bulkEdit.selectors";
import _ from "lodash";
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage";
import Select from "react-select";
import {FaUsers} from "react-icons/fa";
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip";
import SpinnerComponent from "../../commons/SpinnerComponent";
import Icon from "../../commons/Icon";
import ReelerPopover from "../../commons/ReelerPopover";
import {setIsSearching} from "../../../redux/filter/filter.actions";

export default function BulkEditConnectCreator() {
  const [show, setShow] = useState(false);
  const [isLoadingCreators, setIsLoadingCreators] = useState(false);
  const [creators, setCreators] = useState(null);
  const account = useSelector(selectAccounts);
  const assets = useSelector(selectBulkEditArray);
  const {setErrorMessage, setSuccessMessage} = useUserFeedbackMessage();
  const [isConnecting, setIsConnecting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (account.id) {
      setIsLoadingCreators(true);
      database.creators
        .where("accounts", "array-contains", account.id)
        .get()
        .then((querySnapshot) => {
          const creatorsArr = [];
          querySnapshot.forEach((doc) => {
            const data = database.formatDoc(doc);
            creatorsArr.push(data);
          });
          setCreators(creatorsArr);
          setIsLoadingCreators(false);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
          setIsLoadingCreators(false);
        });
    }
  }, [account.id]);

  const formatCreatorName = (creator) => {
    let name = "";
    creator?.firstName && (name += creator?.firstName);

    if (creator?.firstName && creator?.lastName) {
      name += " ";
    }
    creator?.lastName && (name += creator?.lastName);

    if (creator?.email || creator?.ig_username) {
      name += " (";
      creator.email && (name += creator?.email);
      if (creator.email && creator.ig_username) {
        name += ", ";
      }
      creator.ig_username && (name += ` ${creator?.ig_username}`);
      name += ")";
    }

    return name;
  };

  const handleConnectCreatorToAssets = (creatorId) => {
    setIsConnecting(true);

    const batches = _.chunk(assets, 500).map((assetDocs) => {
      const batch = firestore.batch();
      assetDocs.forEach((asset) => {
        let assetRef = database.assets.doc(asset.id);

        batch.set(assetRef, {creatorId}, {merge: true});
      });
      return batch.commit();
    });

    Promise.all(batches)
      .then(() => {
        setSuccessMessage("Creator connected to assets");
        setIsConnecting(false);
        dispatch(setIsSearching(true));

        setShow(false);
      })
      .catch((error) => {
        setErrorMessage("Error connecting creator to assets");
        setIsConnecting(false);
      });
  };

  const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <div
      className="flex-row pt-1 align-middle d-flex"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  return (
    <ReelerPopover
      title="Connect creator to assets"
      btn={
        <ReelerTooltip text="Connect creator to assets" placement="bottom">
          <FaUsers className="icon-color" />
        </ReelerTooltip>
      }
      placement={"bottom"}
    >
      <Form.Group className="mb-1">
        {isLoadingCreators ? (
          <SpinnerComponent />
        ) : (
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "var(--reeler-o-25)",
                primary: "var(--reeler-light)",
              },
            })}
            isLoading={isLoadingCreators || isConnecting}
            placeholder="Search and connect creator to asset"
            onChange={(selectedOption, action) => {
              handleConnectCreatorToAssets(selectedOption.value);
            }}
            options={creators?.map((c) => ({
              value: c.id,
              label: formatCreatorName(c),
            }))}
          />
        )}

        <Form.Text muted>
          The selected creator will be added to all selected assets.
        </Form.Text>
      </Form.Group>
    </ReelerPopover>
  );
}
