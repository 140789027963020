import React, {useState} from "react";
import {FaCloudDownloadAlt} from "react-icons/fa";
import {Button} from "react-bootstrap";

import SpinnerComponent from "../../../../../commons/SpinnerComponent";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";
import {generateFilename} from "../../../../../../utils/GenerateFilename";
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage";
import {MEDIA_TYPE} from "../../../../../../constants";

export default function AssetDownload({asset}) {
  const [loading, setLoading] = useState(false);
  const {setErrorMessage} = useUserFeedbackMessage();

  const downloadAssetContent = async () => {
    if (asset?.media_type !== MEDIA_TYPE.TEXT) {
      let id = asset.id;
      let url = asset.url;
      // if we find a main version, then set its values, otherwise use old values in asset.
      if (asset?.assetVersions?.length > 0) {
        let mainVersion = asset?.assetVersions.filter(
          (version) => version.main === true
        );
        if (mainVersion.length > 0) {
          id = mainVersion[0].id;
          url = mainVersion[0].url;
        }
      }
      setLoading(true);

      let filename = generateFilename(asset);
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const previewUrl = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.download = filename;
          anchor.crossOrigin = "Anonymous";
          anchor.href = URL.createObjectURL(blob);
          anchor.click();

          window.URL.revokeObjectURL(previewUrl);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMessage("Failed to download asset");
        });
    } else {
      setErrorMessage("You cannot download a text based asset");
    }
  };

  return loading ? (
    <SpinnerComponent />
  ) : (
    <ReelerTooltip text="Download asset to computer">
      <Button
        variant="secondary"
        size="sm"
        onClick={() => downloadAssetContent()}
        className="d-flex flex-row align-items-center"
      >
        <FaCloudDownloadAlt className="me-1" />
        <span>Download</span>
      </Button>
    </ReelerTooltip>
  );
}
