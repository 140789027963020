import React from "react";
import {FaRegCopy} from "react-icons/fa";
import ReelerTooltip from "./ReelerTooltip/ReelerTooltip";
import Icon from "./Icon";

export default function CopyToClipboard({link, tooltipText, margin}) {
  const copyUrl = (URL) => {
    navigator.clipboard.writeText(URL);
  };
  return (
    <ReelerTooltip text={tooltipText}>
      <Icon onClick={() => copyUrl(link)}>
        <FaRegCopy
          style={{strokeWidth: "3", margin: margin}}
          className="icon-color icon-btn"
        />
      </Icon>
    </ReelerTooltip>
  );
}
