import React, {useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {Form, Modal} from "react-bootstrap";
import {FaTrash} from "react-icons/fa";
import {database} from "../../../../firebase";
import firebase from "firebase/app";

import ReelerButton from "../../../commons/ReelerButton";
import FilterParameters from "../FilterSidebar/FilterParameters";
import {selectCurrentUser} from "../../../../redux/user/user.selectors";
import {selectAccountId} from "../../../../redux/account/account.selectors";
import {
  selectActiveFilter,
  selectShowSaveFilter,
  selectFilterLoading,
  selectFilterParameters,
} from "../../../../redux/filter/filter.selectors";
import {useDispatch} from "react-redux";
import {
  createAccountFilterStart,
  forceClearFilter,
  setActiveFilter,
  deleteAccountFilterStart,
  setActiveAccountFilter,
  setShowSaveFilter,
  clearFilter,
} from "../../../../redux/filter/filter.actions";
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip";
import DeleteFilter from "../DeleteFilter";
import withoutProperty from "../../../../utils/withoutProperty";
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage";

const CreateFilterCollection = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectActiveFilter);
  const filterParameters = useSelector(selectFilterParameters);
  const filterLoading = useSelector(selectFilterLoading);
  const currentUser = useSelector(selectCurrentUser);
  const accountId = useSelector(selectAccountId);
  const showSaveFilter = useSelector(selectShowSaveFilter);
  const {setUserFeedbackMessage} = useUserFeedbackMessage();
  // eslint-disable-next-line no-unused-vars
  const nameRef = useRef();
  const descRef = useRef();

  function closeModal() {
    dispatch(setShowSaveFilter(false));
  }

  async function handleSubmit() {
    if (currentUser && accountId) {
      // If filter has an id, update filter
      if (filter?.id) {
        try {
          const filterRef = database.accounts
            .doc(`${accountId}`)
            .collection("filters")
            .doc(filter.id);

          let updateFilter = withoutProperty(filter, "id");
          updateFilter["name"] = nameRef.current.value;
          updateFilter["description"] = descRef.current.value;
          updateFilter["filterParameters"] = filterParameters;

          console.log(updateFilter);
          await filterRef.update(updateFilter);
          dispatch(setActiveFilter({id: filter.id, ...updateFilter}));

          setUserFeedbackMessage("Filter updated successfully!");
        } catch (error) {
          console.log(error);
          setUserFeedbackMessage("Could not update filter!", "danger");
        }
      } else {
        // Create new filter
        try {
          const filterRef = database.accounts
            .doc(`${accountId}`)
            .collection("filters");
          let newFilter = {
            filterParameters,
            name: nameRef.current.value,
            description: descRef.current.value,

            createdAt: database.getCurrentTimestamp(),
            ...filter,
          };
          const newFilterDocRef = await filterRef.add(newFilter);
          dispatch(setActiveFilter({id: newFilterDocRef.id, ...newFilter}));
          setUserFeedbackMessage("New filter created successfully!");
        } catch (error) {
          console.log(error);
        }
      }

      closeModal();
    }
  }

  return (
    <Modal show={showSaveFilter} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {filter?.id ? "Update filter" : "Save new filter"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>Filter name</Form.Label>
          <Form.Control
            ref={nameRef}
            defaultValue={filter?.name}
            type="text"
            required
          />
          <Form.Text muted>
            Internal name to keep track of your filter
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Filter description</Form.Label>
          <Form.Control
            ref={descRef}
            defaultValue={filter?.description}
            as="textarea"
            rows={2}
          />
          <Form.Text muted>
            Internal description to describe the filter
          </Form.Text>
        </Form.Group>

        <Form.Label>Filter settings:</Form.Label>

        <FilterParameters />
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <div>
          {filter?.id && (
            <DeleteFilter
              btn={
                <ReelerTooltip text="Delete filter">
                  <FaTrash className="icon-color icon-btn me-3" />
                </ReelerTooltip>
              }
            />
          )}
        </div>
        <div>
          <ReelerButton
            text="Close"
            styleClass="btn-secondary"
            dispatch={closeModal}
          />

          <ReelerButton
            text="Clear"
            // loading={loading}
            dispatch={() => dispatch(forceClearFilter(true))}
            styleClass="btn-secondary ms-2"
            spinnerClass="ms-1"
            spinnerColor="icon-color"
          />
          <ReelerButton
            text="Save"
            disabled={filterLoading}
            styleClass="btn-main ms-2"
            dispatch={() => handleSubmit()}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateFilterCollection;
