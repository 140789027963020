import React, {useEffect} from "react";

// 3rd-party
import {Modal, Row, Col, ModalFooter, Button} from "react-bootstrap";
import {IoIosCloseCircleOutline} from "react-icons/io";
// Redux
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {
  setCloseAssetInfoModal,
  setActiveAsset,
} from "../../../../../redux/asset/asset.actions";
import {
  selectActiveAsset,
  selectActiveSidebarTab,
  selectShowAssetInfoModal,
} from "../../../../../redux/asset/asset.selectors";
import {selectFiltredAssets} from "../../../../../redux/filter/filter.selectors";
import {selectCurrentUser} from "../../../../../redux/user/user.selectors";

// Reeler components
import AssetDownload from "./components/AssetDownload";
import ReelerButton from "../../../../commons/ReelerButton";
import AssetSidebar from "./components/AssetSidebar";
import AssetNotes from "./components/AssetNotes";
import AssetRate from "./components/AssetRate";
import AssetRights from "./components/AssetRights";
import AssetTagsRedux from "./components/AssetTagsRedux";
import AssetPublish from "./components/AssetPublish";
import AssetVersions from "./components/AssetVersions";
import AssetDetails from "./components/AssetDetails";
import DeleteUGC from "../DeleteUGC";
import AssetStatusRedux from "./components/AssetStatusRedux";
import * as S from "../styles";
import AssetNotesComments from "./components/AssetNotesComments";
import MediaTypeRedux from "../../../../commons/MediaTypeRedux";
import {useKeyPress} from "../../../../../hooks/useKeyPress";
import AssetApprove from "./components/asset-approve/AssetApprove";
import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip";
import AssetProducts from "./components/AssetProducts/AssetProducts";
import CloseButton from "../../../../commons/CloseButton";
import {ACCOUNT_PLANS, MAIN_ROUTES} from "../../../../../constants/routes";
import AuthBasedComponent from "../../../../authentication/AuthBasedComponent";
import {CAMPAIGN_TYPE, MEDIA_TYPE} from "../../../../../constants";
import AssetGenAi from "./components/AssetGenAi";
import AssetShare from "./components/AssetShare";
import AssetCreator from "./components/AssetCreator";
import AssetViewIGPost from "./components/AssetViewIGPost";
import AssetTextField from "./components/AssetTextField";
import {BsCardText} from "react-icons/bs";
import FieldValue from "./components/FieldValue";
import EditedCaption from "./components/EditedCaption";
import AssetCreatorHashtags from "./components/AssetCreatorHashtags";
import moment from "moment";
import {capitalize} from "lodash";

const AssetInfoModal = () => {
  const dispatch = useDispatch();
  const showAssetInfoModal = useSelector(selectShowAssetInfoModal);
  const asset = useSelector(selectActiveAsset);
  const assets = useSelector(selectFiltredAssets);
  const activeSidebarTab = useSelector(selectActiveSidebarTab);
  const currentUser = useSelector(selectCurrentUser);
  // const ArrowRight = useKeyPress("ArrowRight")
  // const ArrowLeft = useKeyPress("ArrowLeft")

  const closeModal = () => {
    dispatch(setCloseAssetInfoModal());
  };

  const loadNextAsset = () => {
    let index = assets.indexOf(asset);
    if (index < assets.length - 1) {
      dispatch(setActiveAsset(assets[index + 1]));
    }
  };
  const loadPrevAsset = () => {
    let index = assets.indexOf(asset);
    if (index !== 0) {
      dispatch(setActiveAsset(assets[index - 1]));
    }
  };

  // useEffect(() => {
  //   if (ArrowRight) {
  //     loadNextAsset()
  //   }
  // }, [ArrowRight])

  // useEffect(() => {
  //   if (ArrowLeft) {
  //     loadPrevAsset()
  //   }
  // }, [ArrowLeft])

  const renderTab = () => {
    switch (activeSidebarTab) {
      case "info":
        return (
          <>
            <S.ModalTabHeading>{`${capitalize(
              asset.media_type !== MEDIA_TYPE.AUDIO
                ? asset?.metadata
                  ? `${asset?.metadata?.type} (${capitalize(
                      asset?.metadata?.orientation
                    )}, ${asset?.metadata?.dimensions?.width}x${
                      asset?.metadata?.dimensions?.height
                    }, ${asset?.metadata?.aspect_ratio?.aspectRatio})`
                  : asset?.meta_data
                  ? `${asset?.meta_data?.type} (${asset?.meta_data?.dimensions?.width}x${asset?.meta_data?.dimensions?.height}, ${asset?.meta_data?.aspect_ratio?.aspectRatio})`
                  : asset.type
                : asset.type
            )}`}</S.ModalTabHeading>
            <AssetDetails />
          </>
        );

      case "rating":
        return (
          <>
            <S.ModalTabHeading>Rating, Notes & Comments</S.ModalTabHeading>
            <AssetNotesComments />
          </>
        );

      case "products":
        return (
          <>
            <S.ModalTabHeading>Products</S.ModalTabHeading>
            <AssetProducts />
          </>
        );

      case "rights":
        return (
          <>
            <S.ModalTabHeading>Rights</S.ModalTabHeading>
            <AssetRights />
          </>
        );

      case "labels":
        return (
          <>
            <S.ModalTabHeading>Labels</S.ModalTabHeading>
            <AssetTagsRedux />
          </>
        );

      case "publish":
        return (
          <>
            <S.ModalTabHeading>Publish</S.ModalTabHeading>
            <AssetPublish />
          </>
        );

      case "versions":
        return (
          <>
            <S.ModalTabHeading>Versions</S.ModalTabHeading>
            <AssetVersions />
          </>
        );
      case "genai":
        return (
          <>
            <S.ModalTabHeading>GenAi</S.ModalTabHeading>
            <AssetGenAi />
          </>
        );

      default:
        return "Could not load component";
    }
  };
  return asset ? (
    <Modal
      id="asset-info"
      show={showAssetInfoModal}
      onHide={closeModal}
      size="xl"
    >
      <Modal.Body>
        <div className="d-flex flex-row">
          <div
            className="d-flex flex-column pe-0"
            style={{flex: "1", height: "100vh"}}
          >
            {/* Image box */}
            <div
              style={{
                height: "500px",
                width: "100%",
                position: "relative",
                padding: "1rem",
              }}
            >
              <div style={{position: "absolute", top: 0, right: 0}}>
                <AssetStatusRedux />
              </div>
              <MediaTypeRedux asset={asset} />
            </div>

            <div>
              {asset?.media && asset.media.permalink && (
                <AssetViewIGPost permalink={asset.media.permalink} />
              )}

              {asset?.caption && (
                <AssetTextField
                  icon={
                    <ReelerTooltip text="Creator caption">
                      <BsCardText className="icon-color" />
                    </ReelerTooltip>
                  }
                  text={
                    <FieldValue value={asset?.caption ? asset?.caption : ""} />
                  }
                />
              )}
              {asset?.creator_tags ? (
                <AssetCreatorHashtags asset={asset} />
              ) : null}

              {/* <EditedCaption /> */}

              {asset.campaign_type === CAMPAIGN_TYPE.upload.type && asset?.order
                ? asset.order.map((fieldId) => {
                    let assetValue = asset.values[fieldId];
                    return assetValue ? (
                      <S.FieldContainer key={fieldId}>
                        <S.FieldTitle>
                          {assetValue.database_name
                            ? assetValue.database_name
                            : assetValue.label
                            ? assetValue.label
                            : "n/a"}
                        </S.FieldTitle>

                        {assetValue?.values && assetValue.values.length > 0 ? (
                          assetValue.values.map((value, key) => (
                            <S.FieldValue key={key}>{value}</S.FieldValue>
                          ))
                        ) : (
                          <FieldValue value={assetValue.value} />
                        )}
                      </S.FieldContainer>
                    ) : null;

                    // asset.values[fieldId]?.label ? (
                    //   <S.FieldContainer>
                    //     <S.FieldTitle>
                    //       {asset.values[fieldId]?.label
                    //         ? asset.values[fieldId]?.label
                    //         : asset.values[fieldId]?.database_name
                    //         ? asset.values[fieldId]?.database_name
                    //         : "n/a"}
                    //     </S.FieldTitle>

                    //     <S.FieldValue>
                    //       {asset.values[fieldId]?.value
                    //         ? asset.values[fieldId]?.value
                    //         : "n/a"}
                    //     </S.FieldValue>
                    //   </S.FieldContainer>
                    // ) : null
                  })
                : null}
            </div>
          </div>
          <div
            className="d-flex flex-column justify-content-between"
            style={{flex: "1"}}
          >
            <div className="d-flex h-100 w-100 flex-row flex-nowrap ms-0">
              <div className="p-0">
                <AssetSidebar />
              </div>
              <div className="ps-2 justify-space-between w-100 h-100 position-relative">
                <S.CloseIconAssetInfoModal onClick={() => closeModal()}>
                  <ReelerTooltip text="Close window">
                    <CloseButton />
                  </ReelerTooltip>
                </S.CloseIconAssetInfoModal>

                {renderTab()}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          padding: "0.5rem 0.75rem",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{width: "50%"}}>
          <S.ModalIconBar className="mb-md-0">
            <AssetApprove asset={asset} />
          </S.ModalIconBar>
        </div>

        <div className="d-flex align-items-center gap-2">
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <DeleteUGC asset={asset} buttonText={"Delete"} />
          </AuthBasedComponent>
          {asset?.media_type !== MEDIA_TYPE.TEXT ? (
            <AssetDownload asset={asset} />
          ) : null}

          {asset && <AssetShare assetId={asset.id} />}

          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <Button variant="secondary" size="sm" onClick={() => loadPrevAsset()}>
            {"<< Prev"}
          </Button>
          <Button variant="secondary" size="sm" onClick={() => loadNextAsset()}>
            {"Next >>"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default AssetInfoModal;
