import styled, {css} from "styled-components";
import {ASSET_STATUS} from "../../../../../../../constants";

export const RightRequestContainer = styled.div``;
export const PreviewContainer = styled.div`
  padding: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const ApprovalStatus = styled.span`
  display: flex;
  cursor: default;
  border-radius: 10px;
  background: ${({status}) =>
    [ASSET_STATUS.pending].includes(status)
      ? "var(--reeler-warning)"
      : [ASSET_STATUS.approved].includes(status)
      ? "var(--reeler)"
      : [ASSET_STATUS.notApproved].includes(status)
      ? "var(--reeler-danger)"
      : "var(--reeler-grey)"};

  width: 10px;
  height: 10px;
`;
