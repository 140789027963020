import React, {useState} from "react";
import {database} from "../../../firebase";
import ReelerButton from "../../commons/ReelerButton";
import firebase from "firebase/app";
import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectAccounts} from "../../../redux/account/account.selectors";
import {setCreator} from "../../../redux/creators/creators.actions";
import {selectCreator} from "../../../redux/creators/creators.selectors";
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage";

const types = {
  blacklist: "blacklisted",
  whitelist: "whitelisted",
};

export default function CreatorBlacklistWhitelist({creator, btn, type}) {
  const {setSuccessMessage, setErrorMessage} = useUserFeedbackMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  //const creator = useSelector(selectCreator)
  const dispatch = useDispatch();

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  const handleChange = async () => {
    setIsLoading(true);
    try {
      const updateDate = {
        [type]: creator[type] ? !creator[type] : true,
      };

      await database.creators.doc(creator.id).update(updateDate);
      dispatch(setCreator({...creator, ...updateDate}));
      setSuccessMessage(
        type === types.blacklist ? "Creator blacklisted" : "Creator whitelisted"
      );
      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        type === types.blacklist
          ? "Error blacklisting creator"
          : "Error whitelisting creator"
      );
    }
  };

  return (
    <>
      <ReelerButton
        type="button"
        styleClass="btn-secondary"
        dispatch={openModal}
        disabled={creator ? false : true}
        text={btn}
      />

      <Modal centered show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === types.blacklist
              ? creator?.blacklisted
                ? "Are you sure you want to remove this creator from the blacklist?"
                : "Are you sure you want to blacklist this creator?"
              : creator?.whitelisted
              ? "Are you sure you want to remove this creator from the whitelist?"
              : "Are you sure you want to whitelist this creator?"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {type === types.blacklist
              ? creator?.blacklisted
                ? "This action will remove the creator from blacklist and start collecting new content from the creator."
                : "This action will blacklist the creator, preventing their content from being imported (except via web forms). Blacklisted creators will also be hidden from your creator list by default."
              : creator?.whitelisted
              ? "This action will remove the creator from the whitelist and stop collecting new content from the creator."
              : "This action will whitelist the creator, meaning any content they submit will be auto-marked ‘green’ (approved for use). Only whitelist creators you have agreements with."}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text={
              type === types.blacklist
                ? creator?.blacklisted
                  ? "Remove from blacklist"
                  : "Add to blacklist"
                : creator?.whitelisted
                ? "Remove from whitelist"
                : "Add to whitelist"
            }
            dispatch={() => handleChange()}
            loading={isLoading}
            disabled={isLoading}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
