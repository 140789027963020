import React, {useState, useEffect, useRef, forwardRef} from "react";
import {
  Form,
  FormControl,
  InputGroup,
  ButtonGroup,
  ToggleButton,
  Row,
  Button,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  FaMinus,
  FaImages,
  FaVideo,
  FaTimes,
  FaPlus,
  FaMicrophone,
  FaCheck,
  FaSearch,
  FaCalendarAlt,
  FaEdit,
} from "react-icons/fa";
import {TbZoomCheck} from "react-icons/tb";
import {database} from "../../../../firebase";
import firebase from "firebase/app";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles.css";
import {RowContainer, DatePickerContainer, MoreFilters} from "../../styles";
import {
  ASSET_STATE,
  ASSET_STATUS,
  CAMPAIGN_TYPE,
  MEDIA_ORIENTATION,
  MEDIA_TYPE,
  STATUS,
} from "../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {selectAccounts} from "../../../../redux/account/account.selectors";
import {updateAccounts} from "../../../../redux/account/account.actions";
import {
  setFilterParameters,
  setMediaSortBy,
  clearFilter,
  clearFilterParameters,
} from "../../../../redux/filter/filter.actions";
import {BulkEditReset} from "../../../../redux/bulk-edit/bulkEdit.actions";

import {
  selectForceClearFilter,
  selectTags,
  selectFilterLoading,
  selectFilterParameters,
} from "../../../../redux/filter/filter.selectors";
import {selectCampaigns} from "../../../../redux/campaign/campaign.selectors";

import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip";
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton";
import ReelerAccordation from "../../../commons/ReelerAccordation";

import * as S from "../../../../components/styles/styles";
import Tag from "../../../commons/Tag";
import {MdNotes, MdShortText} from "react-icons/md";
import {
  ToogleGroup,
  Toggle,
} from "../../../commons/ReelerToogleGroup/ReelerToggleGroup";
import withoutProperty from "../../../../utils/withoutProperty";
import FilterCreators from "./FilterCreators";

const ratingValues = [1, 2, 3, 4, 5];
const aiScoreValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
};
const FilterParameters = () => {
  //FROM MEDIAFILTER
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const account = useSelector(selectAccounts);
  // const orderBy = useSelector(selectOrderBy)
  const filter = useSelector(selectFilterParameters);
  const filterLoading = useSelector(selectFilterLoading);
  const campaigns = useSelector(selectCampaigns);
  const forceClearFilter = useSelector(selectForceClearFilter);
  const [newTag, setNewTag] = useState("");
  const [creatorTag, setCreatorTag] = useState("");
  const [objectLabel, setObjectLabel] = useState("");
  const [users, setUsers] = useState(null);
  const [sourceOwnerId, setSourceOwnerId] = useState("showAll");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const creatorEmailRef = useRef();
  const objectLabelRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const [advancedFilter, setAdvancedFilter] = useState(false);

  const handleAdvancedFilter = () => {
    setAdvancedFilter(!advancedFilter);
  };

  useEffect(() => {
    if (account) {
      database.users
        .where("accounts", "array-contains", account.id)
        .get()
        .then((querySnapshot) => {
          let userArray = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc));
          });
          setUsers(userArray);
        });
    }
  }, [account]);

  const updateFilter = (filter) => {
    dispatch(setFilterParameters(filter));
    dispatch(BulkEditReset());
  };

  // FILTER FUNCTIONS
  const handleFilter = ({target: {name, value}}) => {
    if (value !== "showAll") {
      if (name === "rating") {
        parseFloat(value);
        updateFilter({[name]: parseFloat(value)});
        return;
      }

      updateFilter({[name]: value});

      return;
    }

    var newFilter = filter;
    if (name === "type") {
      delete newFilter.type;
    } else if (name === "campaign_type") {
      delete newFilter.campaign_type;
    } else if (name === "favourite") {
      delete newFilter.favourite;
    } else if (name === "campaigns") {
      delete newFilter?.campaigns;
    } else if (name === "state") {
      delete newFilter?.state;
    } else if (name === "rating") {
      delete newFilter?.rating;
    } else if (name === "sourceOwnerId") {
      delete newFilter?.sourceOwnerId;
    } else if (name === "status") {
      delete newFilter?.status;
    } else if (name === "thirdPartyApprovalStatus") {
      delete newFilter?.thirdPartyApprovalStatus;
    } else if (name === "orientation") {
      delete newFilter?.orientation;
    }

    const isEmpty = Object.keys(newFilter)?.length === 0;

    if (isEmpty) {
      dispatch(clearFilterParameters());
    } else {
      updateFilter(newFilter);
    }
  };

  const handleRating = (value) => {
    if (filter?.rating === undefined) {
      // Create new array and add value
      updateFilter({rating: [value]});
      return;
    }

    // Check if value already exists in rating array
    if (filter?.rating.includes(value)) {
      //Remove value
      let newArr = filter?.rating.filter((val) => val !== value);

      if (newArr.length === 0) {
        // Remove rating filter from filter
        delete filter?.rating;

        const isEmpty = Object.keys(filter)?.length === 0;
        if (isEmpty) {
          dispatch(clearFilterParameters());
        } else {
          updateFilter(filter);
        }
      } else {
        // Update with new array
        updateFilter({rating: newArr});
      }
    } else {
      // Add value to array
      updateFilter({rating: [...filter?.rating, value]});
    }
  };

  const handleCampaigns = (value) => {
    if (value != "showAll") {
      if (filter?.campaigns) {
        if (filter?.campaigns.length < 10) {
          updateFilter({
            campaigns: [...filter?.campaigns, value],
          });
        }
      } else {
        updateFilter({
          campaigns: [value],
        });
      }
    } else {
      var newFilter = filter;
      delete newFilter.campaigns;
      updateFilter(newFilter);
    }
  };

  const handleRemoveCampaign = (campaignId) => {
    let campaigns = filter?.campaigns?.filter((c) => c !== campaignId);
    if (campaigns?.length > 0) {
      updateFilter({
        campaigns,
      });
    } else {
      delete filter.campaigns;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        var newFilter = filter;
        delete newFilter.campaigns;
        updateFilter(newFilter);
      } else {
        updateFilter(filter);
      }
    }
  };

  const handleAddCreatorEmail = () => {
    let email = creatorEmailRef.current.value;
    if (email && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email)) {
      if (filter?.creatorEmails) {
        if (filter?.creatorEmails.length < 1) {
          updateFilter({
            creatorEmails: [...filter?.creatorEmails, email],
          });
          creatorEmailRef.current.value = "";
        }
      } else {
        updateFilter({
          creatorEmails: [email],
        });
        creatorEmailRef.current.value = "";
      }
    }
  };

  const handleRemoveCreatorEmail = (value) => {
    let creatorEmails = filter?.creatorEmails.filter(
      (email) => email !== value
    );

    if (creatorEmails.length > 0) {
      dispatch(
        updateFilter({
          creatorEmails: creatorEmails,
        })
      );
    } else {
      delete filter.creatorEmails;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  const handleRemoveLabel = (value) => {
    const tags = filter?.tags.filter((t) => t !== value);

    if (tags.length > 0) {
      dispatch(
        setFilterParameters({
          tags,
        })
      );

      setNewTag("");
    } else {
      delete filter.tags;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  const handleAddObjectLabel = () => {
    let label = objectLabelRef.current.value
      ?.trim()
      .toLowerCase()
      .replace(/#+/g, "");
    dispatch(
      setFilterParameters({
        objectLabels: filter?.objectLabels
          ? [...filter?.objectLabels, label]
          : [label],
      })
    );
    objectLabelRef.current.value = "";
  };
  const handleRemoveObjectLabel = (value) => {
    const objectLabels = filter?.objectLabels.filter((t) => t !== value);

    if (objectLabels.length > 0) {
      dispatch(
        setFilterParameters({
          objectLabels,
        })
      );
      objectLabelRef.current.value = "";
    } else {
      delete filter.objectLabels;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  const handleRemoveCreatorHashtag = (value) => {
    const creatorTags = filter?.creatorTags.filter((t) => t !== value);

    if (creatorTags.length > 0) {
      dispatch(
        setFilterParameters({
          creatorTags,
        })
      );

      setCreatorTag("");
    } else {
      delete filter.creatorTags;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };
  const handleRemoveExcludeCreatorHashtag = (value) => {
    const excludeCreatorHashtags = filter?.excludeCreatorHashtags.filter(
      (t) => t !== value
    );

    if (excludeCreatorHashtags.length > 0) {
      dispatch(
        setFilterParameters({
          excludeCreatorHashtags,
        })
      );

      setCreatorTag("");
    } else {
      delete filter.excludeCreatorHashtags;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  useEffect(() => {
    if (forceClearFilter) {
      setNewTag("");
      setObjectLabel("");
      dispatch(clearFilterParameters());
      creatorEmailRef.current.value = "";
    }
  }, [forceClearFilter]);

  const updateAccountTags = (tag) => {
    database.accounts
      .doc(account.id)
      .update({
        tags: firebase.firestore.FieldValue.arrayUnion(tag),
      })
      .then(() => {
        // Update redux
        dispatch(
          updateAccounts({
            tags: account?.tags ? [...account?.tags, tag] : [tag],
          })
        );
      })
      .catch((err) => {});
  };

  const handleChangeDate = (key, date) => {
    if (date) {
      updateFilter({
        [key]: date,
      });
    } else {
      let newFilter = filter;
      delete newFilter[key];
      updateFilter(newFilter);
    }
  };

  const DateInput = forwardRef(
    ({idDisabled, value, onClick, placeholderText}, ref) => (
      <button
        className="icon-btn"
        onClick={onClick}
        ref={ref}
        disabled={idDisabled}
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "130px",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
          height: "calc(1.5em + 0.75rem + 2px)",
          padding: "0.375rem 0.5rem",
          fontSize: "1rem",
          fontWeight: "400",
          lineHeight: "1.5",
          color: "var(--reeler-grey-darker)",
          backgroundColor: "#fff",
        }}
      >
        {value ? value : placeholderText}
      </button>
    )
  );

  const handleToogle = (toggleValue) => {
    const {name, value} = toggleValue;
    if (value !== "showAll") {
      updateFilter({[name]: value});
      return;
    }

    var newFilter = filter;

    delete newFilter[name];

    const isEmpty = newFilter && Object?.keys(newFilter)?.length === 0;

    if (isEmpty) {
      dispatch(clearFilterParameters());
    } else {
      updateFilter(newFilter);
    }
  };

  const handleChangeAiScore = (key, score) => {
    console.log("score", score);
    console.log("key", key);

    if (score > 0 && score < 10) {
      updateFilter({
        aiScore: {...filter?.aiScore, [key]: parseInt(score)},
      });
    } else {
      let newFilter = filter;

      if (newFilter?.aiScore) {
        if (Object.keys(newFilter?.aiScore).length > 1) {
          delete newFilter?.aiScore[key];
        } else {
          delete newFilter?.aiScore;
        }
      }
      const isEmpty = newFilter && Object?.keys(newFilter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        updateFilter(newFilter);
      }
    }
  };

  return (
    <>
      {/* TOGGLE BUTTONS */}
      <Form.Group className="toggle-buttons-container mb-1">
        <Row className="mb-1">
          <ToogleGroup
            name="state"
            value={filter?.state ? filter?.state : "showAll"}
            onChange={handleToogle}
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show all assets"
            >
              All
            </Toggle>
            <Toggle
              value={ASSET_STATE.unreviewed}
              disabled={filterLoading}
              tooltip="Show only unreviewed content"
            >
              <TbZoomCheck />
            </Toggle>
            <Toggle
              value={ASSET_STATE.approved}
              disabled={filterLoading}
              tooltip="Show only approved content"
            >
              <FaCheck />
            </Toggle>
            <Toggle
              value={ASSET_STATE.rejected}
              disabled={filterLoading}
              tooltip="Show only rejected content"
            >
              <FaTimes />
            </Toggle>
          </ToogleGroup>
        </Row>
        <Row className="mb-1">
          <ToogleGroup
            name="type"
            value={filter?.type ? filter?.type : "showAll"}
            onChange={handleToogle}
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show all types"
            >
              All
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.IMAGE}
              disabled={filterLoading}
              tooltip="Show images only"
            >
              <FaImages />
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.VIDEO}
              disabled={filterLoading}
              tooltip="Show videos only"
            >
              <FaVideo />
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.AUDIO}
              disabled={filterLoading}
              tooltip="Show audio only"
            >
              <FaMicrophone />
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.TEXT}
              disabled={filterLoading}
              tooltip="Show text only"
            >
              <FaEdit />
            </Toggle>
          </ToogleGroup>
        </Row>
        <Row className="mb-1">
          <ToogleGroup
            name="campaign_type"
            value={filter?.campaign_type ? filter?.campaign_type : "showAll"}
            onChange={handleToogle}
            tooltip="Filter by source type"
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show content from all sources"
            >
              All
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.igHashtag.type}
              disabled={filterLoading}
              tooltip="Show content from Instagram hashtag"
            >
              {CAMPAIGN_TYPE.igHashtag.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.igMentions.type}
              disabled={filterLoading}
              tooltip="Show content from Instagram mentions"
            >
              {CAMPAIGN_TYPE.igMentions.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.upload.type}
              disabled={filterLoading}
              tooltip="Show content from web form content sources"
            >
              {CAMPAIGN_TYPE.upload.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.whatsapp.type}
              disabled={filterLoading}
              tooltip="Show content from WhatsApp"
            >
              {CAMPAIGN_TYPE.whatsapp.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.email.type}
              disabled={filterLoading}
              tooltip="Show content from email content sources"
            >
              {CAMPAIGN_TYPE.email.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.import.type}
              disabled={filterLoading}
              tooltip="Show imported assets"
            >
              {CAMPAIGN_TYPE.import.icon}
            </Toggle>
          </ToogleGroup>
        </Row>
      </Form.Group>
      {/* Labels */}
      <Form.Group className="mb-1" controlId="tags">
        <Form.Label className="mb-0">Labels</Form.Label>
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Filter by labels"
              value={newTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  let tag = action.option.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  updateAccountTags(tag);
                  dispatch(
                    setFilterParameters({
                      tags: filter?.tags ? [...filter?.tags, tag] : [tag],
                    })
                  );
                } else {
                  let tag = selectedOption.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  dispatch(
                    setFilterParameters({
                      tags: filter?.tags ? [...filter?.tags, tag] : [tag],
                    })
                  );
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.tags &&
            Array.isArray(filter?.tags) &&
            filter?.tags.length > 0 &&
            filter?.tags?.map((tag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveLabel(tag)}
              >
                {tag}
              </Tag>
            ))}
        </div>
      </Form.Group>
      {/* EXCLUDE LABELS */}
      <Form.Group className="mb-1" controlId="tags">
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Exclude labels"
              value={newTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  let newArr;
                  if (filter?.excludeTags) {
                    newArr = [
                      ...filter?.excludeTags,
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  } else {
                    newArr = [
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  }
                  dispatch(
                    setFilterParameters({
                      excludeTags: newArr,
                    })
                  );
                } else {
                  let newArr;
                  if (filter?.excludeTags) {
                    newArr = [
                      ...filter?.excludeTags,
                      selectedOption.value?.trim().toLowerCase(),
                    ];
                  } else {
                    newArr = [selectedOption.value?.trim().toLowerCase()];
                  }
                  dispatch(
                    setFilterParameters({
                      excludeTags: newArr,
                    })
                  );
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.excludeTags &&
            Array.isArray(filter?.excludeTags) &&
            filter?.excludeTags.length > 0 &&
            filter?.excludeTags.map((excludeTag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => {
                  dispatch(
                    setFilterParameters({
                      excludeTags: filter?.excludeTags.filter(
                        (tag) => tag !== excludeTag
                      ),
                    })
                  );
                  // Forece a timeout to make redux update the store before showing new
                }}
              >
                {excludeTag}
              </Tag>
            ))}
        </div>
      </Form.Group>
      {/* CREATOR TAGS */}
      <Form.Group className="mb-1" controlId="creator_tags">
        <Form.Label className="mb-0">Creator hashtags</Form.Label>
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Filter by creator hashtags"
              value={creatorTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  const tag = action.option.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  updateAccountTags(tag);
                  dispatch(
                    setFilterParameters({
                      creatorTags: filter?.creatorTags
                        ? [...filter?.creatorTags, tag]
                        : [tag],
                    })
                  );
                } else {
                  const tag = selectedOption.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  dispatch(
                    setFilterParameters({
                      creatorTags: filter?.creatorTags
                        ? [...filter?.creatorTags, tag]
                        : [tag],
                    })
                  );
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.creatorTags &&
            Array.isArray(filter?.creatorTags) &&
            filter?.creatorTags.length > 0 &&
            filter?.creatorTags.map((tag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveCreatorHashtag(tag)}
              >
                {tag}
              </Tag>
            ))}
        </div>
      </Form.Group>
      {/* EXCLUDE CREATOR HASTAGS */}
      <Form.Group className="mb-1" controlId="tags">
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Exclude creator hashtags"
              value={newTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  let newArr;
                  if (filter?.excludeCreatorHashtags) {
                    newArr = [
                      ...filter?.excludeCreatorHashtags,
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  } else {
                    newArr = [
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  }
                  dispatch(
                    setFilterParameters({
                      excludeCreatorHashtags: newArr,
                    })
                  );
                } else {
                  let newArr;
                  if (filter?.excludeCreatorHashtags) {
                    newArr = [
                      ...filter?.excludeCreatorHashtags,
                      selectedOption.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  } else {
                    newArr = [
                      selectedOption.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  }
                  dispatch(
                    setFilterParameters({
                      excludeCreatorHashtags: newArr,
                    })
                  );
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.excludeCreatorHashtags &&
            Array.isArray(filter?.excludeCreatorHashtags) &&
            filter?.excludeCreatorHashtags.length > 0 &&
            filter?.excludeCreatorHashtags.map((hashtag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveExcludeCreatorHashtag(hashtag)}
              >
                {hashtag}
              </Tag>
            ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-1">
        <Form.Label className="mb-0">
          Sources{" "}
          <span
            style={{
              fontSize: ".875em",
              fontWeight: "normal",
              color: "var(--bs-secondary-color)",
            }}
          >
            (max 10)
          </span>
        </Form.Label>
        {/* <Form.Group className="mb-1">
          <Form.Control
            as="select"
            name="sourceOwner"
            value={sourceOwnerId}
            onChange={(e) => setSourceOwnerId(e.target.value)}
            disabled={filterLoading}
          >
            <option value="showAll">All internal owners</option>
            {users?.map((user, index) => (
              <option key={index} value={user.id}>
                {user.email}
              </option>
            ))}
          </Form.Control>
          <Form.Text>Select among sources by owner</Form.Text>
        </Form.Group> */}
        <Form.Group className="mb-0">
          <ReelerTooltip
            text={
              filter?.rating?.length > 0 || filter?.aiScore
                ? "You cannot filter for source, ai score and star rating at the same time"
                : `Filter for sources (max 10)`
            }
          >
            <Form.Control
              as="select"
              name="campaigns"
              onChange={(e) => handleCampaigns(e.target.value)}
              disabled={
                filter?.rating?.length > 0 ||
                filter?.aiScore ||
                filter?.creators?.length > 0 ||
                filterLoading
              }
            >
              <option value="showAll">All sources</option>

              {campaigns
                .filter((campaign) => {
                  if (campaign.campaignName.length > 0) {
                    if (sourceOwnerId === "showAll") {
                      return true;
                    } else if (campaign.userId === sourceOwnerId) {
                      return true;
                    }
                  }
                })
                ?.sort((a, b) => a.campaignName.localeCompare(b.campaignName))
                .map((campaign, key) => (
                  <option
                    key={key}
                    value={campaign.id}
                    disabled={filter?.campaigns?.includes(campaign.id)}
                  >
                    {campaign.campaignName}
                  </option>
                ))}
            </Form.Control>
          </ReelerTooltip>
        </Form.Group>
        <div className="d-flex flex-wrap mt-1">
          {campaigns
            ?.filter((campaign) => filter?.campaigns?.includes(campaign.id))
            .map((campaign, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveCampaign(campaign.id)}
              >
                {campaign.campaignName}
              </Tag>
            ))}
        </div>
      </Form.Group>

      <FilterCreators />

      <Form.Group className="toggle-buttons-container mb-1">
        <Form.Label className="mb-0">Creator approval</Form.Label>
        <Row className="mb-1">
          <ToogleGroup
            name="status"
            value={filter?.status ? filter?.status : "showAll"}
            onChange={handleToogle}
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show all assets"
            >
              All
            </Toggle>
            <Toggle
              value={ASSET_STATUS.new}
              disabled={filterLoading}
              tooltip="Show only unmanaged"
            >
              <div style={{padding: "0.25em 0"}}>
                <S.StatusIcon color="var(--reeler-grey)" size="13px" />
              </div>
            </Toggle>
            <Toggle
              value={ASSET_STATUS.approved}
              disabled={filterLoading}
              tooltip="Show only content approved by creator"
            >
              <div style={{padding: "0.25em 0"}}>
                <S.StatusIcon color="var(--reeler-light)" size="13px" />
              </div>
            </Toggle>
            <Toggle
              value={ASSET_STATUS.pending}
              disabled={filterLoading}
              tooltip="Show only content with pending creator approval"
            >
              <div style={{padding: "0.25em 0"}}>
                <S.StatusIcon color="var(--reeler-warning-light)" size="13px" />
              </div>
            </Toggle>
            <Toggle
              value={ASSET_STATUS.notApproved}
              disabled={filterLoading}
              tooltip="Show only content not approved by creator"
            >
              <div style={{padding: "0.25em 0"}}>
                <S.StatusIcon color="var(--reeler-danger-light)" size="13px" />
              </div>
            </Toggle>
          </ToogleGroup>
        </Row>
      </Form.Group>
      <Form.Group className="toggle-buttons-container mb-1">
        <Form.Label className="mb-0">3rd party approval</Form.Label>
        <Row className="mb-1">
          <ToogleGroup
            name="thirdPartyApprovalStatus"
            value={
              filter?.thirdPartyApprovalStatus
                ? filter?.thirdPartyApprovalStatus
                : "showAll"
            }
            onChange={handleToogle}
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show all assets"
            >
              All
            </Toggle>

            <Toggle
              value={ASSET_STATUS.approved}
              disabled={filterLoading}
              tooltip="Show only content approved by 3rd party"
            >
              <div style={{padding: "0.5em 0"}}>
                <S.StatusIcon
                  color="var(--reeler-grey-dark)"
                  size="10px"
                  style={{marginBottom: "-3px"}}
                >
                  <S.ThirdPartyStatusIcon
                    color="var(--reeler-light)"
                    size="8px"
                  />
                </S.StatusIcon>
              </div>
            </Toggle>
            <Toggle
              value={ASSET_STATUS.pending}
              disabled={filterLoading}
              tooltip="Show only content with pending 3rd party approval"
            >
              <div style={{padding: "0.5em 0"}}>
                <S.StatusIcon
                  color="var(--reeler-grey-dark)"
                  size="10px"
                  style={{marginBottom: "-3px"}}
                >
                  <S.ThirdPartyStatusIcon
                    color="var(--reeler-warning-light)"
                    size="8px"
                  />
                </S.StatusIcon>
              </div>
            </Toggle>
            <Toggle
              value={ASSET_STATUS.notApproved}
              disabled={filterLoading}
              tooltip="Show only content not approved by 3rd party"
            >
              <div style={{padding: "0.5em 0"}}>
                <S.StatusIcon
                  color="var(--reeler-grey-dark)"
                  size="10px"
                  style={{marginBottom: "-3px"}}
                >
                  <S.ThirdPartyStatusIcon
                    color="var(--reeler-danger-light)"
                    size="8px"
                  />
                </S.StatusIcon>
              </div>
            </Toggle>
          </ToogleGroup>
        </Row>
      </Form.Group>

      <div className="mb-2 p-2" style={{background: "var(--reeler-grey)"}}>
        <ReelerAccordation
          className="mb-0"
          title={<Form.Label>More filters</Form.Label>}
          visible={false}
        >
          <Form.Group className="mb-1">
            <Form.Label className="mb-0">AI Content Score</Form.Label>
            <RowContainer spaceBetween>
              <ReelerTooltip
                text={
                  filter?.campaigns?.length > 0 ||
                  filter?.rating?.length > 0 ||
                  filter?.creators?.length > 0
                    ? "You cannot filter for source, creators, ai score and star rating at the same time"
                    : `Select lowest AI score`
                }
              >
                <Form.Control
                  as="select"
                  size="sm"
                  onChange={(e) => {
                    handleChangeAiScore("low", e.target.value);
                  }}
                  disabled={
                    filter?.rating?.length > 0 ||
                    filter?.campaigns?.length > 0 ||
                    filter?.creators?.length > 0 ||
                    filterLoading
                  }
                  defaultValue={filter?.aiScore?.low ? filter?.aiScore.low : 0}
                >
                  {aiScoreValues.map((score, index) => {
                    return (
                      <option
                        key={index}
                        value={score}
                        disabled={score >= filter?.aiScore?.high}
                      >
                        {score}
                      </option>
                    );
                  })}
                </Form.Control>
              </ReelerTooltip>
              <FaMinus style={{margin: "0 5px"}} />
              <ReelerTooltip
                text={
                  filter?.campaigns?.length > 0 ||
                  filter?.rating?.length > 0 ||
                  filter?.creators?.length > 0
                    ? "You cannot filter for creators, source, ai score and star rating at the same time"
                    : `Select highest AI score`
                }
              >
                <Form.Control
                  as="select"
                  size="sm"
                  onChange={(e) => {
                    handleChangeAiScore("high", e.target.value);
                  }}
                  disabled={
                    filter?.rating?.length > 0 ||
                    filter?.campaigns?.length > 0 ||
                    filter?.creators?.length > 0 ||
                    filterLoading
                  }
                  defaultValue={
                    filter?.aiScore?.high ? filter?.aiScore.high : 10
                  }
                >
                  {aiScoreValues.map((score, index) => {
                    return (
                      <option
                        key={index}
                        value={score}
                        disabled={score <= filter?.aiScore?.low}
                      >
                        {score}
                      </option>
                    );
                  })}
                </Form.Control>
              </ReelerTooltip>
            </RowContainer>
          </Form.Group>
          <Form.Group className="mb-0">
            <Form.Label className="mb-0">Orientation</Form.Label>
            <Row className="mb-1">
              <ToogleGroup
                name="orientation"
                value={filter?.orientation ? filter?.orientation : "showAll"}
                onChange={handleToogle}
              >
                <Toggle
                  value="showAll"
                  disabled={filterLoading}
                  tooltip="Show all orientations"
                >
                  All
                </Toggle>
                <Toggle
                  value={MEDIA_ORIENTATION.PORTRAIT}
                  disabled={filterLoading}
                  tooltip="Show only assets with portrait orientation"
                >
                  <div
                    style={{padding: "0.25em 0"}}
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "6.75px",
                        height: "12px",
                        background: "var(--reeler-grey-darker)",
                      }}
                    ></span>
                  </div>
                </Toggle>
                <Toggle
                  value={MEDIA_ORIENTATION.SQUARE}
                  disabled={filterLoading}
                  tooltip="Show only square assets"
                >
                  <div
                    style={{padding: "0.25em 0"}}
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "12px",
                        height: "12px",
                        background: "var(--reeler-grey-darker)",
                      }}
                    ></span>
                  </div>
                </Toggle>
                <Toggle
                  value={MEDIA_ORIENTATION.LANDSCAPE}
                  disabled={filterLoading}
                  tooltip="Show only assets with landscape orientation"
                >
                  <div
                    style={{padding: "0.5em 0"}}
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "12px",
                        height: "6.75px",
                        background: "var(--reeler-grey-darker)",
                      }}
                    ></span>
                  </div>
                </Toggle>
              </ToogleGroup>
            </Row>
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label className="mb-0">Rating</Form.Label>
            <InputGroup>
              {ratingValues.map((val) => (
                <ReelerTooltip
                  key={"rating-" + val}
                  text={
                    filter?.campaigns?.length > 0 ||
                    filter?.aiScore ||
                    filter?.creators?.length > 0
                      ? "You cannot filter for source, creators, ai score and star rating at the same time"
                      : `Filter for ${val} star assets`
                  }
                >
                  <Form.Check
                    id={"rating-" + val}
                    inline
                    label={val}
                    type="checkbox"
                    checked={filter?.rating?.includes(val) ? true : false}
                    onChange={() => handleRating(val)}
                    disabled={
                      filter?.campaigns?.length > 0 ||
                      filter?.aiScore ||
                      filter?.creators?.length > 0 ||
                      filterLoading
                    }
                  />
                </ReelerTooltip>
              ))}
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label className="mb-0">Date</Form.Label>
            <RowContainer spaceBetween>
              <DatePickerContainer>
                <DatePicker
                  name="startDate"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Start date"
                  isClearable
                  selected={filter?.startDate}
                  selectsStart
                  startDate={filter?.startDate}
                  endDate={filter?.endDate}
                  showYearDropdown
                  onChange={(date) => handleChangeDate("startDate", date)}
                  customInput={
                    <DateInput
                      placeholderText="Start date"
                      idDisabled={
                        filterLoading ||
                        !filter ||
                        (filter &&
                          Object.keys(filter)?.filter(
                            (key) => !["startDate", "endDate"].includes(key)
                          ).length === 0)
                      }
                    />
                  }
                />
              </DatePickerContainer>
              <FaMinus style={{margin: "0 5px"}} />
              <DatePickerContainer>
                <DatePicker
                  ref={endDateRef}
                  name="endDate"
                  dateFormat="yyyy-MM-dd"
                  isClearable
                  onChange={(date) => handleChangeDate("endDate", date)}
                  selected={filter?.endDate}
                  selectsEnd
                  startDate={filter?.startDate}
                  endDate={filter?.endDate}
                  minDate={filter?.startDate}
                  showYearDropdown
                  customInput={
                    <DateInput
                      placeholderText="End date"
                      idDisabled={
                        filterLoading ||
                        !filter ||
                        (filter &&
                          Object.keys(filter)?.filter(
                            (key) => !["startDate", "endDate"].includes(key)
                          ).length === 0)
                      }
                    />
                  }
                />
              </DatePickerContainer>
            </RowContainer>
            <div
              style={{
                fontSize: "0.65em",
                color: "var(--bs-secondary-color)",
              }}
            >
              The date range filter only works in combination with other
              filters. Please apply the other filter(s) first, then add the date
              range filter.
            </div>
          </Form.Group>

          {/* Ai Tags/Object labels */}
          <Form.Group className="mb-1" controlId="tags">
            <Form.Label className="mb-0">AI Tags</Form.Label>
            <InputGroup className="mb-1">
              <Form.Control
                ref={objectLabelRef}
                size="sm"
                placeholder="Filter by ai tags"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleAddObjectLabel();
                  }
                }}
              />

              <Button
                variant="outline-secondary"
                id="button-addon2"
                size="sm"
                onClick={() => handleAddObjectLabel()}
              >
                <FaSearch />
              </Button>
            </InputGroup>

            {/* <InputGroup className="d-flex align-items-center">
                <CreatableSelect
                  styles={customStyles}
                  isDisabled={filterLoading}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--reeler-o-25)",
                      primary: "var(--reeler-light)",
                    },
                  })}
                  placeholder="Filter by labels"
                  value={newTag}
                  onChange={(selectedOption, action) => {
                    if (action.action === "clear") {
                    } else if (action.action === "create-option") {
                      let label = action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, "")
                      updateAccountTags(label)
                      dispatch(
                        setFilterParameters({
                          objectLabels: filter?.objectLabels
                            ? [...filter?.objectLabels, label]
                            : [label],
                        })
                      )
                    } else {
                      let label = selectedOption.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, "")
                      dispatch(
                        setFilterParameters({
                          objectLabels: filter?.objectLabels
                            ? [...filter?.objectLabels, label]
                            : [label],
                        })
                      )
                    }
                  }}
                  options={account?.tags
                    ?.sort((a, b) => {
                      if (a.toLowerCase() < b.toLowerCase()) {
                        return -1
                      }
                      if (a.toLowerCase() > b.toLowerCase()) {
                        return 1
                      }
                      return 0
                    })
                    ?.map(t => ({ value: t, label: t }))}
                />
              </InputGroup> */}

            <div className="d-flex flex-wrap">
              {filter?.objectLabels &&
                Array.isArray(filter?.objectLabels) &&
                filter?.objectLabels.length > 0 &&
                filter?.objectLabels?.map((label, index) => (
                  <Tag
                    key={index}
                    disabled={filterLoading}
                    highlighted={true}
                    onClick={() => handleRemoveObjectLabel(label)}
                  >
                    {label}
                  </Tag>
                ))}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className="mb-0">Sort by</Form.Label>
            <RowContainer>
              <Form.Control
                as="select"
                name="orderBy"
                onChange={(e) => dispatch(setMediaSortBy(e.target.value))}
                disabled={filterLoading}
              >
                <option value="createdAt">Date</option>
                <option value="email">Email</option>
                <option value="title">Title</option>
              </Form.Control>
            </RowContainer>
          </Form.Group>
        </ReelerAccordation>
      </div>
    </>
  );
};

export default FilterParameters;
