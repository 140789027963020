import React, {useEffect, useState} from "react";
import _ from "lodash";
// Firebase
import {database} from "../../../firebase";
import firebase from "firebase/app";

// Redux
import SpinnerComponent from "../../commons/SpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {
  selectActiveFilter,
  selectSortBy,
  selectAssetView,
  selectShowFilter,
  selectFilterParameters,
  selectViewerFilterParameters,
  selectFiltredAssets,
  selectIsSearching,
} from "../../../redux/filter/filter.selectors";
import {
  setFiltredAssets,
  updateNumberOfFiltredAssets,
  setFilterLoading,
  setSubFiltredAssets,
  setIsSearching,
} from "../../../redux/filter/filter.actions";
import {selectAccountId} from "../../../redux/account/account.selectors";
import {BulkEditReset} from "../../../redux/bulk-edit/bulkEdit.actions";

// Reeler components
import ContentGridAsset from "../../campaigns/campaign/components/ContentGridAsset";
import InfiniteScroll from "react-infinite-scroll-component";
import NoContent from "../../commons/NoContent";
import AssetListView from "../../campaigns/campaign/components/AssetListView";
import AssetInfoModal from "../../campaigns/campaign/components/asset-info/AssetInfoModal";
import {MEDIA_TYPE} from "../../../constants";
import * as S from "../styles";

// RESET
const LIMIT = 50;

export default function MediaFiles() {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilterParameters);
  const filtredAssets = useSelector(selectFiltredAssets);
  const viewerFilter = useSelector(selectViewerFilterParameters);
  const showFilter = useSelector(selectShowFilter);
  const accountId = useSelector(selectAccountId);
  const sortBy = useSelector(selectSortBy);
  const assetView = useSelector(selectAssetView);
  const isSearching = useSelector(selectIsSearching);

  const [filteredAssetsLimit, setFilteredAssetsLimit] = useState(50);
  const [assets, setAssets] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const getQuery = () => {
    let query = database.assets.where("accountId", "==", accountId);

    if (filter?.assetId) {
      query = query.where(
        firebase.firestore.FieldPath.documentId(),
        "==",
        filter.assetId
      );
    }

    if (filter?.campaigns?.length > 0) {
      query = query.where("campaignId", "in", filter?.campaigns);
    }

    if (filter?.creators?.length > 0) {
      query = query.where("creatorId", "in", filter?.creators);
    }

    if (filter?.favourite) {
      if (filter?.favourite === "true") {
        query = query.where("library", "==", true);
      } else if (filter?.favourite === "false") {
        query = query.where("library", "==", false);
      }
    }
    if (filter?.state) {
      query = query.where("state", "==", filter?.state);
    }

    if (filter?.creatorEmails?.length === 1) {
      query = query.where("values.email.value", "==", filter?.creatorEmails[0]);
    }

    if (filter?.status) {
      query = query.where("status", "==", filter?.status);
    }

    if (filter?.thirdPartyApprovalStatus) {
      query = query.where(
        "thirdPartyApprovalStatus",
        "==",
        filter?.thirdPartyApprovalStatus
      );
    }

    if (filter?.type) {
      query = query.where("media_type", "==", filter?.type.toUpperCase());
    }

    if (filter?.campaign_type) {
      query = query.where("campaign_type", "==", filter?.campaign_type);
    }

    if (
      filter?.tags &&
      Array.isArray(filter?.tags) &&
      filter?.tags.length > 0
    ) {
      filter.tags.forEach((tag) => {
        query = query.where(`tags.${tag}`, "==", true);
      });
    }
    if (
      filter?.objectLabels &&
      Array.isArray(filter?.objectLabels) &&
      filter?.objectLabels.length > 0
    ) {
      filter.objectLabels.forEach((labels) => {
        query = query.where(`objectLabels.${labels}`, "==", true);
      });
    }

    if (filter?.creatorTags && filter?.creatorTags.length > 0) {
      filter.creatorTags.forEach((tag) => {
        query = query.where(`creator_tags.${tag}`, "==", true);
      });
    }

    if (filter?.orientation) {
      query = query.where("metadata.orientation", "==", filter?.orientation);
    }

    if (filter?.rating) {
      query = query.where("rating", "in", filter?.rating);
    }

    if (filter?.aiScore) {
      let aiScoreArray = [];
      for (var i = 0; i <= 10; i++) {
        aiScoreArray.push(i);
      }

      if (filter?.aiScore?.low) {
        aiScoreArray = aiScoreArray.filter(
          (score) => score >= filter?.aiScore?.low
        );
      }
      if (filter?.aiScore?.high) {
        aiScoreArray = aiScoreArray.filter(
          (score) => score <= filter?.aiScore?.high
        );
      }
      query = query.where("ai_score.rounded_score", "in", aiScoreArray);
    }

    return query;
  };

  const loadMoreAssets = () => {
    console.log("Load more assets");
    database.assets
      .where("accountId", "==", accountId)
      .orderBy("createdAt", "desc")
      .startAfter(lastVisible)
      .limit(LIMIT)
      .onSnapshot((documentSnapshots) => {
        const snapshotArray = [];
        documentSnapshots.docs.forEach((doc) => {
          let asset = database.formatDoc(doc);

          snapshotArray.push(asset);
        });

        const addNewAssets = assets.concat(snapshotArray);
        setAssets(addNewAssets);
        dispatch(setFiltredAssets(addNewAssets));
        setLastVisible(
          documentSnapshots.docs[documentSnapshots.docs.length - 1]
        );
        if (documentSnapshots.docs.length < LIMIT) setHasMore(false);
      });
  };

  useEffect(() => {
    if (isSearching && filter && accountId) {
      setLoading(true);
      dispatch(setFilterLoading(true));
      const unsubscribe = getQuery().onSnapshot((documentSnapshots) => {
        var snapshotArray = [];
        documentSnapshots.docs.forEach((doc) => {
          let asset = database.formatDoc(doc);
          snapshotArray.push(asset);
        });

        if (filter?.startDate) {
          snapshotArray = snapshotArray.filter(
            (asset) =>
              asset.createdAt.toDate().getTime() >= filter?.startDate.getTime()
          );
        }

        if (filter?.endDate) {
          snapshotArray = snapshotArray.filter(
            (asset) =>
              asset.createdAt.toDate().getTime() <= filter?.endDate.getTime()
          );
        }

        if (filter?.excludeTags && filter?.excludeTags.length > 0) {
          snapshotArray = snapshotArray.filter((asset) => {
            let tags = Object.keys(asset.tags);
            let hasExcludeTag = false;

            filter.excludeTags.forEach((excludeTag) => {
              if (tags.includes(excludeTag)) {
                hasExcludeTag = true;
              }
            });

            if (!hasExcludeTag) {
              return asset;
            }
          });
        }

        if (
          filter?.excludeCreatorHashtags &&
          filter?.excludeCreatorHashtags.length > 0
        ) {
          snapshotArray = snapshotArray.filter((asset) => {
            if (asset?.creator_tags) {
              let creatorHags = Object.keys(asset?.creator_tags);
              let excludeCreatorHashtags = false;

              filter.excludeCreatorHashtags.forEach((excludeTag) => {
                if (creatorHags.includes(excludeTag)) {
                  excludeCreatorHashtags = true;
                }
              });

              if (!excludeCreatorHashtags) {
                return asset;
              }
            } else {
              return asset;
            }
          });
        }

        snapshotArray.sort((a, b) => {
          /**
           * Create a function that will sort depending on user/filter
           */
          if (sortBy === "createdAt") {
            if (a?.createdAt?.seconds > b?.createdAt?.seconds) {
              return -1;
            }
            if (a?.createdAt?.seconds < b?.createdAt?.seconds) {
              return 1;
            }
            return 0;
          } else if (sortBy === "title") {
            return a["titleOfTheWork"]?.localeCompare(b["titleOfTheWork"]);
          } else if (sortBy === "email") {
            return a["email"]?.localeCompare(b["email"]);
          } else {
            return 0;
          }
        });

        setAssets(snapshotArray);
        dispatch(setFiltredAssets(snapshotArray));
        dispatch(updateNumberOfFiltredAssets(snapshotArray.length));

        setLoading(false);
        dispatch(setFilterLoading(false));
        dispatch(setIsSearching(false));
      });
      return () => unsubscribe();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, filter]);

  useEffect(() => {
    // If there is no filter active then load the 25 most recent assets for the account
    if (!filter && accountId) {
      setLoading(true);

      var unsubscribe = database.assets
        .where("accountId", "==", accountId)
        .orderBy("createdAt", "desc")
        .limit(LIMIT)
        .onSnapshot((documentSnapshots) => {
          var snapshotArray = [];
          documentSnapshots.docs.forEach((doc) => {
            let asset = database.formatDoc(doc);

            snapshotArray.push(asset);
          });

          setAssets(snapshotArray);
          dispatch(setFiltredAssets(snapshotArray));
          setLastVisible(
            documentSnapshots.docs[documentSnapshots.docs.length - 1]
          );
          if (documentSnapshots.docs.length < LIMIT) setHasMore(false);
          setLoading(false);
          dispatch(setFilterLoading(false));
        });
      return () => unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    dispatch(BulkEditReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sortBy && assets) {
      setLoading(true);
      dispatch(setFilterLoading(true));
      var sortArray = assets;
      sortArray.sort((a, b) => {
        /**
         * Create a function that will sort depending on user/filter
         */
        if (sortBy === "createdAt") {
          if (a?.createdAt?.seconds > b?.createdAt?.seconds) {
            return -1;
          }
          if (a?.createdAt?.seconds < b?.createdAt?.seconds) {
            return 1;
          }
          return 0;
        } else if (sortBy === "title") {
          return a["titleOfTheWork"]?.localeCompare(b["titleOfTheWork"]);
        } else if (sortBy === "email") {
          return a["email"]?.localeCompare(b["email"]);
        } else {
          return 0;
        }
      });

      setAssets(sortArray);
      setLoading(false);
      dispatch(setFilterLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  // Handle viewer filter
  useEffect(() => {
    if (!viewerFilter && filtredAssets) {
      setAssets(filtredAssets);
      dispatch(setSubFiltredAssets([]));
    }

    if (isSearching && viewerFilter && filtredAssets) {
      setLoading(true);
      dispatch(setFilterLoading(true));

      let filtredViewerAssets = filtredAssets;

      if (viewerFilter?.creatorEmails?.length === 1) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) => a?.values?.email?.value === viewerFilter?.creatorEmails[0]
        );
      }

      if (viewerFilter?.type) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) => a?.media_type === viewerFilter?.type.toUpperCase()
        );
      }

      if (viewerFilter?.campaign_type) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) =>
            a?.campaign_type.toUpperCase() ===
            viewerFilter?.campaign_type.toUpperCase()
        );
      }

      if (viewerFilter?.tags && viewerFilter?.tags.length > 0) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) =>
            a?.tags &&
            viewerFilter?.tags.every((ai) => Object.keys(a?.tags).includes(ai))
        );
      }
      if (viewerFilter?.excludeTags && viewerFilter?.excludeTags.length > 0) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) =>
            a?.tags &&
            viewerFilter?.excludeTags.every(
              (ai) => !Object.keys(a?.tags).includes(ai)
            )
        );
      }

      if (viewerFilter?.creatorTags && viewerFilter?.creatorTags.length > 0) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) =>
            a?.creator_tags &&
            viewerFilter?.creatorTags.every((ai) =>
              Object.keys(a?.creator_tags).includes(ai)
            )
        );
      }

      if (
        viewerFilter?.excludeCreatorHashtags &&
        viewerFilter?.excludeCreatorHashtags.length > 0
      ) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) =>
            a?.creator_tags &&
            viewerFilter?.excludeCreatorHashtags.every(
              (ai) => !Object.keys(a?.creator_tags).includes(ai)
            )
        );
      }

      if (viewerFilter?.orientation) {
        filtredViewerAssets = filtredViewerAssets?.filter(
          (a) => a?.metadata?.orientation === viewerFilter?.orientation
        );
      }

      if (viewerFilter?.rating) {
        filtredViewerAssets = filtredViewerAssets?.filter((a) =>
          viewerFilter?.rating.includes(a?.rating)
        );
      }

      setAssets(filtredViewerAssets);
      dispatch(setSubFiltredAssets(filtredViewerAssets));
      setLoading(false);
      dispatch(setFilterLoading(false));
      dispatch(setIsSearching(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, viewerFilter]);

  if (loading) return <SpinnerComponent size="lg" />;

  if (!loading && assets?.length === 0) return <NoContent />;

  // Default
  if (!filter && assets?.length > 0)
    return (
      <>
        <AssetInfoModal />
        <InfiniteScroll
          style={{overflow: "none"}}
          dataLength={assets.length} //This is important field to render the next data
          next={loadMoreAssets}
          hasMore={hasMore}
          loader={<SpinnerComponent size="lg" />}
        >
          <S.ContentContainer showFilter={showFilter}>
            {assets.map((asset) => {
              return assetView === "grid" ? (
                <ContentGridAsset key={asset.id} asset={asset} />
              ) : (
                <AssetListView key={asset.id} asset={asset} />
              );
            })}
          </S.ContentContainer>
        </InfiniteScroll>
      </>
    );
  // with filter applied
  return (
    <>
      <AssetInfoModal />
      <InfiniteScroll
        style={{overflow: "none"}}
        dataLength={filteredAssetsLimit}
        next={() => setFilteredAssetsLimit(filteredAssetsLimit + 50)}
        hasMore={filteredAssetsLimit <= assets.length}
        loader={<SpinnerComponent size="lg" />}
      >
        {assets && assets.length > 0 && (
          <S.ContentContainer showFilter={showFilter}>
            {assets
              .filter((item, index) => index <= filteredAssetsLimit)
              .map((asset) => {
                return assetView === "grid" ? (
                  <ContentGridAsset key={asset.id} asset={asset} />
                ) : (
                  <AssetListView key={asset.id} asset={asset} />
                );
              })}
          </S.ContentContainer>
        )}
      </InfiniteScroll>
    </>
  );
}
