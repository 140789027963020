import React from "react";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {
  clearFilter,
  setFilterParameters,
  setIsSearching,
} from "../../../redux/filter/filter.actions";
import {selectCampaign} from "../../../redux/campaign/campaign.selectors";
import ReelerButton from "../../commons/ReelerButton";
import CampaignNumberOfAssets from "./CampaignNumberOfAssets";

export default function ShowCampaingAssets() {
  const dispatch = useDispatch();
  const campaign = useSelector(selectCampaign);
  const history = useHistory();

  const showCampaignAssetsInContentTab = () => {
    dispatch(clearFilter());
    dispatch(setFilterParameters({campaigns: [campaign?.id]}));
    dispatch(setIsSearching(true));
    history.push(`/content`);
  };
  return (
    campaign && (
      <ReelerButton
        text={
          <div className="d-flex flex-row align-items-center">
            <span className="me-1">View content</span>
            <CampaignNumberOfAssets campaignId={campaign?.id} />
          </div>
        }
        dispatch={() => showCampaignAssetsInContentTab()}
        styleClass="btn-secondary"
      />
    )
  );
}
