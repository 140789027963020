import React from "react";
import {Row, Col} from "react-bootstrap";
import AssetAddProduct from "./components/AssetAddProduct";
import AssetProductList from "./components/AssetProductList";
import AssetAddCaption from "../AssetAddCaption";
import * as S from "../../../styles";

export default function AssetProducts() {
  return (
    <>
      <AssetAddProduct />
      <AssetProductList />
    </>
  );
}
