import React, {useEffect, useState} from "react";
import {database} from "../../../../../firebase";
import {useSelector, useDispatch} from "react-redux";
import {selectAccounts} from "../../../../../redux/account/account.selectors";
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent";
import {
  setCreators,
  setIsSearching,
} from "../../../../../redux/creators/creators.actions";
import {
  selectCreatorFilterParameters,
  selectCreators,
  selectCreatorTextSearch,
} from "../../../../../redux/creators/creators.selectors";
import {Table} from "react-bootstrap";
import CreatorRow from "./components/CreatorRow/CreatorRow";

import InfiniteScroll from "react-infinite-scroll-component";
import ReelerTooltip from "../../../../../components/commons/ReelerTooltip/ReelerTooltip";
import Text from "../../../../../components/commons/Text";
import CreatorsFilter from "./components/CreatorsFilter";
import asyncFilter from "../../../../../utils/AsyncFilter";

export default function CreatorsTable() {
  const [loading, setLoading] = useState(true);
  const [defaultCreators, setDefaultCreators] = useState(null);
  const account = useSelector(selectAccounts);
  const creators = useSelector(selectCreators);
  const [numberOfCreatorsToShow, setNumberOfCreatorsToShow] = useState(25);

  const filterParameters = useSelector(selectCreatorFilterParameters);
  const textSearch = useSelector(selectCreatorTextSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    if (account) {
      setLoading(true);

      let query = database.creators.where(
        "accounts",
        "array-contains",
        account.id
      );
      if (filterParameters?.list) {
        query = query.where(`lists.${filterParameters?.list}`, "==", true);
      }

      var unsubscribe = query.onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(database.formatDoc(doc));
        });
        dispatch(setCreators(data));
        setDefaultCreators(data);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [account?.id, filterParameters]);

  useEffect(() => {
    console.log("Searching");
    if (textSearch?.length > 0) {
      dispatch(setIsSearching(true));

      const search = textSearch.toLowerCase();

      const filteredCreators = defaultCreators?.filter((creator) => {
        return (
          creator?.firstName?.toLowerCase()?.includes(search) ||
          creator?.lastName?.toLowerCase()?.includes(search) ||
          creator?.email?.toLowerCase()?.includes(search) ||
          creator?.phone?.toLowerCase()?.includes(search) ||
          creator?.ig_username?.toLowerCase()?.includes(search)
        );
      });

      console.log(filteredCreators);

      dispatch(setCreators(filteredCreators));
      setTimeout(() => dispatch(setIsSearching(false)), 1000);
    } else {
      dispatch(setCreators(defaultCreators));
    }
  }, [textSearch]);

  if (loading) return <SpinnerComponent />;

  return (
    <div>
      <CreatorsFilter />
      <InfiniteScroll
        style={{overflow: "none"}}
        dataLength={numberOfCreatorsToShow}
        next={() => setNumberOfCreatorsToShow((prev) => prev + 25)}
        hasMore={numberOfCreatorsToShow <= creators?.length}
        loader={<SpinnerComponent size="lg" />}
      >
        <Table hover>
          <thead>
            <tr style={{}}>
              <th className="align-top" style={{maxWidth: "400px"}}>
                <div className="me-2">
                  {creators?.length > 0
                    ? `Creator (${creators?.length})`
                    : `Creator`}
                </div>
              </th>
              <th className="align-top" style={{maxWidth: "300px"}}>
                <div className="me-2">Instagram insights</div>
              </th>
              <th className="align-top" style={{maxWidth: "400px"}}>
                <div className="me-2">Lists</div>
              </th>
              <th className="align-top text-center" style={{maxWidth: "100px"}}>
                <ReelerTooltip text="Is the creator greenlisted?">
                  <Text>Greenlisted</Text>
                </ReelerTooltip>
              </th>
              <th className="align-top text-center" style={{maxWidth: "100px"}}>
                <ReelerTooltip text="New assets since you last reviewed this creator">
                  <Text>New</Text>
                </ReelerTooltip>
              </th>
              <th className="align-top text-center" style={{maxWidth: "100px"}}>
                <ReelerTooltip text="Total assets submitted">
                  <Text>Total</Text>
                </ReelerTooltip>
              </th>
              <th className="text-center" style={{width: "100px"}}></th>
            </tr>
          </thead>

          <tbody>
            {creators
              ?.filter((item, index) => index <= numberOfCreatorsToShow)
              ?.filter((creator) =>
                filterParameters?.whitelisted === true
                  ? creator?.whitelisted === true
                  : true
              )
              ?.filter((creator) =>
                filterParameters?.blacklisted === true
                  ? creator?.blacklisted === true
                  : creator?.blacklisted !== true
              )
              ?.filter((creator) =>
                filterParameters?.gender
                  ? filterParameters?.gender === creator?.gender
                  : true
              )
              .map((creator) => (
                <CreatorRow key={creator.id} creator={creator} />
              ))}
          </tbody>
        </Table>
      </InfiniteScroll>
    </div>
  );
}
