import {createSelector} from "reselect";

const selectFilter = (state) => state.filter;

export const selectFilterLoading = createSelector(
  [selectFilter],
  (filter) => filter?.loading
);
export const selectActiveFilter = createSelector(
  [selectFilter],
  (filter) => filter?.activeFilter
);
export const selectIsSearching = createSelector(
  [selectFilter],
  (filter) => filter?.isSearching
);
export const selectFilterParameters = createSelector(
  [selectFilter],
  (filter) => filter?.filterParameters
);

export const selectFiltredAssets = createSelector(
  [selectFilter],
  (filter) => filter?.filtredAssets
);
export const selectSubFiltredAssets = createSelector(
  [selectFilter],
  (filter) => filter?.subFiltredAssets
);

export const selectMediaFilterIsOpen = createSelector(
  [selectFilter],
  (filter) => filter?.mediaFilterIsOpen
);
export const selectForceClearFilter = createSelector(
  [selectFilter],
  (filter) => filter?.forceClearFilter
);

export const selectTags = createSelector(
  [selectActiveFilter],
  (filter) => filter?.tags
);

export const selectOrderBy = createSelector(
  [selectActiveFilter],
  (filter) => filter?.orderBy
);

export const selectSortBy = createSelector(
  [selectFilter],
  (filter) => filter?.sort_by
);

export const selectAssetView = createSelector(
  [selectFilter],
  (filter) => filter?.assetView
);

export const selectNumberOfFiltredAssets = createSelector(
  [selectFilter],
  (filter) => filter?.noOfFiltredAssets
);

export const selectActiveAccountFilter = createSelector(
  [selectFilter],
  (filter) => filter?.activeAccountFilter
);
export const selectShowFilter = createSelector(
  [selectFilter],
  (filter) => filter?.showFilter
);
export const selectShowSaveFilter = createSelector(
  [selectFilter],
  (filter) => filter?.showSaveFilter
);
export const selectShowViewers = createSelector(
  [selectFilter],
  (filter) => filter?.showViewers
);
export const selectViewerFilterParameters = createSelector(
  [selectFilter],
  (filter) => filter?.viewerFilterParameters
);
