import styled, {css} from "styled-components";

const SingleAsset = css`
  object-fit: cover;
  overflow: hidden;
  aspect-ratio: 1;
`;

const gridItem = css`
  outline: 0;
  width: ${({settings: {columns, gutter}, containerWidth}) =>
    `${containerWidth / columns - gutter * (columns - 1)}px`};

  height: ${({settings: {columns, gutter}, containerWidth}) =>
    `${containerWidth / columns - gutter * (columns - 1)}px`};
  margin: ${({settings: {gutter}}) => `${gutter}px`};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({settings: {columns}}) =>
    columns ? `repeat(${columns}, 1fr)` : `repeat(3, 1fr)`};
  grid-gap: ${({settings: {gutter}}) => (gutter ? `${gutter}px` : "0")};
`;

export const SingleVideo = styled.video`
  ${SingleAsset}
  width: ${({settings: {columns}}) => `${100 / columns - 2}%`};
  border-radius: ${({settings: {borderRadius}}) => `${borderRadius}px`};
  margin: ${({settings: {gutter}}) => `${gutter}px`};
`;
export const GridItemOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
  color: #fff;
  /* center overlay text */
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: ${({settings: {borderRadius}}) => `${borderRadius}px`};
`;
export const GridItem = styled.div`
  position: relative;
  padding: 0;
  overflow: hidden;
  border: none;
  cursor: ${({clickable}) => (clickable ? "pointer" : "inherit")};
  aspect-ratio: 1/1;
  border-radius: ${({settings: {borderRadius}}) => `${borderRadius}px`};
  &:hover ${GridItemOverlay} {
    display: ${({clickable}) => (clickable ? "flex" : "hidden")};
  }
`;

export const GridVideoItem = styled.div`
  ${gridItem}
  border-radius: ${({settings: {borderRadius}}) => `${borderRadius}px`};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: ${({br}) => `${br}px`};
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`;
export const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 5px;
  color: var(--reeler-link);
  box-shadow: var(--reeler-shadow);
  width: 200px;

  :hover {
    cursor: pointer;
    background: var(--reeler-grey);
  }
`;

export const ImageCardButton = styled.button`
  position: relative;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
  margin-bottom: ${({settings: {gutter}, feedLayout}) =>
    gutter && !["grid", "carousel"].includes(feedLayout) ? `${gutter}rem` : 0};
  margin-right: ${({settings: {gutter}, feedLayout}) =>
    gutter && feedLayout === "carousel" ? `${gutter}rem` : 0};
  &:focus {
    outline: none;
  }
  &:hover img {
    opacity: 0.5;
  }

  img {
    display: block;
  }
`;
export const ImageCardWwrapper = styled.div`
  aspect-ratio: ${({aspectRatio}) => `${aspectRatio}`};
  overflow: hidden;
  &:hover {
    transition: all 300ms ease-in-out;
    background: rgba(0, 0, 0, 0.7);
  }
`;

export const SocialMediaCardButton = styled.button`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
  border-radius: ${({settings: {borderRadius}}) => `${borderRadius}px`};
  margin-bottom: ${({settings: {gutter}}) => `${gutter}rem`};
  box-shadow: ${({settings: {showShadow}}) =>
    showShadow ? "0px 4px 4px 0px #00000040;" : null};
  &:focus {
    outline: none;
  }
  &:hover img {
    transition: all 300ms ease-in-out;
    opacity: 0.8;
  }

  img {
    display: block;
  }
`;
export const SocialMediaContentWrapper = styled.div`
  max-height: 200px;
  display: flex;
  align-self: center;
`;
export const SocialMediaCardBody = styled.div`
  flex: 1 1 auto;
  padding: 0.5rem 0.5rem !important;
`;
export const SocialMediaCardCaption = styled.div`
  width: 100%;
  min-width: 0;
  word-wrap: break-word;
  margin-bottom: 5px;
  font-size: 0.4rem;
  ${({showMore}) => (!showMore ? truncatedCaption : "")};
`;
const truncatedCaption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const ShowMoreButton = styled.button`
  font-size: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: var(--reeler-link);
  }
`;
export const SocialMediaCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.5rem !important;
`;
export const SocialMediaCardProfilePicture = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #63453f;
`;
