import React, {useState} from "react";
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {database} from "../../../firebase";
import {useDispatch} from "react-redux";
import {
  saveCampaign,
  updateCampaignsWithCampaign,
} from "../../../redux/campaign/campaign.actions";
import SpinnerComponent from "../../commons/SpinnerComponent";

export default function CampaignsToggleMenuVisibility({campaign}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleToggleMenuVisibility = async (value) => {
    console.log("handleToggleMenuVisibility");
    setLoading(true);
    console.log("saving campaign status");
    try {
      await database.campaigns.doc(campaign.id).update({
        menuVisibility: value,
      });
      console.log("saved campaign menuVisibility");

      const updatedCampaign = {
        ...campaign,
        menuVisibility: value,
      };
      dispatch(saveCampaign(updatedCampaign));
      dispatch(updateCampaignsWithCampaign(updatedCampaign));

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  if (loading) {
    return <SpinnerComponent size="sm" />;
  }

  return campaign.hasOwnProperty("menuVisibility") ? (
    campaign.menuVisibility ? (
      <ReelerTooltip text="Hide in menu" placement="top">
        <FaEye
          onClick={() => handleToggleMenuVisibility(false)}
          className="icon-color icon-btn"
        />
      </ReelerTooltip>
    ) : (
      <ReelerTooltip text="Show in menu" placement="top">
        <FaEyeSlash
          onClick={() => handleToggleMenuVisibility(true)}
          className="icon-color icon-btn"
        />
      </ReelerTooltip>
    )
  ) : (
    <ReelerTooltip text="Hide in menu" placement="top">
      <FaEye
        onClick={() => handleToggleMenuVisibility(false)}
        className="icon-color icon-btn"
      />
    </ReelerTooltip>
  );
}
