import React, {useRef, useEffect, useState} from "react";
// Firebase
import {database} from "../../../../../../firebase";
// 3rd-party
import {InputGroup, Form} from "react-bootstrap";
// Redux
import {useSelector, useDispatch} from "react-redux";
import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";
import {updateAsset} from "../../../../../../redux/asset/asset.actions";
import {BulkEditActionSuccess} from "../../../../../../redux/bulk-edit/bulkEdit.actions";
import FadeAlert from "../../../../../commons/FadeAlert/FadeAlert";
import {ModalTabHeading} from "../../styles";

import ReelerButton from "../../../../../commons/ReelerButton";

export default function AssetAddCaption() {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const asset = useSelector(selectActiveAsset);
  const inputRef = useRef(null);

  const handleSaveCaption = () => {
    setSaving(true);
    const updateData = {
      edited_caption: inputRef.current.value,
    };

    database.assets
      .doc(asset.id)
      .update(updateData)
      .then(() => {
        dispatch(
          updateAsset({
            ...asset,
            ...updateData,
          })
        );
        setSaving(false);
        dispatch(
          BulkEditActionSuccess({
            type: "success",
            duration: 3000,
            text: "Saved",
            position: "bulkEdit",
          })
        );
      })
      .catch((err) => {
        setSaving(false);
      });
  };

  return (
    <>
      <InputGroup className="mb-1">
        <Form.Control
          as="textarea"
          rows={3}
          disabled={saving}
          style={{fontSize: "12px"}}
          placeholder="Add an edited caption"
          defaultValue={asset?.edited_caption ? asset?.edited_caption : ""}
          ref={inputRef}
          onKeyUp={(e) => (e.key === "Enter" ? handleSaveCaption() : null)}
        />
      </InputGroup>
    </>
  );
}
