import React, {useState, useEffect, useRef} from "react";
import {
  Form,
  FormControl,
  InputGroup,
  ButtonGroup,
  ToggleButton,
  Row,
} from "react-bootstrap";
import {
  FaMinus,
  FaImages,
  FaVideo,
  FaTimes,
  FaPlus,
  FaMicrophone,
  FaEdit,
} from "react-icons/fa";

import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles.css";
import {RowContainer, DatePickerContainer} from "../../styles";
import {
  CAMPAIGN_TYPE,
  MEDIA_ORIENTATION,
  MEDIA_TYPE,
} from "../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {selectAccounts} from "../../../../redux/account/account.selectors";
import {
  setViewerFilterParameters,
  clearViewerFilterParameters,
} from "../../../../redux/filter/filter.actions";
import {BulkEditReset} from "../../../../redux/bulk-edit/bulkEdit.actions";

import {
  selectForceClearFilter,
  selectFilterLoading,
  selectViewerFilterParameters,
} from "../../../../redux/filter/filter.selectors";

import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip";
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton";
import ReelerAccordation from "../../../commons/ReelerAccordation";
import Tag from "../../../commons/Tag";
import {
  Toggle,
  ToogleGroup,
} from "../../../commons/ReelerToogleGroup/ReelerToggleGroup";

const ratingValues = [1, 2, 3, 4, 5];
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
};
const ViewerFilterParameters = () => {
  //FROM MEDIAFILTER
  const dispatch = useDispatch();
  const account = useSelector(selectAccounts);
  // const orderBy = useSelector(selectOrderBy)
  const filter = useSelector(selectViewerFilterParameters);
  const filterLoading = useSelector(selectFilterLoading);
  const forceClearFilter = useSelector(selectForceClearFilter);
  const [newTag, setNewTag] = useState("");
  const [creatorTag, setCreatorTag] = useState("");
  const creatorEmailRef = useRef();
  const [advancedFilter, setAdvancedFilter] = useState(false);

  const handleAdvancedFilter = () => {
    setAdvancedFilter(!advancedFilter);
  };

  const updateFilter = (filter) => {
    dispatch(setViewerFilterParameters(filter));
    dispatch(BulkEditReset());
  };

  const handleToogle = (toggleValue) => {
    const {name, value} = toggleValue;
    if (value !== "showAll") {
      updateFilter({[name]: value});
      return;
    }

    var newFilter = filter;

    delete newFilter[name];

    const isEmpty = newFilter && Object?.keys(newFilter)?.length === 0;

    if (isEmpty) {
      dispatch(clearViewerFilterParameters());
    } else {
      updateFilter(newFilter);
    }
  };

  // FILTER FUNCTIONS
  const handleFilter = ({target: {name, value}}) => {
    if (value !== "showAll") {
      if (name === "rating") {
        parseFloat(value);
        updateFilter({[name]: parseFloat(value)});
        return;
      }

      updateFilter({[name]: value});

      return;
    }

    var newFilter = filter;
    if (name === "type") {
      delete newFilter.type;
    } else if (name === "campaign_type") {
      delete newFilter.campaign_type;
    } else if (name === "favourite") {
      delete newFilter.favourite;
    } else if (name === "campaigns") {
      delete newFilter?.campaigns;
    } else if (name === "state") {
      delete newFilter?.state;
    } else if (name === "rating") {
      delete newFilter?.rating;
    } else if (name === "sourceOwnerId") {
      delete newFilter?.sourceOwnerId;
    } else if (name === "status") {
      delete newFilter?.status;
    } else if (name === "thirdPartyApprovalStatus") {
      delete newFilter?.thirdPartyApprovalStatus;
    } else if (name === "orientation") {
      delete newFilter?.orientation;
    }

    const isEmpty = Object.keys(newFilter)?.length === 0;

    if (isEmpty) {
      dispatch(clearViewerFilterParameters());
    } else {
      updateFilter(newFilter);
    }
  };

  const handleRating = (value) => {
    if (filter?.rating === undefined) {
      // Create new array and add value
      updateFilter({rating: [value]});
      return;
    }

    // Check if value already exists in rating array
    if (filter?.rating.includes(value)) {
      //Remove value
      let newArr = filter?.rating.filter((val) => val !== value);

      if (newArr.length === 0) {
        // Remove rating filter from filter
        delete filter?.rating;

        const isEmpty = Object.keys(filter)?.length === 0;
        if (isEmpty) {
          dispatch(clearViewerFilterParameters());
        } else {
          updateFilter(filter);
        }
      } else {
        // Update with new array
        updateFilter({rating: newArr});
      }
    } else {
      // Add value to array
      updateFilter({rating: [...filter?.rating, value]});
    }
  };

  const handleAddCreatorEmail = () => {
    let email = creatorEmailRef.current.value;
    console.log(email);
    if (email && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email)) {
      if (filter?.creatorEmails) {
        if (filter?.creatorEmails.length < 1) {
          updateFilter({
            creatorEmails: [...filter?.creatorEmails, email],
          });
          creatorEmailRef.current.value = "";
        }
      } else {
        updateFilter({
          creatorEmails: [email],
        });
        creatorEmailRef.current.value = "";
      }
    }
  };

  const handleRemoveCreatorEmail = (value) => {
    let creatorEmails = filter?.creatorEmails.filter(
      (email) => email !== value
    );

    if (creatorEmails.length > 0) {
      updateFilter({
        creatorEmails: creatorEmails,
      });
    } else {
      delete filter.creatorEmails;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearViewerFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  const handleRemoveLabel = (value) => {
    const tags = filter?.tags.filter((t) => t !== value);

    if (tags.length > 0) {
      updateFilter({
        tags,
      });

      setNewTag("");
    } else {
      delete filter.tags;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearViewerFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  const handleRemoveCreatorHashtag = (value) => {
    const creatorTags = filter?.creatorTags.filter((t) => t !== value);

    if (creatorTags.length > 0) {
      updateFilter({
        creatorTags,
      });

      setCreatorTag("");
    } else {
      delete filter.creatorTags;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearViewerFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };
  const handleRemoveExcludeCreatorHashtag = (value) => {
    const excludeCreatorHashtags = filter?.excludeCreatorHashtags.filter(
      (t) => t !== value
    );

    if (excludeCreatorHashtags.length > 0) {
      updateFilter({
        excludeCreatorHashtags,
      });

      setCreatorTag("");
    } else {
      delete filter.excludeCreatorHashtags;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearViewerFilterParameters());
      } else {
        updateFilter(filter);
      }
    }
  };

  return (
    <>
      {/* TOGGLE BUTTONS */}
      <Form.Group className="toggle-buttons-container mb-1">
        <Row className="mb-1">
          <ToogleGroup
            name="type"
            value={filter?.type ? filter?.type : "showAll"}
            onChange={handleToogle}
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show all types"
            >
              All
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.IMAGE}
              disabled={filterLoading}
              tooltip="Show images only"
            >
              <FaImages />
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.VIDEO}
              disabled={filterLoading}
              tooltip="Show videos only"
            >
              <FaVideo />
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.AUDIO}
              disabled={filterLoading}
              tooltip="Show audio only"
            >
              <FaMicrophone />
            </Toggle>
            <Toggle
              value={MEDIA_TYPE.TEXT}
              disabled={filterLoading}
              tooltip="Show text only"
            >
              <FaEdit />
            </Toggle>
          </ToogleGroup>
        </Row>
        <Row className="mb-1">
          <ToogleGroup
            name="campaign_type"
            value={filter?.campaign_type ? filter?.campaign_type : "showAll"}
            onChange={handleToogle}
            tooltip="Filter by source type"
          >
            <Toggle
              value="showAll"
              disabled={filterLoading}
              tooltip="Show content from all sources"
            >
              All
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.igHashtag.type}
              disabled={filterLoading}
              tooltip="Show content from Instagram hashtag"
            >
              {CAMPAIGN_TYPE.igHashtag.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.igMentions.type}
              disabled={filterLoading}
              tooltip="Show content from Instagram mentions"
            >
              {CAMPAIGN_TYPE.igMentions.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.upload.type}
              disabled={filterLoading}
              tooltip="Show content from web form content sources"
            >
              {CAMPAIGN_TYPE.upload.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.whatsapp.type}
              disabled={filterLoading}
              tooltip="Show content from WhatsApp"
            >
              {CAMPAIGN_TYPE.whatsapp.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.email.type}
              disabled={filterLoading}
              tooltip="Show content from email content sources"
            >
              {CAMPAIGN_TYPE.email.icon}
            </Toggle>
            <Toggle
              value={CAMPAIGN_TYPE.import.type}
              disabled={filterLoading}
              tooltip="Show imported assets"
            >
              {CAMPAIGN_TYPE.import.icon}
            </Toggle>
          </ToogleGroup>
        </Row>
      </Form.Group>

      {/* Labels */}
      <Form.Group className="mb-1" controlId="tags">
        <Form.Label className="mb-0">Labels</Form.Label>
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Filter by labels"
              value={newTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  let tag = action.option.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  updateFilter({
                    tags: filter?.tags ? [...filter?.tags, tag] : [tag],
                  });
                } else {
                  let tag = selectedOption.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");

                  updateFilter({
                    tags: filter?.tags ? [...filter?.tags, tag] : [tag],
                  });
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.tags &&
            filter?.tags.length > 0 &&
            filter?.tags.map((tag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveLabel(tag)}
              >
                {tag}
              </Tag>
            ))}
        </div>
      </Form.Group>
      {/* EXCLUDE LABELS */}
      <Form.Group className="mb-1" controlId="tags">
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Exclude labels"
              value={newTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  let newArr;
                  if (filter?.excludeTags) {
                    newArr = [
                      ...filter?.excludeTags,
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  } else {
                    newArr = [
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  }
                  updateFilter({
                    excludeTags: newArr,
                  });
                } else {
                  let newArr;
                  if (filter?.excludeTags) {
                    newArr = [
                      ...filter?.excludeTags,
                      selectedOption.value?.trim().toLowerCase(),
                    ];
                  } else {
                    newArr = [selectedOption.value?.trim().toLowerCase()];
                  }
                  updateFilter({
                    excludeTags: newArr,
                  });
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.excludeTags &&
            filter?.excludeTags.length > 0 &&
            filter?.excludeTags.map((excludeTag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => {
                  updateFilter({
                    excludeTags: filter?.excludeTags.filter(
                      (tag) => tag !== excludeTag
                    ),
                  });

                  // Forece a timeout to make redux update the store before showing new
                }}
              >
                {excludeTag}
              </Tag>
            ))}
        </div>
      </Form.Group>
      {/* CREATOR TAGS */}
      <Form.Group className="mb-1" controlId="creator_tags">
        <Form.Label className="mb-0">Creator hashtags</Form.Label>
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Filter by creator hashtags"
              value={creatorTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  const tag = action.option.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  updateFilter({
                    creatorTags: filter?.creatorTags
                      ? [...filter?.creatorTags, tag]
                      : [tag],
                  });
                } else {
                  const tag = selectedOption.value
                    ?.trim()
                    .toLowerCase()
                    .replace(/#+/g, "");
                  updateFilter({
                    creatorTags: filter?.creatorTags
                      ? [...filter?.creatorTags, tag]
                      : [tag],
                  });
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.creatorTags &&
            filter?.creatorTags.length > 0 &&
            filter?.creatorTags.map((tag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveCreatorHashtag(tag)}
              >
                {tag}
              </Tag>
            ))}
        </div>
      </Form.Group>
      {/* EXCLUDE CREATOR HASTAGS */}
      <Form.Group className="mb-1" controlId="tags">
        <RowContainer>
          <InputGroup className="d-flex align-items-center">
            <CreatableSelect
              styles={customStyles}
              isDisabled={filterLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "var(--reeler-o-25)",
                  primary: "var(--reeler-light)",
                },
              })}
              placeholder="Exclude creator hashtags"
              value={newTag}
              onChange={(selectedOption, action) => {
                if (action.action === "clear") {
                } else if (action.action === "create-option") {
                  let newArr;
                  if (filter?.excludeCreatorHashtags) {
                    newArr = [
                      ...filter?.excludeCreatorHashtags,
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  } else {
                    newArr = [
                      action.option.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  }
                  updateFilter({
                    excludeCreatorHashtags: newArr,
                  });
                } else {
                  let newArr;
                  if (filter?.excludeCreatorHashtags) {
                    newArr = [
                      ...filter?.excludeCreatorHashtags,
                      selectedOption.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  } else {
                    newArr = [
                      selectedOption.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/#+/g, ""),
                    ];
                  }
                  updateFilter({
                    excludeCreatorHashtags: newArr,
                  });
                }
              }}
              options={account?.tags
                ?.sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }
                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((t) => ({value: t, label: t}))}
            />
          </InputGroup>
        </RowContainer>
        <div className="d-flex flex-wrap">
          {filter?.excludeCreatorHashtags &&
            filter?.excludeCreatorHashtags.length > 0 &&
            filter?.excludeCreatorHashtags.map((hashtag, index) => (
              <Tag
                key={index}
                disabled={filterLoading}
                highlighted={true}
                onClick={() => handleRemoveExcludeCreatorHashtag(hashtag)}
              >
                {hashtag}
              </Tag>
            ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-1">
        <Form.Label className="mb-0">Creator email</Form.Label>

        <InputGroup>
          <FormControl
            ref={creatorEmailRef}
            type="email"
            name="creatorEmail"
            placeholder="Filter by creator email (max 1)"
            disabled={filter?.creatorEmails?.length > 0 || filterLoading}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleAddCreatorEmail();
              }
            }}
          />
          <ReelerIconButton
            icon={<FaPlus />}
            styleClass="btn-filter"
            disabled={filter?.creatorEmails?.length > 0}
            dispatch={() => {
              handleAddCreatorEmail();
            }}
          />
        </InputGroup>
        <div className="d-flex flex-wrap">
          {filter?.creatorEmails?.map((email, index) => (
            <Tag
              key={index}
              disabled={filterLoading}
              highlighted={true}
              onClick={() => handleRemoveCreatorEmail(email)}
            >
              {email}
            </Tag>
          ))}
        </div>
      </Form.Group>

      <div className="mb-2 p-2" style={{background: "var(--reeler-grey)"}}>
        <ReelerAccordation
          className="mb-0"
          title={<Form.Label>More filters</Form.Label>}
          visible={false}
        >
          <Form.Group className="mb-0">
            <Form.Label className="mb-0">Orientation</Form.Label>
            <Row className="mb-1">
              <ToogleGroup
                name="orientation"
                value={filter?.orientation ? filter?.orientation : "showAll"}
                onChange={handleToogle}
              >
                <Toggle
                  value="showAll"
                  disabled={filterLoading}
                  tooltip="Show all orientations"
                >
                  All
                </Toggle>
                <Toggle
                  value={MEDIA_ORIENTATION.PORTRAIT}
                  disabled={filterLoading}
                  tooltip="Show only assets with portrait orientation"
                >
                  <div
                    style={{padding: "0.25em 0"}}
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "6.75px",
                        height: "12px",
                        background: "var(--reeler-grey-darker)",
                      }}
                    ></span>
                  </div>
                </Toggle>
                <Toggle
                  value={MEDIA_ORIENTATION.SQUARE}
                  disabled={filterLoading}
                  tooltip="Show only square assets"
                >
                  <div
                    style={{padding: "0.25em 0"}}
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "12px",
                        height: "12px",
                        background: "var(--reeler-grey-darker)",
                      }}
                    ></span>
                  </div>
                </Toggle>
                <Toggle
                  value={MEDIA_ORIENTATION.LANDSCAPE}
                  disabled={filterLoading}
                  tooltip="Show only assets with landscape orientation"
                >
                  <div
                    style={{padding: "0.5em 0"}}
                    className="d-flex w-100 h-100 justify-content-center align-items-center"
                  >
                    <span
                      style={{
                        display: "flex",
                        width: "12px",
                        height: "6.75px",
                        background: "var(--reeler-grey-darker)",
                      }}
                    ></span>
                  </div>
                </Toggle>
              </ToogleGroup>
            </Row>
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label className="mb-0">Rating</Form.Label>
            <InputGroup>
              {ratingValues.map((val) => (
                <ReelerTooltip
                  key={"rating-" + val}
                  text={
                    filter?.campaigns?.length > 0
                      ? "You cannot filter for source and for star rating at the same time"
                      : `Filter for ${val} star assets`
                  }
                >
                  <Form.Check
                    id={"rating-" + val}
                    inline
                    label={val}
                    type="checkbox"
                    checked={filter?.rating?.includes(val) ? true : false}
                    onChange={() => handleRating(val)}
                    disabled={filter?.campaigns?.length > 0 || filterLoading}
                  />
                </ReelerTooltip>
              ))}
            </InputGroup>
          </Form.Group>
        </ReelerAccordation>
      </div>
    </>
  );
};

export default ViewerFilterParameters;
