import React, {useState, useEffect, forwardRef} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {v4 as uuidv4} from "uuid";
import "./ReelerTooltip.css";

const ReelerTooltip = ({placement = "top", text = "", children}) => {
  const [tooltipText, setTooltipText] = useState(text);

  useEffect(() => {
    setTooltipText(text);
  }, [text]);

  const id = uuidv4();

  const ChildComponent = forwardRef((props, ref) => (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  ));

  const renderTooltip = (props) => (
    <Tooltip id={id} {...props} style={{maxWidth: "400px"}}>
      {tooltipText}
    </Tooltip>
  );

  return tooltipText ? (
    <OverlayTrigger placement={placement} overlay={renderTooltip()}>
      <span>{children}</span>
    </OverlayTrigger>
  ) : (
    children
  );
};

export default ReelerTooltip;
