import React, {useEffect, useState} from "react";
import firebase from "firebase/app";

import AssetTextField from "./AssetTextField";
import {
  FaEdit,
  FaPlus,
  FaUserAlt,
  FaUserAltSlash,
  FaUserPlus,
} from "react-icons/fa";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";
import {MAIN_ROUTES} from "../../../../../../constants/routes";
import EditCreator from "../../../../../../pages/Creators/components/EditCreator/EditCreator";
import Icon from "../../../../../commons/Icon";
import {useHistory} from "react-router-dom";
import {database} from "../../../../../../firebase";
import {
  setCloseAssetInfoModal,
  updateAsset,
} from "../../../../../../redux/asset/asset.actions";
import SpinnerComponent from "../../../../../commons/SpinnerComponent";
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";

export default function AssetCreator() {
  const [loading, setLoading] = useState(false);
  const [isLoadingCreatorss, setIsLoadingCreators] = useState(false);
  const [creator, setCreator] = useState(null);
  const [creators, setCreators] = useState(null);
  const history = useHistory();
  const asset = useSelector(selectActiveAsset);
  const dispatch = useDispatch();
  const {setSuccessMessage, setErrorMessage} = useUserFeedbackMessage();

  useEffect(() => {
    if (asset?.creatorId) {
      setLoading(true);
      const subscribe = database.creators
        .doc(asset.creatorId)
        .onSnapshot((doc) => {
          const data = database.formatDoc(doc);
          setCreator(data);
          setLoading(false);
        });

      return () => subscribe();
    } else {
      setCreator(null);
    }
  }, [asset.id, asset?.creatorId]);

  useEffect(() => {
    if (asset?.accountId) {
      setIsLoadingCreators(true);

      database.creators
        .where("accounts", "array-contains", asset.accountId)
        .get()
        .then((querySnapshot) => {
          const creatorsArr = [];
          querySnapshot.forEach((doc) => {
            const data = database.formatDoc(doc);
            creatorsArr.push(data);
          });
          setCreators(creatorsArr);
          setIsLoadingCreators(false);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
          setIsLoadingCreators(false);
        });
    }
  }, [asset.accountId]);

  const showCreator = () => {
    dispatch(setCloseAssetInfoModal());
    history.push(MAIN_ROUTES.creator.path + "/" + creator.id);
  };

  const ConnectCreator = () => {
    const handleConnectCreator = (creatorId) => {
      setLoading(true);
      const assetRef = database.assets.doc(asset.id);
      assetRef
        .update({
          creatorId,
        })
        .then(() => {
          dispatch(
            updateAsset({
              ...asset,
              creatorId,
            })
          );
          setSuccessMessage("Creator connected to asset");
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage("Error connecting creator to asset");
          setLoading(false);
        });
    };
    return creators ? (
      <div className="d-flex flex-row align-items-center">
        <div style={{width: "300px"}}>
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "var(--reeler-o-25)",
                primary: "var(--reeler-light)",
              },
            })}
            placeholder="Search and connect creator to asset"
            onChange={(selectedOption, action) => {
              handleConnectCreator(selectedOption.value);
            }}
            options={creators?.map((c) => ({
              value: c.id,
              label: formatCreatorName(c),
            }))}
          />
        </div>
        <div className="mx-3">
          <EditCreator
            title={"New creator"}
            btn={
              <Icon tooltip="New creator">
                <FaUserPlus size={20} />
              </Icon>
            }
            assetId={asset.id}
          />
        </div>
      </div>
    ) : null;
  };

  const formatCreatorName = (creator) => {
    let name = "";
    creator?.firstName && (name += creator?.firstName);

    if (creator?.firstName && creator?.lastName) {
      name += " ";
    }
    creator?.lastName && (name += creator?.lastName);

    if (creator?.email || creator?.ig_username) {
      name += " (";
      creator.email && (name += creator?.email);
      if (creator.email && creator.ig_username) {
        name += ", ";
      }
      creator.ig_username && (name += ` ${creator?.ig_username}`);
      name += ")";
    }

    return name;
  };

  const DisconnectCreatorFromAsset = () => {
    const handleDisconnectCreator = () => {
      setLoading(true);
      const assetRef = database.assets.doc(asset.id);
      assetRef
        .update({
          creatorId: firebase.firestore.FieldValue.delete(),
        })
        .then(() => {
          dispatch(
            updateAsset({
              ...asset,
              creatorId: null,
            })
          );
          setSuccessMessage("Creator disconnected from asset");
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage("Error disconnecting creator from asset");
          setLoading(false);
        });
    };

    return (
      <Icon
        tooltip="Disonnect creator from asset"
        onClick={() => handleDisconnectCreator()}
      >
        <FaUserAltSlash />
      </Icon>
    );
  };

  return (
    <AssetTextField
      icon={
        <ReelerTooltip text="Creator">
          <FaUserAlt className="icon-color" />
        </ReelerTooltip>
      }
      text={
        loading ? (
          <SpinnerComponent size="sm" />
        ) : creator ? (
          <div className="d-flex flex-row align-items-center">
            <ReelerTooltip text="View creator">
              <span
                className="me-1 link icon-btn"
                onClick={() => showCreator()}
              >
                {formatCreatorName(creator)}
              </span>
            </ReelerTooltip>
            <div className="mx-1">
              <EditCreator
                title={"Edit creator"}
                btn={
                  <Icon tooltip="Edit creator">
                    <FaEdit />
                  </Icon>
                }
                creator={creator}
              />
            </div>
            <div className="mx-1">
              <DisconnectCreatorFromAsset />
            </div>
          </div>
        ) : (
          <ConnectCreator />
        )
      }
    />
  );
}
