import React from "react"
import AssetTextField from "./AssetTextField"
import { FaLink, FaShare } from "react-icons/fa"
import CopyToClipboard from "../../../../../commons/CopyToClipboard"
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage"
import { Button } from "react-bootstrap"

export default function AssetShare({ assetId }) {
  const link = process.env.REACT_APP_FIREBASE_APP_URL + "/content/" + assetId
  const { setSuccessMessage } = useUserFeedbackMessage()

  const copyUrl = () => {
    navigator.clipboard.writeText(link)
    setSuccessMessage("Link copied to clipboard")
  }

  return (
    <Button size="sm" variant="secondary" onClick={() => copyUrl()}>
      <FaShare />
      <span className="ms-2">Share</span>
    </Button>
  )
}
