import React, {createContext, useMemo, useContext} from "react";

import * as S from "./ReelerToggleGroup.styles";
import ReelerTooltip from "../ReelerTooltip/ReelerTooltip";

// https://github.com/joshuawootonn/react-components-from-scratch/blob/main/components/toggle-group/toggle-group.tsx
// https://www.joshuawootonn.com/react-toggle-group-component

const ToggleGroupContext = createContext({
  name: null,
  value: null,
  onChange: () => {},
});

const ToogleGroup = ({name, value, onChange, children, ...props}) => {
  const providerValue = useMemo(
    () => ({
      name,
      value,
      onChange,
    }),
    [value, onChange]
  );
  return (
    <ToggleGroupContext.Provider value={providerValue}>
      <S.ToggleButtonGroup {...props}>{children}</S.ToggleButtonGroup>
    </ToggleGroupContext.Provider>
  );
};

const Toggle = ({children, value, tooltip, ...props}) => {
  const {value: selectedValue, onChange, name} = useContext(ToggleGroupContext);

  return (
    <S.ToogleButton
      {...props}
      selected={selectedValue === value}
      onClick={(e) => {
        onChange({name, value});
        props.onClick?.(e);
      }}
    >
      <ReelerTooltip text={tooltip}>{children}</ReelerTooltip>
    </S.ToogleButton>
  );
};

export {ToogleGroup, Toggle};
