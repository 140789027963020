import React, {useState, useEffect} from "react";
import AssetTextField from "./AssetTextField";
import {database} from "../../../../../../firebase";
import {useHistory} from "react-router-dom";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";
import SpinnerComponent from "../../../../../commons/SpinnerComponent";
import {CAMPAIGN_TYPE} from "../../../../../../constants";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";
import {
  clearFilter,
  setFilterParameters,
} from "../../../../../../redux/filter/filter.actions";
import {BulkEditReset} from "../../../../../../redux/bulk-edit/bulkEdit.actions";
import {loadCampaign} from "../../../../../../redux/campaign/campaign.actions";
import {MAIN_ROUTES} from "../../../../../../constants/routes";
import {setCloseAssetInfoModal} from "../../../../../../redux/asset/asset.actions";

export default function AssetSourceName() {
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const asset = useSelector(selectActiveAsset);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (asset) {
      database.campaigns
        .doc(asset.campaignId)
        .get()
        .then((campaignDoc) => {
          if (campaignDoc.exists) {
            setCampaign(database.formatDoc(campaignDoc));

            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [asset]);

  const showSource = async () => {
    // Get Campaign fom firestore

    dispatch(clearFilter());
    dispatch(BulkEditReset());
    dispatch(setFilterParameters({campaigns: [campaign.id]}));
    dispatch(loadCampaign(campaign));
    dispatch(setCloseAssetInfoModal());
    history.push(MAIN_ROUTES.content.path);
  };

  return loading && !campaign && !asset ? (
    <SpinnerComponent size="sm" />
  ) : (
    <AssetTextField
      icon={
        <ReelerTooltip text="Source name">
          <span className="icon-color">
            {asset.campaign_type === CAMPAIGN_TYPE.upload.type
              ? CAMPAIGN_TYPE.upload.icon
              : asset.campaign_type === "INSTAGRAM" ||
                asset.campaign_type === CAMPAIGN_TYPE.igHashtag.type
              ? CAMPAIGN_TYPE.igHashtag.icon
              : asset.campaign_type === CAMPAIGN_TYPE.email.type
              ? CAMPAIGN_TYPE.email.icon
              : asset.campaign_type === CAMPAIGN_TYPE.igMentions.type
              ? CAMPAIGN_TYPE.igMentions.icon
              : asset.campaign_type === CAMPAIGN_TYPE.whatsapp.type
              ? CAMPAIGN_TYPE.whatsapp.icon
              : asset.campaign_type === CAMPAIGN_TYPE.import.type
              ? CAMPAIGN_TYPE.import.icon
              : null}
          </span>
        </ReelerTooltip>
      }
      text={
        <div className="mt-1 link icon-btn" onClick={() => showSource()}>
          {campaign?.campaignName}
        </div>
      }
    />
  );
}
