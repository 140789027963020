import React, {useState} from "react";
import * as S from "../SocialWall.styles";

import MediaType from "../../MediaType";
import {IMG_SIZES} from "../../../constants";
import FeedItemSoMeIcon from "../../FeedItemSoMeIcon";
import FeedModal from "../../FeedModal/FeedModal";

export default function SocialWallItem({asset, feed}) {
  const settings = feed.feedSettings;

  const [show, setShow] = useState(false);
  const handleOnClick = () => {
    if (asset?.products[0]?.url) {
      window.open(asset.products[0].url, "_blank", "noreferrer");
    }
  };
  return (
    <>
      <S.SocialWallCard
        settings={settings}
        clickable={settings?.showFeedModal || settings?.linkToProductUrl}
        {...(settings.showFeedModal
          ? {
              onClick: () => setShow(true),
            }
          : settings.linkToProductUrl
          ? {
              onClick: handleOnClick,
            }
          : null)}
      >
        {settings.socialMediaIcon ? <FeedItemSoMeIcon asset={asset} /> : null}
        <div
          style={{
            marginBottom:
              asset?.type?.split("/")[0] === "video" ? `-6px` : null,
          }}
        >
          <MediaType
            asset={asset}
            imgSize={IMG_SIZES.preView}
            objectFit="contain"
            videoSettings={{
              autoplay: "autoplay",
              muted: true,
              loop: true,
              controls: false,
              playsinline: true,
            }}
          />
        </div>
        {asset?.edited_caption && settings.showCaptionUnderImage ? (
          <S.SocialWallCardBody>
            <>
              <S.Caption>{asset?.edited_caption}</S.Caption>
            </>
          </S.SocialWallCardBody>
        ) : null}
        <S.Overlay settings={settings}></S.Overlay>
      </S.SocialWallCard>
      {settings?.showFeedModal ? (
        <FeedModal
          isOpen={show}
          feed={feed}
          asset={asset}
          onClose={() => setShow(false)}
        />
      ) : null}
    </>
  );
}
