import React, {useState, useEffect} from "react";
import {storage} from "../../../../firebase";
// 3 party
import {
  Form,
  Col,
  Row,
  Button,
  ButtonGroup,
  ProgressBar,
} from "react-bootstrap";
import {FaAlignCenter, FaAlignLeft, FaAlignRight} from "react-icons/fa";
import {v4 as uuidv4} from "uuid";
import {ReactComponent as DummyImage} from "../../../../assets/images/DummyAsset_optimized.svg";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions";
import {
  selectCampaign,
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors";
import ReelerButton from "../../../commons/ReelerButton";
import SaveField from "./SaveField";

export default function ImageField({previousFieldId, closeModal}) {
  const dispatch = useDispatch();
  const formSchema = useSelector(selectFormSchema);
  const fieldId = useSelector(selectedField);
  const campaign = useSelector(selectCampaign);
  const [settings, setSettings] = useState({
    widget: "image",
    width: 100,
    image_url: "",
    export: false,
  });
  const [fileMessage, setFileMessage] = useState();
  const [uploadError, setUploadError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId];
      setSettings(defaultValues);
    }
  }, [formSchema, fieldId]);

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setImage(null);

    if (file == null) return;

    let format = file.name.split(".").pop().toLowerCase();
    let allowedFormats = ["png", "jpg", "jpeg"];

    if (!allowedFormats.includes(format)) {
      setFileMessage("File format can only be png, jpg or jpeg");
      return;
    }

    if (file.size > 5000000) {
      setFileMessage("File size is too big. File size limit is 5MB");
      return;
    }

    setSettings({...settings, image_url: URL.createObjectURL(file)});
    setImage(file);
  };

  const handleSubmit = () => {
    setSaving(true);
    const field = settings;

    // Check if there is a new file to upload
    if (image) {
      // Generate an id for filename
      const filename = uuidv4();
      let format = image.name.split(".").pop().toLowerCase();

      // Upload image to Storage
      setProgress(0);
      const uploadTask = storage
        .ref(
          `/${campaign.accountId}/${campaign.id}/form_images/${
            filename + "." + format
          }`
        )
        .put(image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress(snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (err) => {
          setUploadError(true);
        },
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            field["image_url"] = url;

            setProgress(0);
          });
        }
      );
    }
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field));

      dispatch(setShowEditModal(false));
    } else {
      dispatch(addNewFormField(field, previousFieldId));

      closeModal();
    }
    setSaving(false);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId={fieldId}>
            <Form.Label>Choose image to upload</Form.Label>
            <Form.Control type="file" onChange={handleUploadImage} />

            <Form.Text className="text-muted">
              Only valid formats are jpg, jpeg, png
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          {settings?.image_url ? (
            <img alt="" style={{width: "100%"}} src={settings.image_url} />
          ) : (
            <DummyImage />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Image size</Form.Label>
            <input
              type="range"
              className="form-range w-100 mt-3 mb-3"
              min="0"
              max="100"
              step="5"
              value={settings?.width}
              onChange={(e) =>
                setSettings({...settings, width: e.target.value})
              }
            />
          </Form.Group>
          <Form.Label>Position</Form.Label>
          <Form.Group className="mb-3" controlId={fieldId}>
            <ButtonGroup aria-label="Basic example">
              <Button
                onClick={() => setSettings({...settings, align: "start"})}
                variant="outline-secondary"
                active={settings?.align === "start" ? true : false}
              >
                <FaAlignLeft className="icon-color" />
              </Button>
              <Button
                onClick={() => setSettings({...settings, align: "center"})}
                variant="outline-secondary"
                active={settings?.align === "center" ? true : false}
              >
                <FaAlignCenter className="icon-color" />
              </Button>
              <Button
                onClick={() => setSettings({...settings, align: "end"})}
                variant="outline-secondary"
                active={settings?.align === "end" ? true : false}
              >
                <FaAlignRight className="icon-color" />
              </Button>
            </ButtonGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="cols">
            <Form.Label>Margin</Form.Label>
            <br />
            <Form.Control
              type="number"
              min={0}
              max={100}
              value={settings?.margin}
              name="margin"
              onChange={(e) =>
                setSettings({...settings, margin: e.target.value})
              }
            />
            <Form.Text muted>Margin</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="rows">
            <Form.Label>Padding</Form.Label>
            <br />
            <Form.Control
              type="number"
              min={0}
              max={100}
              value={settings?.padding}
              name="padding"
              onChange={(e) =>
                setSettings({...settings, padding: e.target.value})
              }
            />
            <Form.Text muted>Padding</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        {progress > 0 ? (
          <ProgressBar
            className="mt-3 mb-3"
            animated={!uploadError}
            variant={uploadError ? "danger" : "primary"}
            now={uploadError ? 100 : progress * 100}
            label={uploadError ? "Error" : `${Math.round(progress * 100)}%`}
          />
        ) : null}
      </Form.Group>
      <SaveField saveFunction={handleSubmit} />
    </>
  );
}
