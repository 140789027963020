import React, {useState} from "react";

// 3rd-party
import {InputGroup, Form} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import {Link} from "react-router-dom";

// Firebase
import firebase from "firebase/app";
import {database} from "../../../../../../firebase";
import {
  ACCOUNT_PLANS,
  MAIN_ROUTES,
  USER_ROLES,
} from "../../../../../../constants/routes";
// Redux
import {useDispatch, useSelector} from "react-redux";

import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";
import {updateAsset} from "../../../../../../redux/asset/asset.actions";
import {updateAccounts} from "../../../../../../redux/account/account.actions";

import {
  selectAccountId,
  selectAccounts,
} from "../../../../../../redux/account/account.selectors";
import {ModalTabContainer} from "../../styles";
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent";
import {selectCurrentUser} from "../../../../../../redux/user/user.selectors";
import Tag from "../../../../../commons/Tag";
import AssetObjectLabels from "./AssetObjectLabels";
import AssetObjectAnnotateImage from "./AssetObjectAnnotateImage";
import AssetMap from "./AssetMap/AssetMap";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";
import Text from "../../../../../commons/Text";

const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
};
const AssetTagsRedux = () => {
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState("");
  const account = useSelector(selectAccounts);
  const accountId = useSelector(selectAccountId);
  const asset = useSelector(selectActiveAsset);
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  const handleNewTag = (str) => {
    // Single asset update
    setLoading(true);

    let tags;
    if (asset.tags) {
      tags = asset.tags;
    } else {
      tags = {tags: {}};
    }
    let accountTags = [];
    str
      .toLowerCase()
      .replace(/#+/g, "")
      .split(" ")
      .forEach((tag) => {
        if (tag === "") {
          return;
        }

        tags[tag] = true;
        accountTags.push(tag);
      });

    const recentTags = account?.recent_tags
      ? [
          ...account?.recent_tags.filter((tag) => !accountTags.includes(tag)),
          ...accountTags,
        ].slice(-20)
      : [...accountTags].slice(-20);

    database.assets
      .doc(asset.id)
      .set({tags}, {merge: true})
      .then(() => {
        // update firebase and redux
        database.accounts
          .doc(accountId)
          .update({
            recent_tags: recentTags,
            tags: firebase.firestore.FieldValue.arrayUnion(...accountTags),
          })
          .then(() => {
            // Update redux
            dispatch(
              updateAccounts({
                recent_tags: recentTags,
                tags: account?.tags
                  ? [...account?.tags, ...accountTags]
                  : accountTags,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
          });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    dispatch(updateAsset({...asset, tags}));

    setLoading(false);
  };

  const addTagToAsset = (str) => {
    setLoading(true);

    let tags = asset.tags ? asset.tags : {};

    str.split(" ").forEach((tag) => {
      if (tag === "") {
        return;
      }
      let lowerCasedTag = tag?.toLowerCase();
      tags[lowerCasedTag] = true;
    });

    let tagsArray = Object.keys(tags);
    console.log(tagsArray);

    const recentTags = account?.recent_tags
      ? [
          ...account?.recent_tags.filter((tag) => !tagsArray.includes(tag)),
          ...tagsArray,
        ].slice(-20)
      : [...tagsArray].slice(-20);

    database.assets
      .doc(asset.id)
      .set({tags}, {merge: true})
      .then(() => {
        database.accounts
          .doc(accountId)
          .update({
            recent_tags: recentTags,
          })
          .then(() => {
            // Update redux
            dispatch(
              updateAccounts({
                recent_tags: recentTags,
              })
            );
            dispatch(updateAsset({...asset, tags}));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeleteTag = (tagToDelete) => {
    setLoading(true);

    database.assets
      .doc(asset.id)
      .set(
        {
          tags: {
            [tagToDelete]: firebase.firestore.FieldValue.delete(),
          },
        },
        {merge: true}
      )
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    const {[tagToDelete]: value, ...tags} = asset.tags;
    dispatch(updateAsset({...asset, tags}));
    setLoading(false);
  };
  return (
    <>
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
        userRoles={Object.keys(USER_ROLES)}
      >
        <InputGroup className="mb-3">
          <CreatableSelect
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "var(--reeler-o-25)",
                primary: "var(--reeler-light)",
              },
            })}
            placeholder="Add a new label..."
            isClearable
            createOptionPosition="first"
            formatCreateLabel={(tag) =>
              `Add and save "${tag}" as a standard account label`
            }
            value={tag}
            onChange={(selectedOption, action) => {
              if (action.action === "clear") {
                setTag("");
              } else if (action.action === "create-option") {
                handleNewTag(action.option.value?.trim().toLowerCase());
              } else {
                addTagToAsset(selectedOption.value?.trim().toLowerCase());
              }
            }}
            options={account?.tags
              ?.sort((a, b) => {
                if (a.toLowerCase() < b.toLowerCase()) {
                  return -1;
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              ?.map((t) => ({value: t, label: t}))}
          />
        </InputGroup>
      </AuthBasedComponent>
      <div>
        {asset.tags &&
          Object.keys(asset.tags).map((tag, index) => (
            <Tag
              key={index}
              onClick={() => handleDeleteTag(tag)}
              disabled={currentUser?.role === USER_ROLES.viewer}
              showRemoveIcon={!(currentUser?.role === USER_ROLES.viewer)}
              highlighted={true}
            >
              {tag}
            </Tag>
          ))}
      </div>

      {/* {[
        "sYz63zyL7iFO7o4K5wOi",
        "Vr2OmjtCKTGWKnnvR03N",
        "qeYfKbyJda943dOxNTUe",
      ].includes(asset.accountId) ? (
        <AuthBasedComponent
          plans={[ACCOUNT_PLANS.premium]}
          userRoles={Object.keys(USER_ROLES)}
        >
          {asset?.landmark ? (
            <div className="mt-3">
              <h5>Landmark</h5>
              <Form.Text muted>
                Landmark has been automatically detected in the photo by Reeler
                AI to help categorize assets.
              </Form.Text>
              <div className="d-flex flex-row align-items-center">
                <Tag showRemoveIcon={false}>{asset?.landmark?.description}</Tag>
                {asset?.landmark?.address_components ? (
                  <small className="ms-2">
                    {` (${asset?.landmark?.address_components?.locality}, ${asset?.landmark?.address_components?.country})`}
                  </small>
                ) : null}
                {asset.landmark?.latlng && (
                  <div className="mx-2">
                    <AssetMap
                      location={{
                        description: asset?.landmark?.description,
                        lat: asset.landmark?.latlng?.latitude,
                        lng: asset.landmark?.latlng?.longitude,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null} 

          <AssetObjectLabels />
        </AuthBasedComponent>
      ) : null}*/}
    </>
  );
};

export default AssetTagsRedux;
