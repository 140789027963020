import React from "react";
import {Col, Row} from "react-bootstrap";

import ReelerButton from "../../../commons/ReelerButton";
import CloseButton from "../../../commons/CloseButton";
import {useDispatch, useSelector} from "react-redux";
import AuthBasedComponent from "../../../authentication/AuthBasedComponent";
import {
  toggleShowFilter,
  clearFilter,
  setShowSaveFilter,
  clearViewerFilterParameters,
  clearActiveAccountFilter,
  setIsSearching,
} from "../../../../redux/filter/filter.actions";
import {BulkEditReset} from "../../../../redux/bulk-edit/bulkEdit.actions";
import {clearCampaign} from "../../../../redux/campaign/campaign.actions";
import {setFeed, setFeedType} from "../../../../redux/feed/feed.actions";
import {
  selectActiveFilter,
  selectFilterParameters,
  selectIsSearching,
  selectViewerFilterParameters,
} from "../../../../redux/filter/filter.selectors";

import FilterParameters from "./FilterParameters";
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip";
import {FaPlus} from "react-icons/fa";
import {MdFilterAltOff} from "react-icons/md";
import {ACCOUNT_PLANS, USER_ROLES} from "../../../../constants/routes";
import ViewerFilterParameters from "./ViewerFilterParameters";

const Filter = () => {
  //FROM MEDIAFILTER
  const dispatch = useDispatch();
  const filter = useSelector(selectFilterParameters);
  const viewerFilterParameters = useSelector(selectViewerFilterParameters);
  const isSearching = useSelector(selectIsSearching);
  const handleClearFilter = () => {
    dispatch(clearFilter());
    dispatch(clearCampaign());
    dispatch(setFeed(null));
    dispatch(setFeedType(null));
    dispatch(BulkEditReset());
  };

  const handleCreateNewFilter = () => {
    dispatch(clearActiveAccountFilter());
    dispatch(setShowSaveFilter(true));
  };

  const handleCloseFilter = () => {
    dispatch(toggleShowFilter());
  };

  const ClearViewerFilterParameters = () => {
    const handleClearViewerFilter = () => {
      dispatch(clearViewerFilterParameters());
    };
    return (
      <ReelerButton
        text="Clear"
        dispatch={handleClearViewerFilter}
        styleClass="btn-secondary block"
        spinnerClass="ms-1"
        spinnerColor="icon-color"
      />
    );
  };

  return (
    <div className="position-relative" style={{height: "100vh"}}>
      <div className="position-relative">
        <div style={{position: "absolute", top: "-10px", right: "-10px"}}>
          <ReelerTooltip text="Hide filter">
            <span onClick={() => handleCloseFilter()}>
              <CloseButton />
            </span>
          </ReelerTooltip>
        </div>

        <div className="d-flex flex-row align-items-center pb-2">
          <h2 className="sidebar-title m-0">Filter</h2>
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <div className="d-flex flex-row align-items-center ms-3">
              <div className="me-2">
                <ReelerTooltip text="Save new filter based on active filter settings">
                  <FaPlus
                    className="icon-color icon-btn"
                    onClick={() => handleCreateNewFilter()}
                  />
                </ReelerTooltip>
              </div>
              <ReelerTooltip text="Clear filter">
                <MdFilterAltOff
                  className="icon-color icon-btn"
                  onClick={() => handleClearFilter()}
                />
              </ReelerTooltip>
            </div>
          </AuthBasedComponent>
        </div>
      </div>
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <div style={{height: "calc(100% - 100px)", overflowY: "auto"}}>
          <FilterParameters />
        </div>
        <div style={{position: "absolute", bottom: "0", width: "100%"}}>
          <Row className="">
            <Col className="">
              <ReelerButton
                text="Clear"
                disabled={isSearching}
                dispatch={handleClearFilter}
                styleClass="btn-secondary block"
                spinnerClass="ms-1"
                spinnerColor="icon-color"
              />
            </Col>
            <Col>
              <ReelerTooltip text="Apply filter">
                <ReelerButton
                  text="Show results"
                  disabled={isSearching || !filter}
                  loading={isSearching}
                  dispatch={() => dispatch(setIsSearching(true))}
                  styleClass="btn-main block"
                  spinnerClass="ms-1"
                  spinnerColor="icon-color"
                />
              </ReelerTooltip>
            </Col>
          </Row>
        </div>
      </AuthBasedComponent>

      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
        userRoles={[USER_ROLES.viewer]}
      >
        <div style={{height: "calc(100% - 100px)", overflowY: "auto"}}>
          <ViewerFilterParameters />
        </div>
        <div style={{position: "absolute", bottom: "0", width: "100%"}}>
          <Row>
            <Col>
              <ClearViewerFilterParameters />
            </Col>
            <Col>
              <ReelerTooltip text="Apply filter">
                <ReelerButton
                  text="Show results"
                  disabled={isSearching || !viewerFilterParameters}
                  loading={isSearching}
                  dispatch={() => dispatch(setIsSearching(true))}
                  styleClass="btn-main block"
                  spinnerClass="ms-1"
                  spinnerColor="icon-color"
                />
              </ReelerTooltip>
            </Col>
          </Row>
        </div>
      </AuthBasedComponent>
      {/* SUBMIT SECTION */}
    </div>
  );
};

export default Filter;
