import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors";
import {database} from "../../../../firebase";
import SpinnerComponent from "../../../commons/SpinnerComponent";
import formatCreatorName from "../../../../utils/formatCreatorName";
import {selectFilterParameters} from "../../../../redux/filter/filter.selectors";
import {
  clearFilterParameters,
  setFilterParameters,
} from "../../../../redux/filter/filter.actions";
import {Form} from "react-bootstrap";
import {BulkEditReset} from "../../../../redux/bulk-edit/bulkEdit.actions";
import Tag from "../../../commons/Tag";
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip";

export default function FilterCreators() {
  const dispatch = useDispatch();

  const accountId = useSelector(selectAccountId);
  const filter = useSelector(selectFilterParameters);
  const [isLoadingCreators, setIsLoadingCreators] = useState(false);
  const [creators, setCreators] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setIsLoadingCreators(true);

    if (accountId) {
      database.creators
        .where("accounts", "array-contains", accountId)
        .onSnapshot((querySnapshot) => {
          const creators = [];
          querySnapshot.forEach((doc) => {
            const data = database.formatDoc(doc);
            creators.push(data);
          });
          setCreators(creators);
          setIsLoadingCreators(false);
        });
    }
  }, [accountId]);

  const handleFilterCreators = (creatorId) => {
    console.log(creatorId);

    if (filter?.creators) {
      if (filter?.creators.length < 10) {
        dispatch(
          setFilterParameters({
            creators: [...filter?.creators, creatorId],
          })
        );
      }
    } else {
      dispatch(
        setFilterParameters({
          creators: [creatorId],
        })
      );
    }
    setSelectedOption(null);
    dispatch(BulkEditReset());
  };

  const handleRemoveCreator = (creatorId) => {
    const newCreators = filter?.creators.filter((c) => c !== creatorId);

    if (newCreators.length === 0) {
      delete filter.creators;

      const isEmpty = Object.keys(filter)?.length === 0;

      if (isEmpty) {
        dispatch(clearFilterParameters());
      } else {
        dispatch(setFilterParameters(filter));
      }
    } else {
      dispatch(setFilterParameters({creators: newCreators}));
    }
  };

  if (isLoadingCreators) {
    return <SpinnerComponent size="sm" />;
  }

  return (
    <Form.Group className="mb-1">
      <Form.Label className="mb-0">
        Creators{" "}
        <span
          style={{
            fontSize: ".875em",
            fontWeight: "normal",
            color: "var(--bs-secondary-color)",
          }}
        >
          (max 10)
        </span>
      </Form.Label>
      <ReelerTooltip
        text={
          filter?.rating?.length > 0 ||
          filter?.aiScore ||
          filter?.campaigns?.length > 0
            ? "You cannot filter for creators, source, ai score and star rating at the same time"
            : `Filter for creators (max 10)`
        }
      >
        <Select
          value={selectedOption}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "var(--reeler-o-25)",
              primary: "var(--reeler-light)",
            },
          })}
          placeholder="Search creators"
          onChange={(selectedOption, action) => {
            handleFilterCreators(selectedOption.value);
          }}
          options={creators?.map((c) => ({
            value: c.id,
            label: formatCreatorName(c),
          }))}
          disabled={
            filter?.campaigns?.length > 0 ||
            filter?.aiScore ||
            filter?.rating?.length > 0
          }
        />
      </ReelerTooltip>
      <div className="d-flex flex-wrap mt-1">
        {creators
          ?.filter((c) => filter?.creators?.includes(c.id))
          .map((c, index) => (
            <Tag
              key={index}
              disabled={isLoadingCreators}
              highlighted={true}
              onClick={() => handleRemoveCreator(c.id)}
            >
              {formatCreatorName(c)}
            </Tag>
          ))}
      </div>
    </Form.Group>
  );
}
