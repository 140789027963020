import styled, {css} from "styled-components";

export const AssetPreviewContainer = styled.div`
  user-select: none;
  position: relative;
  cursor: pointer;
  border: ${({selected}) =>
    selected
      ? "3px solid var(--reeler) !important"
      : "1px solid #dee2e6 !important"};
  /* box-shadow: ${({selected}) =>
    selected ? "0 0 0 0.2rem var(--reeler-o-25) !important;" : "none"}; */

  background: var(--reeler-white);
`;
export const AssetMediaPreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  max-height: 200px;
  padding: 0.5rem;
  margin-bottom: -0.5rem;
`;

export const AssetMediaTypeVideoPlayIcon = styled.div`
  position: absolute;
  color: white;
  &:hover {
    color: var(--reeler-grey-darker);
  }
`;

export const AssetPreviewFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 0.5rem;
`;
export const AssetApproveRejectContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  background-color: var(--reeler-grey-middle);
`;
export const CloseIconAssetInfoModal = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;

  cursor: pointer;
`;

export const AssetFooterIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  border-radius: ${({borderRadius}) => borderRadius};
  background-color: ${({bgColor}) => bgColor};
  &:hover {
    background-color: ${({bgHover}) => bgHover};
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const TopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const SquareContainer = styled.div`
  width: 14px;
  aspect-ratio: 1/1;
  background-color: transparent;
  /* display: ${({bulkEditClicked}) => (bulkEditClicked ? "block" : "none")}; */
  z-index: 2;
  cursor: default;
  position: relative;
  border-radius: 2px;
  padding: 14px;

  svg {
    position: absolute;
  }
`;

export const Square = styled.div`
  width: 14px;
  aspect-ratio: 1;
  background-color: #ffff;
  /* display: ${({bulkEditClicked}) => (bulkEditClicked ? "block" : "none")}; */
  cursor: default;
  z-index: 99;
  position: absolute;
  border-radius: 2px;
  border: 1px solid black;
  top: 8px;
  left: 8px;
`;

// MODAL SIDEBAR
export const SidebarNavContainer = styled.aside`
  height: 100%;
  background-color: var(--reeler-grey);
  padding: 1rem 0.5rem;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0;
  list-style: none;
  height: 100%;
`;

export const ListItem = styled.li`
  background-color: ${(props) =>
    props.active ? "var(--reeler-title)" : "var(--reeler-grey-darker)"};
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in;
  position: relative;
  margin: 0.25rem 0;
  &:hover {
    background-color: var(--reeler-link);
    background-color: ${(props) => props.active && "var(--reeler-title)"};
  }
`;

export const Notification = styled.div`
  aspect-ratio: 1/1;

  width: ${(props) => (props.$width ? `${props.$width}px` : "20px")};
  height: ${(props) => (props.$height ? `${props.$height}px` : "20px")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: var(--reeler-light);
  color: var(--reeler-icon);
  border-radius: 50%;
  font-size: 10px;

  position: absolute;
  top: ${(props) => (props.$top ? `${props.$top}px` : "-6px")};
  right: ${(props) => (props.$right ? `${props.$right}px` : "-6px")};
`;

// Asset info modal layout
export const ModalRow = styled.div`
  display: flex;
`;
export const ModalCol = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MediaCol = styled.div`
  display: flex;
`;

// Modal tabs
export const ModalTabContainer = styled.div`
  color: var(--reeler-title);

  display: flex;
  flex-direction: column;
  ${(props) => props.spaceBetween && "justify-content: space-between;"}
  margin-top: 0;
  max-height: 650px;
`;

export const ModalIconBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  box-shadow: var(--reeler-shadow);
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 0.75rem;
`;

// Versions tab
export const MainVersionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--reeler-title);
  margin-bottom: 1rem;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  height: 1.3rem;
  margin-bottom: 0.25rem;
  /* flex: 3; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Buttons = styled.div`
  color: var(--reeler-icon);
  font-size: 1rem;
  height: 1.3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const VersionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 200px;
`;

export const MediaThumbnailContainer = styled.div`
  height: ${(props) => (props.$heightPx ? `${props.$heightPx}px ` : null)};
  width: ${(props) =>
    props.$widthPx ? `${props.$widthPx}px !important;` : null};
  cursor: pointer;
`;

export const ContentDataList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VersionTitle = styled.h3`
  flex: 2;
  width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

export const OtherVersionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--reeler-grey);
  padding: 0.5rem 1rem 1rem 1rem;
  border-radius: 3px;
  max-height: 370px;
  width: 100%;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ListItemVersions = styled.span`
  font-size: 10px;
`;

export const Scrollable = styled.div`
  overflow: auto;
`;
export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  height: fit-content;
  justify-content: end;
`;
export const OtherVersionsItem = styled.div`
  display: flex;
  height: ${(props) => (props.$heightPx ? `${props.$heightPx}px` : null)};

  img {
    &:hover {
      filter: brightness(110%);
    }
  }
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;

  /* background-color: ${(props) =>
    props.$active && "var(--reeler-grey-middle)"}; */
  background-color: ${(props) => props.$active && "var(--reeler-light-o-25)"};
  border: ${(props) =>
    props.$active ? "1px solid var(--reeler-light)" : null};
`;

export const EditBtn = styled.button`
  text-decoration: underline;
  padding: 0;
  border: none;
  color: var(--reeler-title);

  &:hover {
    color: var(--reeler);
  }
`;

// Edit/Upload
export const FileInfo = styled.div`
  background-color: var(--reeler-grey);
  padding: 1rem;
  border-radius: 3px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        font-weight: 600;
      }
    }
  }
`;

export const ErrMsg = styled.p`
  color: var(--reeler-danger);
`;

export const ModalTabHeading = styled.h1`
  font-size: 1.25rem;
  color: var(--reeler-title);
  padding-left: 1rem;
`;

export const ModalTabHeading2 = styled(ModalTabHeading)`
  font-size: 1rem;
`;
export const FieldContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.$direction ? props.$direction : "column"};
  justify-content: ${(props) => (props.$justify ? props.$justify : "normal")};
  margin-bottom: 5px;
  padding: 0 0.25rem;
`;
export const GreyContainer = styled.div`
  background: var(--reeler-grey);
  padding: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;
export const FieldTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-right: 0.5rem;
`;
export const FieldValue = styled.span`
  font-size: 12px;
  ${({showMore}) => (!showMore ? truncatedCaption : null)};
  overflow-wrap: break-word;
  max-width: 600px;
`;
const truncatedCaption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const ShowMoreButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 5px;
`;
export const ShowMoreButton = styled.div`
  font-size: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: var(--reeler-link);
  }
`;
