import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {InputGroup, Form} from "react-bootstrap";

import {FaEdit, FaPen} from "react-icons/fa";
import {selectCampaign} from "../../../../../../../redux/campaign/campaign.selectors";
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip";
import * as S from "./CampaignName-styles";
import {database} from "../../../../../../../firebase";
import {saveCampaign} from "../../../../../../../redux/campaign/campaign.actions";
import ReelerButton from "../../../../../../commons/ReelerButton";
import {flushSync} from "react-dom";

export default function CampaignName() {
  const campaign = useSelector(selectCampaign);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);
  const [name, setName] = useState();
  const [edit, setEdit] = useState(false);
  const containerRef = useRef();
  const inputRef = useRef();
  const buttonRef = useRef();

  const handleSaveCampaignName = () => {
    database.campaigns
      .doc(campaign.id)
      .update({campaignName: name})
      .then(() => {
        dispatch(
          saveCampaign({
            ...campaign,
            campaignName: name,
          })
        );
      })
      .then(() => {
        containerRef.current.blur();
        inputRef.current.blur();
        setEdit(false);
      });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    setName(campaign?.campaignName);
  }, [campaign]);

  return edit ? (
    <S.Container ref={containerRef}>
      <S.Input
        ref={inputRef}
        defaultValue={name}
        width="300px"
        onChange={handleNameChange}
        onKeyUp={(e) =>
          e.key === "Enter" ? handleSaveCampaignName(e.target.value) : null
        }
      />

      <ReelerButton text="Save" dispatch={() => handleSaveCampaignName()} />
    </S.Container>
  ) : (
    <ReelerTooltip text="Edit source name" placement="bottom">
      <button
        type="button"
        ref={buttonRef}
        onClick={() => {
          flushSync(() => {
            setEdit(true);
          });
          inputRef.current?.select();
        }}
        style={{
          border: "none",
          background: "none",
          outline: "none",
          color: "var(--reeler-black)",
          fontSize: "1.2rem",
          fontWeight: "500",
        }}
      >
        <div className="d-flex flex-row items-center">
          {name ? (
            <span>{name}</span>
          ) : (
            <span className="text-slate-400 italic">Edit</span>
          )}
          <span className="d-flex align-items-center p-1 ms-2">
            <FaPen className="icon-color" />
          </span>
        </div>
      </button>
    </ReelerTooltip>
  );
}
