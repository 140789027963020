import React, {useState, useCallback, useRef} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";

import {useSelector, useDispatch} from "react-redux";
import {selectAccounts} from "../../../../../redux/account/account.selectors";
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent";
import {
  setCreatorTextSearch,
  setIsSearching,
} from "../../../../../redux/creators/creators.actions";
import {FaCross, FaSearch, FaTimes} from "react-icons/fa";
import {selectIsSearching} from "../../../../../redux/creators/creators.selectors";
import Icon from "../../../../../components/commons/Icon";

export default function CreatorsTextSearch() {
  const account = useSelector(selectAccounts);
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const [searchString, setSearchString] = useState("");
  const isSearching = useSelector(selectIsSearching);

  function handleSearch() {
    const searchString = searchInputRef.current.value;
    setSearchString(searchString);
    dispatch(setCreatorTextSearch(searchString));
  }

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  if (!account) return <SpinnerComponent />;

  const handleClearSearch = () => {
    setSearchString("");
    searchInputRef.current.value = "";
    dispatch(setCreatorTextSearch(""));
  };

  return (
    <InputGroup>
      <Form.Control
        ref={searchInputRef}
        size="sm"
        placeholder="Search name, email, phone or Instagram username"
        onBlur={() => handleSearch}
        onKeyUp={handleKeyUp}
        disabled={isSearching}
      />
      {searchString && (
        <Icon tooltip={"Clear search"} onClick={handleClearSearch}>
          <FaTimes />
        </Icon>
      )}
      <Button
        className="btn-secondary"
        size="sm"
        onClick={() => handleSearch()}
      >
        {isSearching ? <SpinnerComponent /> : <FaSearch />}
      </Button>
    </InputGroup>
  );
}
