import React, {useEffect, useRef, useState} from "react";

import firebase from "firebase/app";

// 3rd-party libraries
import parse from "html-react-parser";

// 3rd-party components
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  FaAlignCenter,
  FaAlignLeft,
  FaAlignRight,
  FaDesktop,
  FaEye,
  FaEyeSlash,
  FaLink,
  FaMobileAlt,
  FaTrash,
  FaTrashAlt,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";

// Redux
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {database, storage, functions} from "../../../firebase";
import {selectAccountId} from "../../../redux/account/account.selectors";
import {selectCampaign} from "../../../redux/campaign/campaign.selectors";

// Reeler components
import IconLink from "../../commons/IconLink/IconLink";
import ColorPicker from "../../commons/ColorPicker";
import NoSuchDocument from "../../commons/NoSuchDocument";
import SpinnerComponent from "../../commons/SpinnerComponent";
import ReelerButton from "../../commons/ReelerButton";
import ReelerIconButton from "../../commons/reelerIconButton/ReelerIconButton";
import FadeAlert from "../../commons/FadeAlert/FadeAlert";
import CampaignTags from "./components/CampaignTags";
import {MAIN_ROUTES} from "../../../constants/routes";

const HideField = ({state, dispatch}) => {
  return state && state.visible ? (
    <FaEye
      className="icon-btn"
      onClick={() =>
        dispatch({
          ...state,
          visible: false,
        })
      }
    />
  ) : (
    <FaEyeSlash
      className="icon-btn icon-color"
      onClick={() =>
        dispatch({
          ...state,
          visible: true,
        })
      }
    />
  );
};
const RequiredField = ({state, dispatch}) => {
  return (
    state && (
      <Form.Check
        disabled={!state.visible}
        checked={state.required}
        className="small"
        type="checkbox"
        label="Required?"
        onClick={(e) =>
          dispatch({
            ...state,
            required: e.target.checked,
          })
        }
      />
    )
  );
};

const FieldSettings = ({
  controlId,
  label,
  type = "text",
  text,
  requiredField,
  state,
  dispatch,
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Row>
        <Col sm={4}>
          <div className="d-flex flex-row justify-content-between">
            <Form.Label>{label}</Form.Label>
            <HideField state={state} dispatch={dispatch} />
          </div>
        </Col>
        <Col sm={8}>
          {type === "textarea" ? (
            <Form.Control
              as="textarea"
              rows={3}
              disabled={state && !state.visible}
              value={state && state.placeholder}
              onChange={(e) =>
                dispatch({
                  ...state,
                  placeholder: e.target.value,
                })
              }
            />
          ) : (
            <Form.Control
              type="text"
              disabled={state && !state.visible}
              value={state && state.placeholder}
              onChange={(e) =>
                dispatch({
                  ...state,
                  placeholder: e.target.value,
                })
              }
            />
          )}

          {text ? <Form.Text muted>{text}</Form.Text> : null}

          {requiredField ? (
            <RequiredField state={state} dispatch={dispatch} />
          ) : null}
        </Col>
      </Row>
    </Form.Group>
  );
};

const EditCampaignView = () => {
  const accountId = useSelector(selectAccountId);
  const campaign = useSelector(selectCampaign);
  const [campaignId, setCampaignId] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const titleRef = useRef();
  const nameRef = useRef();
  const textRef = useRef();
  const contentTypeTextRef = useRef();
  const contentTypeTagRef = useRef();
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [embed, setEmbed] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState("");
  const [campaignText, setCampaignText] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoWidth, setLogoWidth] = useState(50);
  const [logoAlign, setLogoAlign] = useState("center");
  const [logoPreview, setLogoPreview] = useState(null);
  const [splash, setSplash] = useState(null);
  const [splashPreview, setSplashPreview] = useState(null);
  const [progressLogo, setProgressLogo] = useState(0);
  const [progressSplash, setProgressSplash] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [formBackground, setFormbackground] = useState("#fff");
  const [fontColor, setFontColor] = useState("#212529");
  const [btnColor, setBtnColor] = useState("#0d6efd");
  const [btnFontColor, setBtnFontColor] = useState("#fff");
  const [maxNoFilesPerSubmission, setMaxNoFilesPerSubmission] = useState(1);
  const [key, setKey] = useState("template");
  const [openContentType, setOpenContentType] = useState({
    open: false,
    index: -1,
    text: "",
    tag: "",
  });

  const [fieldFileText, setFieldFileText] = useState({
    visible: true,
    required: false,
    placeholder: "Please upload your content here",
    description: "",
  });
  const [fieldTitleOfTheWork, setFieldTitleOfTheWork] = useState({
    visible: false,
    required: false,
    placeholder: "Please fill in the title of the content",
    description: "",
  });
  const [headingContactDetails, setHeadingContactDetails] = useState({
    visible: true,
    required: false,
    placeholder: "Fill in your contact details below",
    description: "",
  });
  const [fieldDescription, setFieldDescription] = useState({
    visible: false,
    required: false,
    placeholder:
      "Please tell us more about your content. If you have used music, please include music info and a link to the music source.",
    description: "",
  });
  const [fieldContentLocation, setFieldContentLocation] = useState({
    visible: false,
    required: false,
    placeholder: "Where did you take your photo/video?",
    description: "",
  });

  const [fieldContentType, setFieldContentType] = useState({
    visible: false,
    required: false,
    placeholder: "",
    description: "",
    fields: [{text: "Choose an option", tag: ""}],
  });
  const [fieldFirstName, setFieldFirstName] = useState({
    visible: false,
    required: false,
    placeholder: "First name",
    description: "",
  });
  const [fieldLastName, setFieldLastName] = useState({
    visible: false,
    required: false,
    placeholder: "Last name",
    description: "",
  });
  const [fieldEmail, setFieldEmail] = useState({
    visible: true,
    required: true,
    placeholder: "Email",
    description: "",
  });
  const [fieldPhone, setFieldPhone] = useState({
    visible: false,
    required: false,
    placeholder: "Phone",
    description: "",
  });
  const [fieldStreet, setFieldStreet] = useState({
    visible: false,
    required: false,
    placeholder: "Street",
    description: "",
  });
  const [fieldPostalCode, setFieldPostalCode] = useState({
    visible: false,
    required: false,
    placeholder: "Postal code",
    description: "",
  });
  const [fieldCity, setFieldCity] = useState({
    visible: false,
    required: false,
    placeholder: "City",
    description: "",
  });
  const [fieldState, setFieldState] = useState({
    visible: false,
    required: false,
    placeholder: "State/Region",
    description: "",
  });
  const [fieldCountry, setFieldCountry] = useState({
    visible: false,
    required: false,
    placeholder: "Location",
    description: "",
  });
  const [fieldBirthDate, setFieldBirthDate] = useState({
    visible: false,
    required: false,
    placeholder: "Birth date",
    description: "",
  });

  const [fieldSocialMediaText, setFieldSocialMediaText] = useState({
    visible: false,
    required: false,
    placeholder:
      "Your social media accounts (to give photo/video credit when possible)",
    description: "",
  });
  const [fieldInstagram, setFieldInstagram] = useState({
    visible: false,
    required: false,
    placeholder: "Instagram username",
    description: "",
  });
  const [fieldYoutube, setFieldYoutube] = useState({
    visible: false,
    required: false,
    placeholder: "YouTube channel",
    description: "",
  });
  const [fieldTiktok, setFieldTiktok] = useState({
    visible: false,
    required: false,
    placeholder: "Tiktok profile",
    description: "",
  });
  const [fieldFacebook, setFieldFacebook] = useState({
    visible: false,
    required: false,
    placeholder: "Facebook profile",
    description: "",
  });
  const [fieldTerms, setFieldTerms] = useState({
    visible: true,
    required: true,
    placeholder:
      "I agree to the Terms & Conditions of this content source as well as the privacy policy of this website.",
    description: "",
  });
  const [fieldUsage, setFieldUsage] = useState({
    visible: false,
    required: true,
    placeholder:
      "I allow that my videos/photos are used for marketing purposes by the organizer of this content source. I understand that by uploading my content, I grant the organizer a non-exclusive, royalty free license to use my videos/photos in their marketing worldwide, in any media, without any time limit. My videos/photos may be edited and combined with other marketing materials. I will still own the copyright to my videos/photos.",
    description: "",
  });
  const [fieldThirdParty, setFieldThirdParty] = useState({
    visible: false,
    required: true,
    placeholder:
      "I certify that I’m the creator of all parts of the videos/photos submitted. I have used royalty-free music only, and filled in the music details above. I have the permission from any people appearing in my videos/photos, to grant the organizer of this content source the right to use this content for marketing purposes.",
    description: "",
  });
  const [fieldSuccessMessage, setFieldSuccessMessage] = useState({
    visible: true,
    required: false,
    placeholder: "Thank you for your contribution",
    description: "",
  });

  const handleUploadLogo = (e) => {
    const logo = e.target.files[0];
    if (logo == null) return;
    setLogo(logo);
    setLogoPreview(URL.createObjectURL(logo));
  };

  const handleUploadSplash = (e) => {
    const splash = e.target.files[0];
    if (splash == null) return;
    setSplash(splash);
    setSplashPreview(URL.createObjectURL(splash));
  };

  useEffect(() => {
    if (campaign) {
      setCampaignId(campaign.id);
    }
  }, [campaign]);

  useEffect(() => {
    if (accountId && campaignId) {
      setLoading(true);
      setError(false);

      var unsubscribe = database.campaigns
        .doc(campaignId)
        .onSnapshot((snapshot) => {
          if (
            snapshot &&
            snapshot.data() &&
            snapshot.data().accountId === accountId
          ) {
            let campaign = snapshot.data();
            setCampaignTitle(campaign.campaignTitle);
            setCampaignText(campaign.campaignText);
            setCampaignName(campaign.campaignName);

            if (campaign.logo) {
              if (campaign.logo.url) {
                setLogoPreview(campaign.logo.url);
              }
              if (campaign.logo.logoWidth)
                setLogoWidth(campaign.logo.logoWidth);
              if (campaign.logo.logoAlign)
                setLogoAlign(campaign.logo.logoAlign);
            }

            if (campaign.splash) setSplashPreview(campaign.splash);
            if (campaign.design) {
              setFormbackground(campaign.design.formBackground);
              setFontColor(campaign.design.fontColor);
              setBtnColor(campaign.design.btnColor);
              setBtnFontColor(campaign.design.btnFontColor);
            }
            if (campaign.maxNoFilesPerSubmission) {
              setMaxNoFilesPerSubmission(campaign.maxNoFilesPerSubmission);
            }

            // Sätts alltid till true
            setEmbed(campaign.embed);
            if (campaign.template) {
              setFieldFileText(campaign.template.fieldFileText);
              setHeadingContactDetails(campaign.template.headingContactDetails);
              setFieldDescription(campaign.template.fieldDescription);
              if (campaign.template.fieldContentType)
                setFieldContentType(campaign.template.fieldContentType);
              if (campaign.template.fieldContentLocation)
                setFieldContentLocation(campaign.template.fieldContentLocation);
              setFieldTitleOfTheWork(campaign.template.fieldTitleOfTheWork);
              setFieldFirstName(campaign.template.fieldFirstName);
              setFieldLastName(campaign.template.fieldLastName);
              setFieldEmail(campaign.template.fieldEmail);

              if (campaign.template.fieldPhone) {
                setFieldPhone(campaign.template.fieldPhone);
              }

              /** Address */
              if (campaign.template.fieldStreet) {
                setFieldStreet(campaign.template.fieldStreet);
              }
              if (campaign.template.fieldPostalCode) {
                setFieldPostalCode(campaign.template.fieldPostalCode);
              }
              if (campaign.template.fieldCity) {
                setFieldCity(campaign.template.fieldCity);
              }
              if (campaign.template.fieldState) {
                setFieldState(campaign.template.fieldState);
              }
              if (campaign.template.fieldCountry) {
                setFieldCountry(campaign.template.fieldCountry);
              }

              setFieldBirthDate(campaign.template.fieldBirthDate);
              if (campaign.template.fieldSocialMediaText) {
                setFieldSocialMediaText(campaign.template.fieldSocialMediaText);
              }
              if (campaign.template.fieldFacebook) {
                setFieldFacebook(campaign.template.fieldFacebook);
              } else {
                setFieldFacebook({
                  visible: false,
                  required: false,
                  placeholder: "Facebook profile",
                  description: "",
                });
              }
              if (campaign.template.fieldInstagram) {
                setFieldInstagram(campaign.template.fieldInstagram);
              } else {
                setFieldInstagram({
                  visible: false,
                  required: false,
                  placeholder: "Instagram username",
                  description: "",
                });
              }
              if (campaign.template.fieldTiktok) {
                setFieldTiktok(campaign.template.fieldTiktok);
              }

              if (campaign.template.fieldYoutube) {
                setFieldYoutube(campaign.template.fieldYoutube);
              } else {
                setFieldYoutube({
                  visible: false,
                  required: false,
                  placeholder: "Youtube channel",
                  description: "",
                });
              }
              if (campaign.template.fieldSuccessMessage)
                setFieldSuccessMessage(campaign.template.fieldSuccessMessage);

              setFieldTerms(campaign.template.fieldTerms);
              setFieldUsage(campaign.template.fieldUsage);
              if (campaign.template.fieldThirdParty)
                setFieldThirdParty(campaign.template.fieldThirdParty);
            }
          } else {
            setError(true);
          }
          setLoading(false);
        });
      return () => unsubscribe();
    }
  }, [accountId, campaignId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMsg(null);
    setSaving(true);

    if (campaignName.trim().length < 3) {
      // Change console logs to error messages

      return;
    }

    // Create an upload task for logo.
    if (logo) {
      const uploadTaskLogo = storage
        .ref(`/campaigns/${campaignId}/logo/${logo.name}`)
        .put(logo);

      uploadTaskLogo.on(
        "state_changed",
        (snapshot) => {
          setProgressLogo(snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (err) => {
          setUploadError(true);
        },
        () => {
          uploadTaskLogo.snapshot.ref.getDownloadURL().then((url) => {
            database.campaigns.doc(campaignId).update({
              "logo.url": url,
            });
            setProgressLogo(0);
          });
        }
      );
    }

    // Create an upload task for splash.
    if (splash) {
      const uploadTaskSplash = storage
        .ref(`/campaigns/${campaignId}/splash/${splash.name}`)
        .put(splash);

      uploadTaskSplash.on(
        "state_changed",
        (snapshot) => {
          setProgressSplash(snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (err) => {
          setUploadError(true);
        },
        () => {
          uploadTaskSplash.snapshot.ref.getDownloadURL().then((url) => {
            database.campaigns.doc(campaignId).update({
              splash: url,
            });
          });
        }
      );
    }

    // Update campaign
    database.campaigns
      .doc(campaignId)
      .update({
        campaignName: campaignName.trim(),
        campaignTitle: campaignTitle ? campaignTitle : "",
        campaignText: campaignText ? campaignText : "",
        embed: embed,
        maxNoFilesPerSubmission: maxNoFilesPerSubmission,
        "logo.logoWidth": logoWidth,
        "logo.logoAlign": logoAlign,
        design: {
          formBackground: formBackground,
          fontColor: fontColor,
          btnColor: btnColor,
          btnFontColor: btnFontColor,
        },
        template: {
          fieldFileText,
          fieldTitleOfTheWork,
          headingContactDetails,
          fieldDescription,
          fieldContentType,
          fieldContentLocation,
          fieldFirstName,
          fieldLastName,
          fieldEmail,
          fieldPhone,
          fieldStreet,
          fieldPostalCode,
          fieldCity,
          fieldState,
          fieldCountry,
          fieldBirthDate,
          fieldSocialMediaText,
          fieldFacebook,
          fieldTiktok,
          fieldInstagram,
          fieldYoutube,
          fieldSuccessMessage,
          fieldTerms,
          fieldUsage,
          fieldThirdParty,
        },
      })
      .then(() => {
        setMsg({
          success: true,
          lead: "Success!",
          text: "Campaign updated successfully!",
        });
        setSaving(false);
      })
      .catch((err) => {
        setMsg({
          success: false,
          lead: "Oops!",
          text: "Your changes could not be saved",
        });
        setSaving(false);
      });
  };

  const deleteCampaign = async () => {
    var handleDeleteCampaign = functions.httpsCallable("handleDeleteCampaign");

    try {
      const {data} = await handleDeleteCampaign({
        campaignId: campaignId,
      });

      return data;
    } catch (err) {}
  };

  const handleDeleteCampaign = async () => {
    setDeleting(true);

    deleteCampaign().then((data) => {
      if (data) {
        setDeleting(false);
        history.push(MAIN_ROUTES.campaigns.path);
      }
    });
  };

  const handleRemoveLogo = () => {
    database.campaigns
      .doc(campaignId)
      .update({
        logo: firebase.firestore.FieldValue.delete(),
      })
      .then(() => {
        setLogoPreview(null);
        setLogo(null);
      });
  };

  const handleRemoveSplash = () => {
    database.campaigns
      .doc(campaignId)
      .update({
        splash: firebase.firestore.FieldValue.delete(),
      })
      .then(() => {
        setSplashPreview(null);
        setSplash(null);
      });
  };

  const handleContentType = () => {
    if (openContentType.index >= 0) {
      let newArr = [...fieldContentType.fields];
      newArr[openContentType.index] = {
        text: openContentType.text,
        tag: openContentType.tag,
      };

      setFieldContentType((prevState) => ({
        ...prevState,
        fields: newArr,
      }));
    } else {
      setFieldContentType((prevState) => ({
        ...prevState,
        fields: [
          ...prevState.fields,
          {
            text: openContentType.text,
            tag: openContentType.tag,
          },
        ],
      }));
    }

    setOpenContentType({open: false, index: -1, text: "", tag: ""});
  };

  const handleRemoveContentType = (index) => {
    const fields = fieldContentType.fields.filter((item, i) => i !== index);
    setFieldContentType((prevState) => ({
      ...prevState,
      fields: fields,
    }));

    setOpenContentType({open: false, index: -1, text: "", tag: ""});
  };

  const SubmitSection = () => {
    return (
      <>
        {msg && <FadeAlert msg={msg} duration={5000} />}
        <div className="d-flex flex-row justify-content-end pt-3">
          <span
            className="d-flex flex-row align-items-center me-3 link icon-btn"
            onClick={() => setOpen(true)}
          >
            <FaTrash className="me-2" /> Delete
          </span>
          <div className="d-flex flex-row">
            <ReelerButton
              loading={saving}
              dispatch={(e) => handleSubmit(e)}
              text="Save"
              styleClass="btn-main"
            />
          </div>
          <Modal centered show={open} onHide={() => setOpen(false)}>
            <ModalBody>
              <Modal.Title>
                Are you sure you want to delete this content source?
              </Modal.Title>
              <Modal.Body>
                If you delete this content source you cannot recreate it.
              </Modal.Body>
              <Modal.Footer>
                <span className="link me-2" onClick={() => setOpen(false)}>
                  Close
                </span>
                <ReelerButton
                  loading={deleting}
                  dispatch={() => handleDeleteCampaign()}
                  text="Delete content source"
                  styleClass="btn-delete"
                />
              </Modal.Footer>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <SpinnerComponent size="lg" />
      ) : !error ? (
        <>
          <Row>
            <Col className="mb-3 hej" lg={6}>
              <Row>
                <Col>
                  <h1 className="title">Form builder</h1>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <IconLink
                    url={"/campaigns/" + campaignId}
                    text="Back"
                    icon={<FaRegArrowAltCircleLeft className="me-1" />}
                    styleClass="me-0"
                  />
                </Col>
              </Row>
              <>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                  <Tab tabClassName="link" eventKey="template" title="Form">
                    <Card style={{borderTop: "0px"}}>
                      <Card.Body>
                        {msg && <FadeAlert msg={msg} duration={5000} />}

                        <Form.Group controlId="campaign-name">
                          <Row>
                            <Col sm={4}>
                              <Form.Label className="mb-0">
                                Content source name
                              </Form.Label>
                            </Col>
                            <Col sm={8}>
                              <Form.Control
                                type="text"
                                ref={nameRef}
                                value={campaignName}
                                onChange={(e) =>
                                  setCampaignName(e.target.value)
                                }
                                required
                              />
                              <Form.Text muted>For internal use only</Form.Text>
                            </Col>
                          </Row>
                        </Form.Group>

                        <Form.Group>
                          <Row>
                            <Col sm={4}>
                              <Form.Label>Form type</Form.Label>
                            </Col>
                            <Col sm={8}>
                              <Form.Check>
                                <Form.Check.Input
                                  onChange={() => setEmbed(true)}
                                  checked={embed === true}
                                  name="form_type"
                                  type="radio"
                                  id="embedded"
                                />
                                <Form.Check.Label htmlFor="embedded">
                                  Embedded in company website
                                </Form.Check.Label>
                              </Form.Check>
                              <div className="d-flex flex-row">
                                <Form.Check>
                                  <Form.Check.Input
                                    onChange={() => setEmbed(false)}
                                    checked={embed === false}
                                    name="form_type"
                                    type="radio"
                                    id="reeler_website"
                                  />
                                  <Form.Check.Label htmlFor="reeler_website">
                                    On Reeler website
                                  </Form.Check.Label>
                                </Form.Check>
                                {!embed ? (
                                  <a
                                    className="icon-color ms-2"
                                    href={`/c/${campaignId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      fontSize: ".875rem",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <FaLink /> Content Source link
                                  </a>
                                ) : null}
                              </div>

                              {embed ? (
                                <div>
                                  <div
                                    className="mt-2 p-2"
                                    style={{
                                      width: "100%",
                                      height: "100px",
                                      background: "#fff",
                                      fontSize: "10px",
                                      border: "1px solid lightgrey",
                                      borderRadius: ".25rem",
                                    }}
                                  >
                                    &lt;iframe width=&quot;100%&quot;
                                    height=&quot;1500px&quot;
                                    scrolling=&quot;no&quot; style=&quot;border:
                                    none&quot;
                                    src=&quot;https://app.reelertech.com/iframe/v2/
                                    {campaignId}&quot; &gt;&lt;/iframe&gt;
                                  </div>
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Group>
                        {!embed ? (
                          <>
                            <h5 className="sub-title">
                              Landing Page Header Content
                            </h5>
                            <Form.Text muted className="mb-3">
                              Only if hosted on Reeler website
                            </Form.Text>
                            <Form.Group controlId="logo">
                              <Row>
                                <Col sm={4}>
                                  <Form.Label>Upload logo</Form.Label>
                                </Col>
                                <Col sm={8}>
                                  <Form.File
                                    className="position-relative"
                                    name="logo"
                                    onChange={handleUploadLogo}
                                  />
                                  <div>
                                    <Button
                                      variant="link"
                                      size="sm"
                                      className="icon-color mt-2"
                                      onClick={() => handleRemoveLogo()}
                                    >
                                      <FaTrash /> Remove logo
                                    </Button>
                                  </div>
                                  {progressLogo > 0 ? (
                                    <ProgressBar
                                      className="mt-3"
                                      animated={!uploadError}
                                      variant={
                                        uploadError ? "danger" : "primary"
                                      }
                                      now={
                                        uploadError ? 100 : progressLogo * 100
                                      }
                                      label={
                                        uploadError
                                          ? "Error"
                                          : `${Math.round(progressLogo * 100)}%`
                                      }
                                    />
                                  ) : null}
                                  <input
                                    type="range"
                                    className="form-range w-100 mt-3 mb-3"
                                    min="0"
                                    max="100"
                                    step="5"
                                    value={logoWidth}
                                    onChange={(e) =>
                                      setLogoWidth(e.target.value)
                                    }
                                  />

                                  <div className="d-flex flex-row justify-content-start mb-3">
                                    <ButtonGroup aria-label="Basic example">
                                      <Button
                                        onClick={() => setLogoAlign("start")}
                                        variant="outline-secondary"
                                      >
                                        <FaAlignLeft className="icon-color" />
                                      </Button>
                                      <Button
                                        onClick={() => setLogoAlign("center")}
                                        variant="outline-secondary"
                                      >
                                        <FaAlignCenter className="icon-color" />
                                      </Button>
                                      <Button
                                        onClick={() => setLogoAlign("end")}
                                        variant="outline-secondary"
                                      >
                                        <FaAlignRight className="icon-color" />
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </Col>
                              </Row>
                            </Form.Group>

                            <Form.Group controlId="splash">
                              <Row>
                                <Col sm={4}>
                                  <Form.Label>Upload splash image</Form.Label>
                                </Col>
                                <Col sm={8}>
                                  <Form.File
                                    className="position-relative"
                                    name="splash"
                                    onChange={handleUploadSplash}
                                  />
                                  <Form.Text muted>
                                    Horizontal aspect ratio recommended
                                  </Form.Text>
                                  {progressSplash > 0 ? (
                                    <ProgressBar
                                      className="mt-3"
                                      animated={!uploadError}
                                      variant={
                                        uploadError ? "danger" : "primary"
                                      }
                                      now={
                                        uploadError ? 100 : progressSplash * 100
                                      }
                                      label={
                                        uploadError
                                          ? "Error"
                                          : `${Math.round(
                                              progressSplash * 100
                                            )}%`
                                      }
                                    />
                                  ) : null}
                                  {splashPreview ? (
                                    <Button
                                      variant="link"
                                      size="sm"
                                      className="icon-color mt-2"
                                      onClick={() => handleRemoveSplash()}
                                    >
                                      <FaTrash /> Remove splash image
                                    </Button>
                                  ) : null}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="title">
                              <Row>
                                <Col sm={4}>
                                  <Form.Label>Landing page title</Form.Label>
                                </Col>
                                <Col sm={8}>
                                  <Form.Control
                                    type="text"
                                    ref={titleRef}
                                    value={campaignTitle}
                                    placeholder="Write a nice content source title"
                                    onChange={(e) =>
                                      setCampaignTitle(e.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group controlId="text">
                              <Row>
                                <Col sm={4}>
                                  <Form.Label>Content source text</Form.Label>
                                </Col>
                                <Col sm={8}>
                                  <Form.Control
                                    as="textarea"
                                    ref={textRef}
                                    value={campaignText}
                                    placeholder="Write a short description/brief about your content source."
                                    onChange={(e) =>
                                      setCampaignText(e.target.value)
                                    }
                                    rows={3}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </>
                        ) : null}
                        <Form.Group controlId="no_files">
                          <Row>
                            <Col sm={4}>
                              <Form.Label className="mb-0">
                                Max number of files per submission
                              </Form.Label>
                            </Col>
                            <Col sm={8}>
                              <Form.Control
                                as="select"
                                value={maxNoFilesPerSubmission}
                                onChange={(e) =>
                                  setMaxNoFilesPerSubmission(e.target.value)
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </Form.Control>
                              <Form.Text muted>
                                Choose how many files the user can upload per
                                submission.
                              </Form.Text>
                            </Col>
                          </Row>
                        </Form.Group>
                        <h5 className="sub-title">Form Fields</h5>

                        <FieldSettings
                          controlId="contentSectionTitle"
                          label="Content section title"
                          type="text"
                          requiredField={false}
                          state={fieldFileText}
                          dispatch={setFieldFileText}
                        />
                        <FieldSettings
                          controlId="contentTitleOfTheWork"
                          label="Title of content"
                          type="text"
                          requiredField={true}
                          state={fieldTitleOfTheWork}
                          dispatch={setFieldTitleOfTheWork}
                        />
                        <FieldSettings
                          controlId="contentLocation"
                          label="Content location"
                          type="textarea"
                          requiredField={true}
                          state={fieldContentLocation}
                          dispatch={setFieldContentLocation}
                        />

                        <Form.Group>
                          <Row>
                            <Col sm={4}>
                              <div className="d-flex flex-row justify-content-between">
                                <Form.Label>Content options</Form.Label>
                                <HideField
                                  state={fieldContentType}
                                  dispatch={setFieldContentType}
                                />
                              </div>
                            </Col>
                            <Col sm={8}>
                              {fieldContentType.fields.map((field, index) => (
                                <div
                                  key={index}
                                  className="d-flex flex-row p-2 mb-2 justify-content-between"
                                  style={{
                                    background: "var(--reeler-grey)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div>
                                    <Form.Text>{field.text}</Form.Text>
                                    <Form.Text>Tag: {field.tag}</Form.Text>
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <Form.Text
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setOpenContentType({
                                          open: true,
                                          index: index,
                                          text: field.text,
                                          tag: field.tag,
                                        })
                                      }
                                    >
                                      Edit
                                    </Form.Text>
                                  </div>
                                </div>
                              ))}

                              <div className="d-flex flex-row mt-1">
                                <ReelerButton
                                  text="Add option"
                                  size="sm"
                                  disabled={!fieldContentType.visible}
                                  dispatch={() =>
                                    setOpenContentType({
                                      open: true,
                                      index: -1,
                                      text: "",
                                      tag: "",
                                    })
                                  }
                                  styleClass="btn-secondary"
                                />
                              </div>
                              <Form.Text muted>
                                Create a dropdown where the end-user chooses
                                their content type. First add a headline shown
                                at the top of the dropdown list. Then add the
                                options one by one.
                              </Form.Text>
                              <Modal
                                centered
                                show={openContentType.open}
                                onHide={() =>
                                  setOpenContentType({
                                    open: false,
                                    index: -1,
                                  })
                                }
                              >
                                <ModalBody>
                                  <Modal.Title>Add dropdown option</Modal.Title>
                                  <Modal.Body>
                                    <Form.Group>
                                      <Form.Control
                                        ref={contentTypeTextRef}
                                        type="text"
                                        size="sm"
                                        placeholder=""
                                        value={openContentType.text}
                                        onChange={(e) =>
                                          setOpenContentType((prevState) => ({
                                            ...prevState,
                                            text: e.target.value,
                                          }))
                                        }
                                      />
                                      <Form.Text>
                                        Option text in dropdown.
                                      </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                      <Form.Control
                                        ref={contentTypeTagRef}
                                        type="text"
                                        size="sm"
                                        placeholder=""
                                        value={openContentType.tag}
                                        onChange={(e) =>
                                          setOpenContentType((prevState) => ({
                                            ...prevState,
                                            tag: e.target.value,
                                          }))
                                        }
                                      />
                                      <Form.Text>
                                        Optional: Tag to associate with option
                                        (for content source administration)
                                      </Form.Text>
                                    </Form.Group>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <ReelerButton
                                      text="Close"
                                      styleClass="btn-secondary"
                                      dispatch={() =>
                                        setOpenContentType({
                                          open: false,
                                          index: -1,
                                          text: "",
                                          tag: "",
                                        })
                                      }
                                    />
                                    {openContentType.index >= 0 ? (
                                      <ReelerIconButton
                                        icon={<FaTrashAlt className="me-1" />}
                                        text="Delete"
                                        styleClass="btn-delete"
                                        dispatch={() =>
                                          handleRemoveContentType(
                                            openContentType.index
                                          )
                                        }
                                      />
                                    ) : null}

                                    <ReelerButton
                                      text="Save"
                                      styleClass="btn-main"
                                      dispatch={() => handleContentType()}
                                    />
                                  </Modal.Footer>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </Form.Group>
                        <FieldSettings
                          controlId="contentDescription"
                          label="Content description"
                          type="textarea"
                          requiredField={true}
                          state={fieldDescription}
                          dispatch={setFieldDescription}
                        />

                        <h5 className="sub-title">Contact Section</h5>
                        <FieldSettings
                          controlId="contactHeading"
                          label="Contact section title"
                          type="text"
                          requiredField={false}
                          state={headingContactDetails}
                          dispatch={setHeadingContactDetails}
                        />
                        <FieldSettings
                          controlId="contactFirstName"
                          label="First name"
                          type="text"
                          requiredField={true}
                          state={fieldFirstName}
                          dispatch={setFieldFirstName}
                        />
                        <FieldSettings
                          controlId="contactLastName"
                          label="Last name"
                          type="text"
                          requiredField={true}
                          state={fieldLastName}
                          dispatch={setFieldLastName}
                        />
                        <FieldSettings
                          controlId="contactEmail"
                          label="Email"
                          type="text"
                          requiredField={true}
                          state={fieldEmail}
                          dispatch={setFieldEmail}
                        />
                        <FieldSettings
                          controlId="contactPhone"
                          label="Phone"
                          type="text"
                          requiredField={true}
                          state={fieldPhone}
                          dispatch={setFieldPhone}
                        />
                        <FieldSettings
                          controlId="contactStreet"
                          label="Street"
                          type="text"
                          requiredField={true}
                          state={fieldStreet}
                          dispatch={setFieldStreet}
                        />
                        <FieldSettings
                          controlId="contactPostalCode"
                          label="Postal code"
                          type="text"
                          requiredField={true}
                          state={fieldPostalCode}
                          dispatch={setFieldPostalCode}
                        />
                        <FieldSettings
                          controlId="contactCity"
                          label="City"
                          type="text"
                          requiredField={true}
                          state={fieldCity}
                          dispatch={setFieldCity}
                        />
                        <FieldSettings
                          controlId="contactState"
                          label="State/region"
                          type="text"
                          requiredField={true}
                          state={fieldState}
                          dispatch={setFieldState}
                        />
                        <FieldSettings
                          controlId="contactCountry"
                          label="Country"
                          type="text"
                          requiredField={true}
                          state={fieldCountry}
                          dispatch={setFieldCountry}
                        />
                        <FieldSettings
                          controlId="contactBirthDate"
                          label="Birth date"
                          type="text"
                          requiredField={true}
                          state={fieldBirthDate}
                          dispatch={setFieldBirthDate}
                        />
                        <h5 className="sub-title">Social Media Section</h5>
                        <FieldSettings
                          controlId="social-media-text"
                          label="Social media section text"
                          type="textarea"
                          requiredField={false}
                          state={fieldSocialMediaText}
                          dispatch={setFieldSocialMediaText}
                        />
                        <FieldSettings
                          controlId="instagram"
                          label="Instagram"
                          type="text"
                          requiredField={true}
                          state={fieldInstagram}
                          dispatch={setFieldInstagram}
                        />
                        <FieldSettings
                          controlId="youtube"
                          label="Youtube channel"
                          type="text"
                          requiredField={true}
                          state={fieldYoutube}
                          dispatch={setFieldYoutube}
                        />
                        <FieldSettings
                          controlId="tiktok"
                          label="Tiktok"
                          type="text"
                          requiredField={true}
                          state={fieldTiktok}
                          dispatch={setFieldTiktok}
                        />
                        <FieldSettings
                          controlId="facebook"
                          label="Facebook"
                          type="text"
                          requiredField={true}
                          state={fieldFacebook}
                          dispatch={setFieldFacebook}
                        />

                        <h5 className="sub-title">Legal</h5>
                        <FieldSettings
                          controlId="terms"
                          label="Content Source T&C"
                          type="textarea"
                          requiredField={true}
                          state={fieldTerms}
                          dispatch={setFieldTerms}
                        />
                        <Form.Group controlId="title">
                          <Row>
                            <Col sm={{span: 8, offset: 4}}>
                              <Form.Text muted className="mb-3">
                                {`Link to external page by adding <a target=“_blank” href='www.yourdomain.se'>Your text</a>`}
                              </Form.Text>
                            </Col>
                          </Row>
                        </Form.Group>

                        <FieldSettings
                          controlId="usage"
                          label="Usage rights"
                          type="textarea"
                          requiredField={true}
                          state={fieldUsage}
                          dispatch={setFieldUsage}
                        />
                        <FieldSettings
                          controlId="third_party"
                          label="3rd party rights"
                          type="textarea"
                          requiredField={true}
                          state={fieldThirdParty}
                          dispatch={setFieldThirdParty}
                        />
                        <h5 className="sub-title">Success Section</h5>
                        <FieldSettings
                          controlId="successMessage"
                          label="Success message"
                          type="text"
                          requiredField={false}
                          state={fieldSuccessMessage}
                          dispatch={setFieldSuccessMessage}
                        />
                        <SubmitSection />
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab tabClassName="link" eventKey="design" title="Design">
                    <Card style={{borderTop: "0px"}}>
                      <Card.Body>
                        <div className="d-flex flex-row mt-3 align-items-center">
                          <div className="pe-3">Background color:</div>
                          <ColorPicker
                            state={formBackground}
                            dispatch={setFormbackground}
                          />
                        </div>
                        <div className="d-flex flex-row mt-3 align-items-center">
                          <div className="pe-3">Font color:</div>
                          <ColorPicker
                            state={fontColor}
                            dispatch={setFontColor}
                          />
                        </div>
                        <div className="d-flex flex-row mt-3 align-items-center">
                          <div className="pe-3">Button color:</div>
                          <ColorPicker
                            state={btnColor}
                            dispatch={setBtnColor}
                          />
                        </div>
                        <div className="d-flex flex-row mt-3 align-items-center">
                          <div className="pe-3">Button font color:</div>
                          <ColorPicker
                            state={btnFontColor}
                            dispatch={setBtnFontColor}
                          />
                        </div>
                        <SubmitSection />
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab tabClassName="link" eventKey="tags" title="Tags">
                    <Card style={{borderTop: "0px"}}>
                      <Card.Body>
                        {campaign && <CampaignTags campaign={campaign} />}
                      </Card.Body>
                    </Card>
                  </Tab>
                </Tabs>
              </>
            </Col>
            <Col
              lg={6}
              style={{
                position: "sticky",
                alignSelf: "flex-start",
                top: "0px",
              }}
            >
              <div className="d-flex flex-row justify-content-between">
                <h1 className="title">Preview</h1>
                <div className="d-flex flex-row align-items-center">
                  <FaDesktop
                    className={
                      !mobileView
                        ? "me-2 icon-btn icon-reeler"
                        : "me-2 icon-btn"
                    }
                    onClick={() => setMobileView(false)}
                  />
                  <FaMobileAlt
                    className={
                      mobileView ? "me-2 icon-btn icon-reeler" : "me-2 icon-btn"
                    }
                    onClick={() => setMobileView(true)}
                  />
                </div>
              </div>
              <Container
                fluid
                className="ugc_landingpage_container"
                style={{
                  maxWidth: mobileView ? "300px" : null,
                  background: formBackground ? formBackground : "#fff",
                  color: fontColor ? fontColor : "#000",
                  border: "1px solid rgba(0,0,0,.125)",
                  borderRadius: ".25rem",
                }}
              >
                <div className="p-3 mb-3 d-flex justify-content-center">
                  <>
                    {!embed ? (
                      <div>
                        {logoPreview ? (
                          <div
                            className={
                              logoAlign
                                ? `d-flex justify-content-${logoAlign}`
                                : "d-flex justify-content-center"
                            }
                          >
                            <img
                              alt=""
                              className="pt-4 pb-5"
                              style={{
                                width: logoWidth ? `${logoWidth}%` : "50%",
                              }}
                              src={logoPreview}
                            />
                          </div>
                        ) : null}

                        {splashPreview ? (
                          <img
                            className="rounded"
                            style={{width: "100%"}}
                            src={splashPreview}
                            alt=""
                          />
                        ) : null}

                        <h1
                          className="mt-3 mb-3 text-center"
                          style={{fontSize: "2rem"}}
                        >
                          {campaignTitle ? campaignTitle : null}
                        </h1>
                        <p className="mt-5">
                          {campaignText ? parse(campaignText) : null}
                        </p>

                        <hr />
                      </div>
                    ) : null}

                    {fieldFileText && fieldFileText.visible ? (
                      <h5 className="mt-4 mb-3">
                        {fieldFileText.placeholder
                          ? fieldFileText.placeholder
                          : null}
                      </h5>
                    ) : null}

                    <Form.Group>
                      <Form.File
                        size="sm"
                        className="position-relative"
                        name="file"
                        disabled
                        feedbackTooltip
                      />
                    </Form.Group>
                    {fieldTitleOfTheWork && fieldTitleOfTheWork.visible ? (
                      <Form.Group>
                        <Form.Control
                          size="sm"
                          placeholder={
                            fieldTitleOfTheWork.placeholder
                              ? fieldTitleOfTheWork.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}
                    {fieldContentLocation && fieldContentLocation.visible ? (
                      <Form.Group>
                        <Form.Control
                          size="sm"
                          placeholder={
                            fieldContentLocation.placeholder
                              ? fieldContentLocation.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}
                    {fieldContentType && fieldContentType.visible ? (
                      <Form.Group>
                        <Form.Control as="select" size="sm">
                          {fieldContentType.fields.map((fields, index) => (
                            <option key={index}>{fields.text}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    ) : null}

                    {fieldDescription && fieldDescription.visible ? (
                      <Form.Group>
                        <Form.Control
                          size="sm"
                          as="textarea"
                          rows={3}
                          placeholder={
                            fieldDescription.placeholder
                              ? fieldDescription.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}

                    {headingContactDetails.visible && (
                      <h5 className="mt-5 mb-3">
                        {headingContactDetails.placeholder}
                      </h5>
                    )}
                    <Form.Group>
                      <Form.Row>
                        {fieldFirstName.visible ? (
                          <Col>
                            <Form.Control
                              size="sm"
                              placeholder={
                                fieldFirstName.placeholder
                                  ? fieldFirstName.placeholder
                                  : null
                              }
                            />
                          </Col>
                        ) : null}
                        {fieldLastName.visible ? (
                          <Col>
                            <Form.Control
                              size="sm"
                              placeholder={
                                fieldLastName.placeholder
                                  ? fieldLastName.placeholder
                                  : null
                              }
                            />
                          </Col>
                        ) : null}
                      </Form.Row>
                    </Form.Group>
                    {fieldEmail.visible ? (
                      <Form.Group>
                        <Form.Control
                          size="sm"
                          type="email"
                          placeholder={
                            fieldEmail.placeholder
                              ? fieldEmail.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}

                    {fieldPhone && fieldPhone.visible ? (
                      <Form.Group>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldPhone.placeholder
                              ? fieldPhone.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}
                    {fieldStreet.visible ? (
                      <Form.Group controlId="street">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldStreet.placeholder
                              ? fieldStreet.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}
                    <Row className="mb-3">
                      {fieldPostalCode.visible ? (
                        <Form.Group as={Col} controlId="postalcode">
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder={
                              fieldPostalCode.placeholder
                                ? fieldPostalCode.placeholder
                                : null
                            }
                          />
                        </Form.Group>
                      ) : null}
                      {fieldCity.visible ? (
                        <Form.Group as={Col} controlId="city">
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder={
                              fieldCity.placeholder
                                ? fieldCity.placeholder
                                : null
                            }
                          />
                        </Form.Group>
                      ) : null}
                      {fieldState.visible ? (
                        <Form.Group as={Col} controlId="state">
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder={
                              fieldState.placeholder
                                ? fieldState.placeholder
                                : null
                            }
                          />
                        </Form.Group>
                      ) : null}
                    </Row>

                    {fieldCountry.visible ? (
                      <Form.Group controlId="country">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldCountry.placeholder
                              ? fieldCountry.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}
                    {fieldBirthDate.visible ? (
                      <Form.Group controlId="dateOfBirth">
                        <Form.Label className="small text-muted">
                          {fieldBirthDate.placeholder}
                        </Form.Label>
                        <Form.Control size="sm" type="date" />
                      </Form.Group>
                    ) : null}

                    {fieldSocialMediaText && fieldSocialMediaText.visible ? (
                      <div className="mt-4 mb-3">
                        <p>
                          {fieldSocialMediaText.placeholder
                            ? fieldSocialMediaText.placeholder
                            : null}
                        </p>
                      </div>
                    ) : null}

                    {fieldInstagram.visible ? (
                      <Form.Group controlId="instagram">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldInstagram.placeholder
                              ? fieldInstagram.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}
                    {fieldYoutube.visible ? (
                      <Form.Group controlId="youtube">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldYoutube.placeholder
                              ? fieldYoutube.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}

                    {fieldTiktok.visible ? (
                      <Form.Group controlId="tiktok">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldTiktok.placeholder
                              ? fieldTiktok.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}

                    {fieldFacebook.visible ? (
                      <Form.Group controlId="facebook">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={
                            fieldFacebook.placeholder
                              ? fieldFacebook.placeholder
                              : null
                          }
                        />
                      </Form.Group>
                    ) : null}

                    <br />
                    {fieldTerms && fieldTerms.visible ? (
                      <Form.Group className="d-inline-flex">
                        <Form.Check type="checkbox" />{" "}
                        <small>{parse(fieldTerms.placeholder)}</small>
                      </Form.Group>
                    ) : null}
                    {fieldUsage && fieldUsage.visible ? (
                      <Form.Group className="d-inline-flex">
                        <Form.Check type="checkbox" />{" "}
                        <small>{parse(fieldUsage.placeholder)}</small>
                      </Form.Group>
                    ) : null}
                    {fieldThirdParty && fieldThirdParty.visible ? (
                      <Form.Group className="d-inline-flex">
                        <Form.Check type="checkbox" />{" "}
                        <small>
                          {fieldThirdParty.placeholder
                            ? parse(fieldThirdParty.placeholder)
                            : null}
                        </small>
                      </Form.Group>
                    ) : null}
                    <Form.Group>
                      <Button
                        style={{background: btnColor, color: btnFontColor}}
                      >
                        Submit
                      </Button>
                    </Form.Group>
                  </>
                </div>
              </Container>
            </Col>
          </Row>
        </>
      ) : (
        <NoSuchDocument
          message="Oops, could not find the content source"
          img="../../no_content_women_dog.svg"
          alt=""
        />
      )}
    </>
  );
};

export default EditCampaignView;
