import React from "react";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";

const SingleRowIcon = ({tooltip, children}) => (
  <div className="d-flex flex-column justify-content-center">
    <ReelerTooltip text={tooltip} placement="right">
      {children}
    </ReelerTooltip>
  </div>
);

export default SingleRowIcon;
