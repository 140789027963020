import React from "react";

import {ACCOUNT_PLANS, USER_ROLES} from "../../../../../../constants/routes";
// Redux
import {useSelector} from "react-redux";

import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";

import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent";
import Tag from "../../../../../commons/Tag";
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
};
const AssetObjectLabels = () => {
  const asset = useSelector(selectActiveAsset);
  const copyLabel = (label) => {
    navigator.clipboard.writeText(label);
  };

  return asset.objectLabels ? (
    <AuthBasedComponent
      plans={[ACCOUNT_PLANS.premium]}
      userRoles={Object.keys(USER_ROLES)}
    >
      <div className="d-flex flex-wrap">
        {Object.keys(asset.objectLabels)
          .sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((label, index) => (
            <Tag
              key={index}
              showRemoveIcon={false}
              tooltip="Copy tag"
              onClick={() => copyLabel(label)}
            >
              {label}
            </Tag>
          ))}
      </div>
    </AuthBasedComponent>
  ) : null;
};

export default AssetObjectLabels;
