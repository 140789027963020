import React, {useState, useEffect} from "react";
import firebase from "firebase/app";
import {useSelector, useDispatch} from "react-redux";
import {selectAccounts} from "../../../redux/account/account.selectors";
import {updateAccounts} from "../../../redux/account/account.actions";
import {database} from "../../../firebase";
import parse from "html-react-parser";
import {Form, Row, Col, InputGroup, FormControl} from "react-bootstrap";
import SpinnerComponent from "../../commons/SpinnerComponent";
import {FaExclamationCircle} from "react-icons/fa";
import {IoClose} from "react-icons/io5";

import axios from "axios";
import ReelerButton from "../../commons/ReelerButton";
import ReelerIconButton from "../../commons/reelerIconButton/ReelerIconButton";
import Tag from "../../commons/Tag";

export default function ManageConsentHashtags() {
  const dispatch = useDispatch();
  const account = useSelector(selectAccounts);
  const [loading, setLoading] = useState(false);
  const [instagramBusinessAccount, setInstagramBusinessAccount] =
    useState(null);
  const [query, setQuery] = useState();
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (account) {
      if (!instagramBusinessAccount) {
        if (account?.integrations?.instagram?.accounts[0]) {
          setInstagramBusinessAccount(
            account?.integrations?.instagram?.accounts[0]
              ?.ig_business_account_profile?.id
          );
        }
      }
    }
  }, [account]);

  const getIGHashtagID = async (userId, searchTerm, access_token) => {
    const {data} = await axios({
      url: `https://graph.facebook.com/v16.0/ig_hashtag_search?user_id=${userId}&q=${searchTerm}&access_token=${access_token}`,
      method: "get",
    });
    return data;
  };

  const handleAddConsentHashtag = () => {
    setLoading(true);
    // Get hashtag id for hashtag

    const long_lived_token =
      account?.integrations?.instagram?.long_lived_user_token;

    getIGHashtagID(instagramBusinessAccount, query, long_lived_token)
      .then((data) => {
        let consentId = data.data[0].id;

        const newConsentHashtag = {
          name: query,
          id: consentId,
          createdAt: new Date(),
        };

        let consentHashtags;
        if (account?.integrations?.instagram?.rightsRequests?.consentHashtags) {
          consentHashtags = [
            ...account?.integrations?.instagram?.rightsRequests
              ?.consentHashtags,
            newConsentHashtag,
          ];
        } else {
          consentHashtags = [newConsentHashtag];
        }
        database.accounts
          .doc(account.id)
          .update({
            "integrations.instagram.rightsRequests.consentHashtags":
              consentHashtags,
            "integrations.instagram.rightsRequests.instagram_business_account":
              instagramBusinessAccount,
          })
          .then(() => {
            dispatch(
              updateAccounts({
                integrations: {
                  ...account.integrations,
                  instagram: {
                    ...account.integrations.instagram,
                    rightsRequests: {
                      ...account.integrations.instagram.rightsRequests,
                      consentHashtags: consentHashtags,
                      instagram_business_account: instagramBusinessAccount,
                    },
                  },
                },
              })
            );

            setQuery(null);

            setLoading(false);
          });
      })
      .catch((err) => {
        if (err.response.data.error.error_subcode === 2207034) {
          setMsg(
            "You can query a maximum of 30 unique hashtags on behalf of your Instagram Business Account within a rolling, 7 day period. Once you query a hashtag, it will count against this limit for 7 days. Subsequent queries on the same hashtag within this time frame will not count against your limit, and will not reset its initial query 7 day timer."
          );
        } else if (err.response.data.error.error_subcode === 2207024) {
          setMsg(
            "The hashtag has to already exist on Instagram. If you want to use a new hashtag, please begin by posting the hashtag anywhere on Instagram – then please try again to register it in Reeler."
          );
        } else if (err.response.data.error.error_subcode === 463) {
          setMsg(
            "The connection to Instagram has expired and you need to refresh the Instagram connection. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>"
          );
        } else if (err.response.data.error.error_user_title != undefined) {
          setMsg(err.response.data.error.error_user_title);
        } else {
          setMsg(err.response.data.error.message);
        }

        setLoading(false);
      });
  };

  const removeConsentHashtag = (id) => {
    console.log(id);
    setLoading(true);

    let updatedConsentHashtagsArr =
      account?.integrations?.instagram?.rightsRequests?.consentHashtags.filter(
        (consentHashtag) => consentHashtag.id !== id
      );
    database.accounts
      .doc(account.id)
      .update({
        "integrations.instagram.rightsRequests.consentHashtags":
          updatedConsentHashtagsArr,
      })
      .then(() => {
        dispatch(
          updateAccounts({
            integrations: {
              ...account.integrations,
              instagram: {
                ...account.integrations.instagram,
                rightsRequests: {
                  ...account.integrations.instagram.rightsRequests,
                  consentHashtags: updatedConsentHashtagsArr,
                },
              },
            },
          })
        );

        setLoading(false);
      });
  };

  return !account ? (
    <SpinnerComponent size="sm" />
  ) : (
    <Row>
      <Col>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex align-items-center">
            <h1 className="title">Manage consent hashtags</h1>
          </div>
        </div>
        <Form.Label>Add consent hashtags</Form.Label>
        <Form.Text>
          <p>
            On Instagram, you can request content creators to grant permission
            for the use of their content by asking them to reply in the comments
            field with a "consent hashtag".{" "}
          </p>
          <p>Here's how it works: </p>

          <ol>
            <li>
              Post a question as a comment under their photo or video on
              Instagram, where you ask them to reply using the consent hashtag.
            </li>
            <li>
              When you confirm that you have posted your question, the icon on
              the content asset will change from grey to yellow.
            </li>
            <li>
              When they reply with the consent hashtag, the icon will turn
              green.
            </li>
          </ol>
          <p>
            This is where you register the consent hashtag(s) you want to use.
            Please note that the effectiveness of the consent hashtag is
            time-sensitive:
          </p>
          <ul>
            <li>
              If you or the creator are slow to ask or respond, the consent
              hashtag may not work.
            </li>
            <li>
              We can only ensure that consent hashtags work for 24 hours from
              when the content asset was originally posted on Instagram.
            </li>
          </ul>
          <p>
            If you collaborate with regular content contributors or brand
            ambassadors, consider asking them to include both the source hashtag
            (for content collection) and the consent hashtag already when they
            post the content. This way, the asset will be collected and marked
            as green from the start.
          </p>
        </Form.Text>
        <Row className="mt-4">
          <Col md="8">
            {msg ? (
              <div className="d-flex mt-3 flex-row justify-content-between">
                <div className="d-flex flex-row">
                  <div className="d-flex align-items-center p-1 icon-reeler">
                    <FaExclamationCircle
                      style={{color: "var(--reeler-danger)"}}
                    />
                  </div>
                  <div>{parse(msg)}</div>
                </div>
              </div>
            ) : null}
            <Form.Group controlId="ig_business_account">
              <Form.Control
                as="select"
                name="ig_business_account"
                value={instagramBusinessAccount}
                onChange={(e) => setInstagramBusinessAccount(e.target.value)}
              >
                {account?.integrations?.instagram?.accounts.map(
                  (account, key) => (
                    <option
                      key={key}
                      value={account.ig_business_account_profile.id}
                    >
                      {account.ig_business_account_profile.username}
                    </option>
                  )
                )}
              </Form.Control>
              <Form.Text muted>
                Choose which Instagram business account that this should be
                used.
              </Form.Text>
            </Form.Group>
            <div>
              <InputGroup
                style={{
                  borderRadius: ".25rem",
                  background: "#fff",
                  border: "1px solid lightgrey",
                }}
              >
                <InputGroup.Text
                  className="pe-0"
                  style={{border: "none", background: "none"}}
                >
                  #
                </InputGroup.Text>

                <FormControl
                  style={{border: "none"}}
                  type="text"
                  placeholder="Add a consent hashtag to track"
                  value={query ? query : ""}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <ReelerButton
                  text="Add consent hashtag"
                  id="button-addon2"
                  loading={loading}
                  styleClass="btn-main"
                  disabled={query?.length > 2 ? false : true}
                  dispatch={() => handleAddConsentHashtag()}
                />
              </InputGroup>

              <Form.Text muted>
                Choose a specific hashtag, that the end-user should use to give
                you usage rights. Please make it very specific, e.g.
                #yesOKyourbrandname
              </Form.Text>
            </div>
            <div>
              {account?.integrations?.instagram?.rightsRequests?.consentHashtags?.map(
                (consentHashtag, index) => (
                  <Tag
                    key={index}
                    onClick={() => removeConsentHashtag(consentHashtag.id)}
                    showRemoveIcon
                  >
                    #{consentHashtag.name.toLowerCase()}
                  </Tag>
                )
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
