import React from "react";
import {Dropdown, Row, Col, Form} from "react-bootstrap";
import {FaRegComment} from "react-icons/fa";

import Icon from "../../../../../../commons/Icon";

export const RightsRequestComment = ({rightsRequest}) => {
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu

  const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <div
      ref={ref}
      // onMouseOver={() => setShow(true)}
      // onMouseOut={() => setShow(false)}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  return rightsRequest?.id ? (
    <Dropdown id={"rights-request-comment-" + rightsRequest.id}>
      <Dropdown.Toggle as={CustomToggle} drop="start">
        <span>
          <Icon
            notification={rightsRequest?.comment?.length > 0 ? 1 : ""}
            tooltip="Show comment"
          >
            <FaRegComment />
          </Icon>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <div className="p-2 mb-3" style={{width: "500px"}}>
          <div className="mb-2">
            <strong>Rights request comment</strong>
          </div>
          <Row className="mb-3">
            <Col>
              <div className="d-flex flex-row">
                <div className="me-2">
                  <Form.Text>{rightsRequest?.comment}</Form.Text>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};
