const FilterActionTypes = {
  SET_LOADING: "SET_LOADING",
  SET_MEDIA_FILTER_START: "SET_MEDIA_FILTER_START",
  SET_IS_SEARCHING: "SET_IS_SEARCHING",
  SET_ACTIVE_FILTER: "SET_ACTIVE_FILTER",
  SET_FILTER_PARAMETERS: "SET_FILTER_PARAMETERS",
  SET_MEDIA_SORT_BY: "SET_MEDIA_SORT_BY",
  SET_MEDIA_FILTER_OPEN: "SET_MEDIA_FILTER_OPEN",
  SET_MEDIA_FILTER_CLOSE: "SET_MEDIA_FILTER_CLOSE",
  SET_FILTRED_ASSETS: "SET_FILTRED_ASSETS",
  SET_SUB_FILTRED_ASSETS: "SET_SUB_FILTRED_ASSETS",
  SET_ASSET_VIEW: "SET_ASSET_VIEW",
  DELETE_TAG_START: "DELETE_TAG_START",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILURE: "DELETE_TAG_FAILURE",
  CREATE_TAG_START: "CREATE_TAG_START",
  CREATE_TAG_SUCCESS: "CREATE_TAG_SUCCESS",
  CREATE_TAG_FAILURE: "CREATE_TAG_FAILURE",
  FORCE_CLEAR_FILTER: "FORCE_CLEAR_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  CLEAR_FILTER_PARAMETERS: "CLEAR_FILTER_PARAMETERS",
  CLEAR_ALL_TAGS: "CLEAR_ALL_TAGS",
  CREATE_ACCOUNT_FILTER_START: "CREATE_ACCOUNT_FILTER_START",
  CREATE_ACCOUNT_FILTER_SUCCESS: "CREATE_ACCOUNT_FILTER_SUCCESS",
  CREATE_ACCOUNT_FILTER_FAILURE: "CREATE_ACCOUNT_FILTER_FAILURE",
  CREATE_USER_FILTER_START: "CREATE_USER_FILTER_START",
  CREATE_USER_FILTER_SUCCESS: "CREATE_USER_FILTER_SUCCESS",
  CREATE_USER_FILTER_FAILURE: "CREATE_USER_FILTER_FAILURE",
  DELETE_ACCOUNT_FILTER_START: "DELETE_ACCOUNT_FILTER_START",
  DELETE_ACCOUNT_FILTER_SUCCESS: "DELETE_ACCOUNT_FILTER_SUCCESS",
  DELETE_ACCOUNT_FILTER_FAILURE: "DELETE_ACCOUNT_FILTER_FAILURE",
  UPDATE_NUMBER_OF_FILTRED_ASSETS: "UPDATE_NUMBER_OF_FILTRED_ASSETS",
  SET_ACTIVE_ACCOUNT_FILTER: "SET_ACTIVE_ACCOUNT_FILTER",
  CLEAR_ACTIVE_ACCOUNT_FILTER: "CLEAR_ACTIVE_ACCOUNT_FILTER",
  SHOW_FILTER: "SHOW_FILTER",
  SET_SHOW_FILTER: "SET_SHOW_FILTER",
  SHOW_SAVE_FILTER: "SHOW_SAVE_FILTER",
  SET_SHOW_SAVE_FILTER: "SET_SHOW_SAVE_FILTER",
  SET_SHOW_VIEWERS: "SET_SHOW_VIEWERS",
  SET_VIEWER_FILTER_PARAMETERS: "SET_VIEWER_FILTER_PARAMETERS",
  CLEAR_VIEWER_FILTER_PARAMETERS: "CLEAR_VIEWER_FILTER_PARAMETERS",
  RESET_TO_INITIAL_STATE: "RESET_TO_INITIAL_STATE",
};

export default FilterActionTypes;
