import React, {useEffect, useState} from "react";

import {database} from "../../../firebase";
// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  loadCampaign,
  clearCampaign,
} from "../../../redux/campaign/campaign.actions";
import {
  clearFilter,
  setFilterParameters,
} from "../../../redux/filter/filter.actions";
import {
  selectAccountId,
  selectAccounts,
} from "../../../redux/account/account.selectors";
import {useHistory} from "react-router-dom";
import CampaignNumberOfAssets from "./CampaignNumberOfAssets";
import {FaImages, FaEdit, FaExclamationCircle} from "react-icons/fa";
import {FiUpload} from "react-icons/fi";
import CampaignToggleStatus from "./CampaignToggleStatus";
import CopyCampaign from "./CopyCampaign";
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip";
import CampaignDownloadCSV from "../campaign/components/CampaignDownloadCSV";
import ExportCSV from "../../campaign/form-builder/components/ExportCSV";
import {CAMPAIGN_TYPE} from "../../../constants";
import {MAIN_ROUTES} from "../../../constants/routes";
import {renderDate} from "../../../utils/RenderDate";
import CampaignsToggleMenuVisibility from "./CampaignsToggleMenuVisibility";
export default function CampaignsRow({campaign}) {
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const accountId = useSelector(selectAccountId);
  const account = useSelector(selectAccounts);

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then((querySnapshot) => {
          let userArray = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc));
          });
          setUsers(userArray);
        });
    }
  }, [accountId]);

  const editCampaign = () => {
    dispatch(clearCampaign());
    dispatch(loadCampaign(campaign));
    switch (campaign.campaignType) {
      case "upload":
        history.push(MAIN_ROUTES.campaignsEditCampaign.path);
        break;
      case CAMPAIGN_TYPE.upload.type:
        history.push(MAIN_ROUTES.campaignsFormBuilder.path);
        break;
      case CAMPAIGN_TYPE.igHashtag.type:
        history.push(MAIN_ROUTES.campaignsEditHashtag.path);
        break;
      case CAMPAIGN_TYPE.landingpage.type:
        history.push(MAIN_ROUTES.campaignsEditCampaign.path);
        break;
      case CAMPAIGN_TYPE.email.type:
        history.push(MAIN_ROUTES.campaignsEditEmail.path);
        break;
      case CAMPAIGN_TYPE.igMentions.type:
        history.push(MAIN_ROUTES.campaignsEditMention.path);
        break;
      case CAMPAIGN_TYPE.whatsapp.type:
        history.push(MAIN_ROUTES.campaignsEditWhatsApp.path);
        break;
      default:
        history.push(MAIN_ROUTES.campaignsEditCampaign.path);
        break;
    }
  };

  const showCampaign = () => {
    dispatch(clearFilter());
    dispatch(setFilterParameters({campaigns: [campaign.id]}));
    dispatch(loadCampaign(campaign));
    history.push(MAIN_ROUTES.content.path);
  };

  return (
    <tr>
      <td style={{width: "20px"}} className="text-center align-middle">
        {campaign.campaignType === CAMPAIGN_TYPE.igHashtag.type &&
        (campaign?.hashtag === undefined || !campaign?.access_token) ? (
          <ReelerTooltip text="Action needed! Edit the source and verify the hashtag with Instagram.">
            <FaExclamationCircle style={{color: "var(--reeler-danger)"}} />
          </ReelerTooltip>
        ) : (
          <CampaignToggleStatus
            campaignId={campaign.id}
            status={campaign.status}
          />
        )}
      </td>
      <td style={{width: "20px"}} className="text-center align-middle">
        <CampaignsToggleMenuVisibility campaign={campaign} />
      </td>
      <td style={{width: "100px"}}>
        {campaign?.campaignType ? (
          campaign.campaignType === CAMPAIGN_TYPE.igHashtag.type ? (
            <ReelerTooltip text="Hashtag content source">
              <span className="d-flex align-items-center">
                <span className="me-2">{CAMPAIGN_TYPE.igHashtag.icon}</span>
                {typeof campaign?.instagram_business_account === "string" &&
                campaign?.instagram_business_account
                  ? account?.integrations?.instagram?.accounts.map((ig) => {
                      if (
                        ig.ig_business_account_profile.id ===
                        campaign.instagram_business_account
                      ) {
                        return (
                          <small
                            key={ig.ig_business_account_profile.id}
                            className="text-truncate"
                          >
                            {ig.ig_business_account_profile.name}
                          </small>
                        );
                      }
                    })
                  : null}
              </span>
            </ReelerTooltip>
          ) : campaign.campaignType === CAMPAIGN_TYPE.landingpage ? (
            <ReelerTooltip text="Web form content source">
              <FiUpload style={{strokeWidth: "3"}} className="icon-color" />
            </ReelerTooltip>
          ) : campaign.campaignType === CAMPAIGN_TYPE.upload.type ? (
            <ReelerTooltip text="Web form content source">
              {CAMPAIGN_TYPE.upload.icon}
            </ReelerTooltip>
          ) : campaign.campaignType === CAMPAIGN_TYPE.email.type ? (
            <ReelerTooltip text="Email content source">
              {CAMPAIGN_TYPE.email.icon}
            </ReelerTooltip>
          ) : campaign.campaignType === CAMPAIGN_TYPE.whatsapp.type ? (
            <ReelerTooltip text="WhatsApp content source">
              {CAMPAIGN_TYPE.whatsapp.icon}
            </ReelerTooltip>
          ) : campaign.campaignType === CAMPAIGN_TYPE.igMentions.type ? (
            <ReelerTooltip text="Mentions">
              <span className="d-flex align-items-center">
                <span className="me-2">{CAMPAIGN_TYPE.igMentions.icon}</span>
                {typeof campaign?.instagram_business_account === "object" &&
                campaign?.instagram_business_account
                  ? account?.integrations?.instagram?.accounts.map((ig) => {
                      if (
                        ig.ig_business_account_profile.id ===
                        campaign.instagram_business_account
                          .ig_business_account_profile.id
                      ) {
                        return (
                          <small
                            key={ig.ig_business_account_profile.id}
                            className="text-truncate"
                          >
                            {ig.ig_business_account_profile.name}
                          </small>
                        );
                      }
                    })
                  : null}
              </span>
            </ReelerTooltip>
          ) : campaign.campaignType === CAMPAIGN_TYPE.import.type ? (
            <ReelerTooltip text="Import">
              {CAMPAIGN_TYPE.import.icon}
            </ReelerTooltip>
          ) : null
        ) : null}
      </td>
      <td
        style={{
          maxWidth: "200px",
        }}
        className="icon-btn"
      >
        <ReelerTooltip text="Show source content">
          <div className="text-truncate" onClick={() => showCampaign()}>
            {campaign.campaignName}
          </div>
        </ReelerTooltip>
      </td>
      <td style={{maxWidth: "130px"}}>
        <ReelerTooltip text="Created at">
          <div>
            {renderDate(
              new Date(campaign?.createdAt?.seconds * 1000),
              "YYYY-MM-DD"
            )}
          </div>
        </ReelerTooltip>
      </td>
      <td style={{maxWidth: "100px"}}>
        {users
          ? users.map((u) => {
              if (u.id === campaign.userId) {
                return (
                  <div key={u.id} className="text-truncate">
                    {u.email}
                  </div>
                );
              }
            })
          : null}
      </td>

      <td className="text-center">
        <div className="d-flex flex-row justify-content-end algign-items-center">
          <span
            className="d-flex justify-content-center align-items-center me-2"
            style={{width: "30px"}}
          >
            <ReelerTooltip text="Show source content">
              <FaImages
                className="icon-color icon-btn"
                onClick={() => showCampaign()}
              />
            </ReelerTooltip>
          </span>
          <span
            className="d-flex justify-content-center align-items-center me-2"
            style={{width: "30px"}}
          >
            <ReelerTooltip text="Edit content source">
              <FaEdit
                className="icon-color icon-btn"
                onClick={() => editCampaign()}
              />
            </ReelerTooltip>
          </span>
          <span
            className="d-flex justify-content-center align-items-center me-2"
            style={{width: "30px"}}
          >
            <CopyCampaign campaign={campaign} />
          </span>
          <span
            className="d-flexjustify-content-center align-items-center me-2"
            style={{width: "30px"}}
          >
            {campaign.campaignType === CAMPAIGN_TYPE.landingpage ? (
              <CampaignDownloadCSV campaign={campaign} />
            ) : campaign.campaignType === CAMPAIGN_TYPE.upload.type ? (
              <ExportCSV campaign={campaign} />
            ) : null}
          </span>
          <span
            className="d-flex justify-content-center align-items-center me-2"
            style={{width: "60px"}}
          >
            <CampaignNumberOfAssets campaignId={campaign.id} />
          </span>
        </div>
      </td>
    </tr>
  );
}
