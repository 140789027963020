import React, {useEffect, useState} from "react";
import {database} from "../../../../../../firebase";

// 3rd-party
import {
  FaCommentAlt,
  FaBrain,
  FaInfoCircle,
  FaFileSignature,
  FaStarHalfAlt,
  FaTags,
  FaShareSquare,
} from "react-icons/fa";
import {BsStack, BsBoxFill, BsStars} from "react-icons/bs";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  selectActiveAsset,
  selectActiveSidebarTab,
} from "../../../../../../redux/asset/asset.selectors";
import {setActiveSidebarTab} from "../../../../../../redux/asset/asset.actions";

// Reeler components
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";
// Styled
import * as S from "../../styles";
import {selectCurrentUser} from "../../../../../../redux/user/user.selectors";
import {USER_ROLES} from "../../../../../../constants/routes";
import {selectAccounts} from "../../../../../../redux/account/account.selectors";

const AssetSidebar = () => {
  const [rightRequests, setRightRequests] = useState(null);
  const dispatch = useDispatch();
  const activeSidebarTab = useSelector(selectActiveSidebarTab);
  const asset = useSelector(selectActiveAsset);
  const account = useSelector(selectAccounts);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    database.rightRequests
      .where("accountId", "==", asset.accountId)
      .where("assetId", "==", asset.id)
      .get()
      .then((querySnapshot) => {
        setRightRequests(querySnapshot.size);
      });
  }, [asset]);

  const navItems = [
    {
      label: "Info",
      icon: <FaInfoCircle />,
      key: "info",
      userRoles: [USER_ROLES.editor, USER_ROLES.owner, USER_ROLES.viewer],
    },
    // {
    //   label: "Rating, Notes & comments",
    //   icon: <FaStarHalfAlt />,
    //   key: "rating",
    //   userRoles: [USER_ROLES.editor, USER_ROLES.owner, USER_ROLES.viewer],
    // },

    {
      label: "Versions",
      icon: <BsStack />,
      key: "versions",
      userRoles: [USER_ROLES.editor, USER_ROLES.owner],
    },

    {
      label: "Publish",
      icon: <FaShareSquare />,
      key: "publish",
      userRoles: [USER_ROLES.editor, USER_ROLES.owner],
    },
    {
      label: "GenAi",
      icon: <BsStars />,
      key: "genai",
      userRoles: [USER_ROLES.editor, USER_ROLES.owner],
    },
  ];

  return (
    currentUser && (
      <S.SidebarNavContainer>
        <S.NavList>
          {navItems.map((item, index) => {
            if (
              item.key === "genai" &&
              ["sYz63zyL7iFO7o4K5wOi", "Vr2OmjtCKTGWKnnvR03N"].includes(
                account.id
              )
            ) {
              return (
                <ReelerTooltip key={index} text={item.label}>
                  <S.ListItem
                    key={index}
                    active={activeSidebarTab === item.key}
                    onClick={() => dispatch(setActiveSidebarTab(item.key))}
                  >
                    {item.icon}
                  </S.ListItem>
                </ReelerTooltip>
              );
            } else {
              return (
                item.key !== "genai" &&
                item.userRoles.includes(currentUser?.role) && (
                  <ReelerTooltip key={index} text={item.label}>
                    <S.ListItem
                      key={index}
                      active={activeSidebarTab === item.key}
                      onClick={() => dispatch(setActiveSidebarTab(item.key))}
                    >
                      {/* Show rating */}
                      {item.key === "rating" && asset?.rating > 0 ? (
                        <S.Notification>{asset?.rating}</S.Notification>
                      ) : null}
                      {/* Show no of products */}
                      {item.key === "products" &&
                      asset?.products?.length > 0 ? (
                        <S.Notification>
                          {asset?.products.length}
                        </S.Notification>
                      ) : null}
                      {/* Show number of tags */}
                      {asset.tags &&
                      item.key === "labels" &&
                      Object.keys(asset?.tags).length > 0 ? (
                        <S.Notification>
                          {Object.keys(asset?.tags).length}
                        </S.Notification>
                      ) : null}
                      {asset?.feeds &&
                      item.key === "publish" &&
                      asset?.feeds.length > 0 ? (
                        <S.Notification>{asset?.feeds.length}</S.Notification>
                      ) : null}
                      {/* Show number of versions */}
                      {item.key === "versions" &&
                      asset?.assetVersions?.length > 0 ? (
                        <S.Notification>
                          {asset?.assetVersions?.length}
                        </S.Notification>
                      ) : null}
                      {/* Show number of versions */}
                      {item.key === "rights" ? (
                        asset?.thirdPartyApprovals?.status === "approved" ? (
                          <S.Notification>1</S.Notification>
                        ) : rightRequests ? (
                          <S.Notification>{rightRequests}</S.Notification>
                        ) : null
                      ) : null}

                      {item.icon}
                    </S.ListItem>
                  </ReelerTooltip>
                )
              );
            }
          })}
        </S.NavList>
      </S.SidebarNavContainer>
    )
  );
};

export default AssetSidebar;
