export default function formatCreatorName(creator) {
  let name = "";
  creator?.firstName && (name += creator?.firstName);

  if (creator?.firstName && creator?.lastName) {
    name += " ";
  }
  creator?.lastName && (name += creator?.lastName);

  if (creator?.email || creator?.ig_username) {
    name += " (";
    creator.email && (name += creator?.email);
    if (creator.email && creator.ig_username) {
      name += ", ";
    }
    creator.ig_username && (name += ` ${creator?.ig_username}`);
    name += ")";
  }

  return name;
}
