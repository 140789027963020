import React, {useState} from "react";

// Firebase
import {database} from "../../../../../../firebase";

// 3rd-party
import {InputGroup, Form} from "react-bootstrap";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {updateAsset} from "../../../../../../redux/asset/asset.actions";
import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";

// Reeler components
import ReelerButton from "../../../../../commons/ReelerButton";
import FadeAlert from "../../../../../commons/FadeAlert/FadeAlert";
import {useRef} from "react";
import {ModalTabHeading} from "../../styles";
import {ACCOUNT_PLANS, USER_ROLES} from "../../../../../../constants/routes";
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent";
import {selectCurrentUser} from "../../../../../../redux/user/user.selectors";
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage";

export default function AssetNotes() {
  const {setSuccessMessage, setErrorMessage} = useUserFeedbackMessage();
  const asset = useSelector(selectActiveAsset);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const currentUser = useSelector(selectCurrentUser);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();

  const handleUpdateNotes = () => {
    setLoading(true);
    database.assets
      .doc(asset.id)
      .update({
        notes: inputRef.current.value,
      })
      .then(dispatch(updateAsset({...asset, notes: inputRef.current.value})))
      .then(() => {
        setLoading(false);
        setSuccessMessage("Your note was added");
        setMsg({success: true, lead: "Success!", text: "Your note was added"});
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage("Something went wrong with adding your note");
      });

    setMsg();
  };

  return (
    // <ModalTabContainer>
    <>
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
        userRoles={["owner", "editor", "viewer"]}
      >
        <InputGroup className="mb-1">
          <Form.Control
            as="textarea"
            style={{fontSize: "12px"}}
            rows={2}
            disabled={loading || currentUser.role === USER_ROLES.viewer}
            placeholder="Leave a note"
            defaultValue={asset?.notes}
            ref={inputRef}
          />

          {currentUser.role !== USER_ROLES.viewer ? (
            <ReelerButton
              styleClass="btn-secondary"
              dispatch={() => handleUpdateNotes()}
              disabled={loading || currentUser.role === USER_ROLES.viewer}
              text="Save"
            />
          ) : null}
        </InputGroup>
      </AuthBasedComponent>
    </>
  );
}
